import React from "react";
import {
  Theme,
  withStyles,
  IconButton,
  Divider,
  Typography,
  Grid
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import { pagePath } from "../..";
import { BotViewModel, TransactionViewModel } from "@futbot/runtime-api";
import { makeStyles, createStyles } from "@material-ui/styles";
import { fancyHeader } from "../../../../../../assets/jss";
import { platforms } from "../../../../../../components/plateformPicker";
import { Anonymizable } from "../../../../../../components/anonymizable";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: "grow",
      paddingLeft: theme.spacing(2),
      color: theme.palette.primary.contrastText,
      height: 57,
      ...fancyHeader
    }
  })
);

export function TransactionDetailsHeader(props: {
  transaction: TransactionViewModel;
}) {
  const classes = useStyles({});
  const { transaction } = props;
  var correspondingPlatform = platforms.find(p => {
    return p.value == transaction.platform;
  });
  return (
    <div className={classes.root}>
      <Grid container justify="space-between">
        <Grid>
          <Typography color="inherit" variant="h6">
            <Anonymizable>{transaction.guid}</Anonymizable>
          </Typography>
          <Typography color="inherit">
            <Anonymizable>
              {correspondingPlatform ? correspondingPlatform.label : "Unknown"}
            </Anonymizable>
          </Typography>
        </Grid>
        <Link to={pagePath}>
          <IconButton>
            <CloseIcon />
          </IconButton>
        </Link>
      </Grid>
    </div>
  );
}
