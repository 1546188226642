import React, { useState } from "react";
import { MenuItem, Select, Menu, IconButton } from "@material-ui/core";
import { LanguageIcon } from "../../assets/icons";

export function LanguagePicker() {
  const [language, setLanguage] = useState(localStorage.i18n);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const onChange = l => {
    setLanguage(l);
    localStorage.i18n = l;
    setAnchorEl(null);
    window.location.reload();
  };
  return (
    <div>
      <IconButton
        color="inherit"
        onClick={e => {
          setAnchorEl(e.currentTarget);
        }}
      >
        <LanguageIcon />
      </IconButton>
      <Menu open={Boolean(anchorEl)} keepMounted anchorEl={anchorEl}>
        <MenuItem onClick={() => onChange("en")}>English</MenuItem>
        <MenuItem onClick={() => onChange("fr")}>Français</MenuItem>
        <MenuItem onClick={() => onChange("de")}>Deutsch</MenuItem>
      </Menu>
    </div>
  );
}
