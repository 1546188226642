import React from "react";
import { levels } from "./levels";
import { AssetPicker } from "./AssetPicker";
import any from "../../assets/images/searchFilters/any.png";
import gold from "../../assets/images/searchFilters/gold.png";
import silver from "../../assets/images/searchFilters/silver.png";
import bronze from "../../assets/images/searchFilters/bronze.png";
import special from "../../assets/images/searchFilters/SP.png";

export function getAnyLevelImage(): string {
  return `url(${any})`;
}

export function getLevelImage(level: number): string {
  switch (level) {
    case 1:
      return `url(${bronze})`;
    case 2:
      return `url(${silver})`;
    case 3:
      return `url(${gold})`;
    case 4:
      return `url(${special})`;
  }
}

export function LevelPicker(props: {
  initialValue?: number;
  onChange?: (value: number) => void;
  className?: string;
}) {
  const {} = props;
  return (
    <AssetPicker
      className={props.className}
      initialValue={props.initialValue}
      onChange={props.onChange}
      anyLabel={"Any Level"}
      assetLabel={"Level"}
      getImgLink={getLevelImage}
      getAnyImgLink={getAnyLevelImage}
      source={levels}
    />
  );
}
