import React, { useState, useEffect } from "react";
import {
  Theme,
  Table,
  TableBody,
  LinearProgress,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useStatApi } from "../../../../../../api";
import { HistoryApi, SaleViewModel } from "@futbot/stats-api";
import { PlayerInfo } from "../../../../../../components/playerInfo";
import { Coins } from "../../../../../../components/coins";
import TimeAgo from "react-timeago";
import { useBotGuid } from "../..";
import { usePerfectScroll } from "../../../../../../hooks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    tableWrapper: {
      overflow: "auto",
      position: "relative",
      maxHeight: "calc(100vh - 160px)",
    },
    creationDate: {
      fontSize: 11,
      color: "#90949c",
    },
  })
);

export function BotHistory(props: {}) {
  const classes = useStyles({});
  const [deals, setDeals] = useState<SaleViewModel[]>([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const api = useStatApi(HistoryApi);
  const { botGuid } = useBotGuid();
  const wrapperRef = usePerfectScroll();

  useEffect(() => {
    setIsLoading(true);
    api
      .getHistory({ botId: botGuid, page })
      .then((svm: SaleViewModel[]) => {
        setDeals(svm);
      })
      .catch((reason) => {
        console.log(reason);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [botGuid]);

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <div>
      <div className={classes.tableWrapper} ref={wrapperRef}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Player</TableCell>
              <TableCell>Deal Time</TableCell>
              <TableCell>Buy Price</TableCell>
              <TableCell>Sell Price</TableCell>
              <TableCell>Profit/Loss</TableCell>
            </TableRow>
            {deals.map((deal, i) => {
              return (
                <TableRow hover key={i}>
                  <TableCell>
                    <PlayerInfo {...deal.playerCard} />
                  </TableCell>
                  <TableCell>
                    <div className={classes.creationDate}>
                      <TimeAgo date={deal.date} />
                    </div>
                  </TableCell>
                  <TableCell>
                    <Coins amount={deal.buyPrice} />
                  </TableCell>
                  <TableCell>
                    <Coins amount={deal.sellPrice} />
                  </TableCell>
                  <TableCell>
                    <Coins
                      amount={deal.profit}
                      color={deal.profit > 0 ? "green" : "red"}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
