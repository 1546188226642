import { useRef, useEffect } from "react";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

export function usePerfectScroll() {
  const ref = useRef(null);
  useEffect(() => {
    new PerfectScrollbar(ref.current);
  }, []);
  return ref;
}
