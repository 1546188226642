import React, { useCallback } from "react";
import {
  Theme,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { ShopListViewModel } from "@futbot/stats-api";
import { State, history } from "../../../../../../store/config/configureStore";
import { extractGuid, pagePath, useGuid } from "../..";
import { useMappedState } from "redux-react-hook";
import { usePerfectScroll } from "../../../../../../hooks";
import { Link } from "react-router-dom";
import { InteractiveListHeader } from "../../../../../../components/interactiveTable/InteractiveListHeader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    tableWrapper: {
      overflow: "auto",
      position: "relative",
      maxHeight: "calc(100vh - 160px)"
    },
    link: {
      color: "#4894fe",
      fontWeight: 500,
      textDecoration: "none",
      display: "flex"
    },
    linkCount: {
      fontSize: 12,
      color: "gray",
      fontWeight: 100
    },
    linkSpacer: {
      flexGrow: 1
    }
  })
);

export function ShopListsList(props: { shopLists: ShopListViewModel[] }) {
  const classes = useStyles({});
  const { shopLists } = props;
  const { guid } = useGuid();
  const wrapperRef = usePerfectScroll();
  return (
    <div className={classes.root}>
      <InteractiveListHeader header={"Identifier"} />
      <div className={classes.tableWrapper} ref={wrapperRef}>
        <Table>
          <TableBody>
            {shopLists.map((shopList, i) => (
              <TableRow
                hover
                key={i}
                selected={shopList.id === guid}
                onClick={() => {
                  history.push(pagePath + "/" + shopList.id);
                }}
              >
                <TableCell>
                  <div className={classes.link}>
                    <span>{shopList.name}</span>
                    <span className={classes.linkSpacer} />
                    <div>
                      <div className={classes.linkCount}>
                        {shopList.searches.length} card(s)
                      </div>
                      <div className={classes.linkCount}>
                        {shopList.fuzzySearches.length} search(es)
                      </div>
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
