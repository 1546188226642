import React, { useState } from "react";
import {
  Theme,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import { BotSettings } from "../botSettings";
import { InputBotSettings } from "../botSettings/InputBotSettings";
import { BotViewModel, BotSettingsViewModel } from "@futbot/runtime-api";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative"
    },
    flex: {
      flex: 1
    }
  })
);

const newSettings: BotSettingsViewModel = {
  buyingOtherwisePercentage: 7,
  mode: 1,
  maxNumberOfSameCard: 10,
  maxRequestCountBySession: 500,
  minRestingDelay: 30,
  maxRestingDelay: 90,
  minDelayBetweenRequests: 3,
  maxDelayBetweenRequests: 10,
  backupCodes: [],
  buyTresholds: []
};

export function BotCreationModal(props: {
  open: boolean;
  onClose: () => void;
  onSubmit: (settings: BotSettingsViewModel) => void;
}) {
  const classes = useStyles({});
  const [botSettings, setBotSettings] = useState(newSettings);
  const { open, onClose, onSubmit } = props;
  const [showHelp, setShowHelp] = useState(true);

  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            Create a New Bot
          </Typography>
          <Button color="inherit" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="inherit"
            onClick={() => {
              onSubmit(botSettings);
            }}
          >
            Create
          </Button>
        </Toolbar>
      </AppBar>
      <InputBotSettings
        settings={botSettings}
        showHelp={showHelp}
        onSettingsUpdate={s => {
          setBotSettings(s);
        }}
      />
    </Dialog>
  );
}
