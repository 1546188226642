import { tertiaryColor } from "../../assets/jss";
import { Theme, StyleRules } from "@material-ui/core/styles";
import {
  drawerWidth,
  blackColor,
  whiteColor,
  hexToRgb
} from "../../assets/jss";
import AppBarBackground from "../../assets/images/backgrounds/1.jpg";
import brandIcon from "../../assets/images/brand.png";
import brandIcon2 from "../../assets/images/brand-2.png";
import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    root: {
      display: "flex"
    },
    grow: {
      flexGrow: 1
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      background: theme.palette.primary.main,
      backgroundImage: "url(" + AppBarBackground + ")",
      backgroundSize: "auto,cover",
      backgroundPosition: "50%",
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    menuButton: {
      marginLeft: 12,
      marginRight: 36
    },
    hide: {
      display: "none"
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap"
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),
      borderRight: 0
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1
      },
      borderRight: 0
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      zIndex: 4,
      padding: "0 8px",
      ...theme.mixins.toolbar
    },
    divider: {
      backgroundColor: "rgba(117, 117, 117, 0.94)",
      margin: 10
    },
    brand: {
      content: "url(" + process.env.REACT_APP_LOGO_URL + ")",
      width: 190
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3)
    },
    background: {
      position: "absolute",
      zIndex: 1,
      height: "100%",
      width: "100%",
      display: "block",
      top: "0",
      left: "0",
      background: "#222"
    },
    list: {
      marginTop: "20px",
      paddingLeft: "0",
      paddingTop: "0",
      paddingBottom: "0",
      marginBottom: "0",
      listStyle: "none",
      position: "unset"
    },
    listOpen: {
      marginTop: "21px"
    },
    item: {
      position: "relative",
      display: "block",
      textDecoration: "none",
      "&:hover,&:focus,&:visited,&": {
        color: whiteColor
      }
    },
    itemLink: {
      width: "auto",
      transition: "all 300ms linear",
      margin: "10px 8px 0",
      borderRadius: "3px",
      position: "relative",
      display: "block",
      padding: "10px 15px",
      backgroundColor: "transparent"
    },
    inlineItemLink: {
      display: "inline-block",
      width: 54
    },
    itemIcon: {
      width: "24px",
      fontSize: "24px",
      lineHeight: "30px",
      float: "left",
      marginRight: "15px",
      textAlign: "center",
      verticalAlign: "middle",
      color: "rgba(255,255,255, 0.8) !important"
    },
    itemText: {
      fontFamily: "Cairo",
      textTransform: "uppercase",
      margin: "0",
      lineHeight: "30px",
      fontSize: "15px",
      fontWeight: 600,
      color: whiteColor
    },
    foreground: {
      position: "relative",
      zIndex: 4,
      background: "#222"
    },
    selectedItemText: {
      color: theme.palette.primary.main + " !important"
    },
    selectedItem: {
      backgroundColor: tertiaryColor,
      boxShadow:
        "0 12px 20px -10px rgba(" +
        hexToRgb(tertiaryColor) +
        ",.28), 0 4px 20px 0 rgba(" +
        hexToRgb(blackColor) +
        ",.12), 0 7px 8px -5px rgba(" +
        hexToRgb(tertiaryColor) +
        ",.2)",
      "&:hover": {
        backgroundColor: tertiaryColor,
        boxShadow:
          "0 12px 20px -10px rgba(" +
          hexToRgb(tertiaryColor) +
          ",.28), 0 4px 20px 0 rgba(" +
          hexToRgb(blackColor) +
          ",.12), 0 7px 8px -5px rgba(" +
          hexToRgb(tertiaryColor) +
          ",.2)"
      }
    },
    title: {
      fontFamily: "Cairo",
      textTransform: "uppercase"
    }
  })
);
