export const clubs = [
  { value: 171, label: "1. FC Nürnberg", league: 19 },
  { value: 169, label: "1. FSV Mainz 05", league: 19 },
  { value: 33, label: "1860 München", league: 2076 },
  { value: 820, label: "Aalborg BK", league: 1 },
  { value: 271, label: "Aarhus GF", league: 1 },
  { value: 77, label: "Aberdeen", league: 50 },
  { value: 614, label: "AC Ajaccio", league: 17 },
  { value: 1446, label: "AC Horsens", league: 1 },
  { value: 110313, label: "Accrington", league: 60 },
  { value: 100831, label: "AD Alcorcón", league: 54 },
  { value: 111393, label: "Adelaide United", league: 351 },
  { value: 111821, label: "Admira Wacker", league: 80 },
  { value: 650, label: "ADO Den Haag", league: 10 },
  { value: 278, label: "AEK Athens", league: 63 },
  { value: 112259, label: "AFC Wimbledon", league: 60 },
  { value: 433, label: "AIK", league: 56 },
  { value: 57, label: "AJ Auxerre", league: 17 },
  { value: 245, label: "Ajax", league: 10 },
  { value: 111340, label: "Akhisarspor", league: 68 },
  { value: 112387, label: "Al Ahli", league: 350 },
  { value: 112990, label: "Al Batin", league: 350 },
  { value: 112389, label: "Al Faisaly", league: 350 },
  { value: 112390, label: "Al Fateh", league: 350 },
  { value: 113057, label: "Al Fayha", league: 350 },
  { value: 113222, label: "Al Hazem", league: 350 },
  { value: 605, label: "Al Hilal", league: 350 },
  { value: 607, label: "Al Ittihad", league: 350 },
  { value: 112139, label: "Al Nassr", league: 350 },
  { value: 112391, label: "Al Qadisiyah", league: 350 },
  { value: 112392, label: "Al Raed", league: 350 },
  { value: 111674, label: "Al Shabab", league: 350 },
  { value: 112393, label: "Al Taawoun", league: 350 },
  { value: 112408, label: "Al Wehda", league: 350 },
  { value: 112579, label: "Al. Petrolera", league: 336 },
  { value: 113142, label: "Alanyaspor", league: 68 },
  { value: 1854, label: "Albacete BP", league: 54 },
  { value: 111707, label: "Aldosivi", league: 353 },
  { value: 1879, label: "América", league: 341 },
  { value: 101099, label: "América de Cali", league: 336 },
  { value: 1816, label: "Amiens SC", league: 16 },
  { value: 1530, label: "Angers SCO", league: 16 },
  { value: 741, label: "Antalyaspor", league: 68 },
  { value: 1369, label: "Argentina" },
  { value: 111019, label: "Argentinos Jrs.", league: 353 },
  { value: 111082, label: "Arka Gdynia", league: 66 },
  { value: 1, label: "Arsenal", league: 13 },
  { value: 113948, label: "AS Béziers", league: 17 },
  { value: 69, label: "AS Monaco", league: 16 },
  { value: 1847, label: "Ascoli", league: 32 },
  { value: 1819, label: "ASSE", league: 16 },
  { value: 2, label: "Aston Villa", league: 14 },
  { value: 39, label: "Atalanta", league: 31 },
  { value: 448, label: "Athletic Club", league: 53 },
  { value: 112992, label: "Atl. Bucaramanga", league: 336 },
  { value: 101100, label: "Atl. Nacional", league: 336 },
  { value: 112885, label: "Atlanta United", league: 39 },
  { value: 101114, label: "Atlas", league: 341 },
  { value: 112528, label: "Atlético Huila", league: 336 },
  { value: 240, label: "Atlético Madrid", league: 53 },
  { value: 1035, label: "Atlético Mineiro", league: 7 },
  { value: 111708, label: "Atlético Tucumán", league: 353 },
  { value: 101097, label: "Audax Italiano", league: 335 },
  { value: 506, label: "Aue", league: 20 },
  { value: 1415, label: "Australia" },
  { value: 1322, label: "Austria" },
  { value: 1906, label: "AZ", league: 10 },
  { value: 1598, label: "Bahia", league: 7 },
  { value: 110404, label: "Banfield", league: 353 },
  { value: 1932, label: "Barnsley", league: 60 },
  { value: 101014, label: "Başakşehir", league: 68 },
  { value: 749, label: "BB Erzurumspor", league: 68 },
  { value: 111768, label: "Beijing Guoan", league: 2012 },
  { value: 112537, label: "Beijing Renhe", league: 2012 },
  { value: 1325, label: "Belgium" },
  { value: 111022, label: "Belgrano", league: 353 },
  { value: 112026, label: "Benevento", league: 32 },
  { value: 327, label: "Beşiktaş", league: 68 },
  { value: 159, label: "Bielefeld", league: 20 },
  { value: 88, label: "Birmingham City", league: 14 },
  { value: 711, label: "BK Häcken", league: 56 },
  { value: 3, label: "Blackburn Rovers", league: 14 },
  { value: 1926, label: "Blackpool", league: 60 },
  { value: 1898, label: "Boavista FC", league: 308 },
  { value: 160, label: "Bochum", league: 20 },
  { value: 305, label: "Bohemian FC", league: 65 },
  { value: 111451, label: "Bolivia" },
  { value: 189, label: "Bologna", league: 31 },
  { value: 4, label: "Bolton", league: 14 },
  { value: 1943, label: "Bournemouth", league: 13 },
  { value: 112019, label: "Boyacá Chicó", league: 336 },
  { value: 1804, label: "Bradford City", league: 60 },
  { value: 110500, label: "Braunschweig", league: 2076 },
  { value: 838, label: "Bray Wanderers", league: 65 },
  { value: 1370, label: "Brazil" },
  { value: 1925, label: "Brentford", league: 14 },
  { value: 190, label: "Brescia", league: 32 },
  { value: 1808, label: "Brighton", league: 13 },
  { value: 111395, label: "Brisbane Roar", league: 351 },
  { value: 1919, label: "Bristol City", league: 14 },
  { value: 1962, label: "Bristol Rovers", league: 60 },
  { value: 111705, label: "Brommapojkarna", league: 56 },
  { value: 269, label: "Brøndby IF", league: 1 },
  { value: 900, label: "BSC Young Boys", league: 189 },
  { value: 1877, label: "Buenos Aires", league: 353 },
  { value: 1327, label: "Bulgaria" },
  { value: 1796, label: "Burnley", league: 13 },
  { value: 742, label: "Bursaspor", league: 68 },
  { value: 15015, label: "Burton Albion", league: 60 },
  { value: 1945, label: "Bury", league: 61 },
  { value: 479, label: "CA Osasuna", league: 54 },
  { value: 1968, label: "Cádiz CF", league: 54 },
  { value: 1842, label: "Cagliari", league: 31 },
  { value: 1944, label: "Cambridge Utd", league: 61 },
  { value: 1395, label: "Cameroon" },
  { value: 111455, label: "Canada" },
  { value: 1961, label: "Cardiff City", league: 13 },
  { value: 110591, label: "Carl Zeiss Jena", league: 2076 },
  { value: 1480, label: "Carlisle United", league: 61 },
  { value: 112409, label: "Carpi", league: 32 },
  { value: 101037, label: "Çaykur Rizespor", league: 68 },
  { value: 112533, label: "CD Antofagasta", league: 335 },
  { value: 10019, label: "CD Feirense", league: 308 },
  { value: 111327, label: "CD Huachipato", league: 335 },
  { value: 100888, label: "CD Leganés", league: 53 },
  { value: 110831, label: "CD Lugo", league: 54 },
  { value: 477, label: "CD Numancia", league: 54 },
  { value: 112116, label: "CD O'Higgins", league: 335 },
  { value: 111328, label: "CD Palestino", league: 335 },
  { value: 260, label: "CD Tenerife", league: 54 },
  { value: 112516, label: "CD Tondela", league: 308 },
  { value: 110980, label: "CD Viñazur", league: 335 },
  { value: 111059, label: "Ceará SC", league: 7 },
  { value: 78, label: "Celtic", league: 50 },
  { value: 111396, label: "Central Coast", league: 351 },
  { value: 1750, label: "Cercle Brugge", league: 4 },
  { value: 101148, label: "Cerezo Osaka", league: 349 },
  { value: 110066, label: "CF Rayo", league: 54 },
  { value: 110854, label: "CF Reus", league: 54 },
  { value: 1813, label: "Chamois FC", league: 17 },
  { value: 111769, label: "Changchun Yatai", league: 2012 },
  { value: 112476, label: "Chapecoense", league: 7 },
  { value: 89, label: "Charlton Ath", league: 60 },
  { value: 5, label: "Chelsea", league: 13 },
  { value: 1936, label: "Cheltenham Town", league: 61 },
  { value: 693, label: "Chicago Fire", league: 39 },
  { value: 192, label: "Chievo Verona", league: 31 },
  { value: 111459, label: "Chile" },
  { value: 112165, label: "Chongqing SWM", league: 2012 },
  { value: 111993, label: "Cittadella", league: 32 },
  { value: 1815, label: "Clermont Foot", league: 17 },
  { value: 231, label: "Club Brugge", league: 4 },
  { value: 1935, label: "Colchester", league: 61 },
  { value: 111109, label: "Colombia" },
  { value: 110406, label: "Colón", league: 353 },
  { value: 694, label: "Colorado Rapids", league: 39 },
  { value: 687, label: "Columbus Crew SC", league: 39 },
  { value: 1867, label: "Córdoba CF", league: 54 },
  { value: 422, label: "Cork City", league: 65 },
  { value: 112168, label: "Cosenza", league: 32 },
  { value: 111112, label: "Côte d'Ivoire" },
  { value: 1800, label: "Coventry City", league: 60 },
  { value: 110747, label: "Cracovia", league: 66 },
  { value: 110890, label: "Crawley Town", league: 61 },
  { value: 111434, label: "Cremona", league: 32 },
  { value: 121, label: "Crewe Alexandra", league: 61 },
  { value: 110734, label: "Crotone", league: 32 },
  { value: 1878, label: "Cruz Azul", league: 341 },
  { value: 568, label: "Cruzeiro", league: 7 },
  { value: 1799, label: "Crystal Palace", league: 13 },
  { value: 315, label: "CSKA Moscow", league: 3006 },
  { value: 112655, label: "Curicó Unido", league: 335 },
  { value: 1330, label: "Czech Republic" },
  { value: 463, label: "D. Alavés", league: 53 },
  { value: 688, label: "D.C. United", league: 39 },
  { value: 2056, label: "Daegu FC", league: 83 },
  { value: 112378, label: "Dalian Yifang", league: 2012 },
  { value: 113876, label: "Dalkurd FF", league: 56 },
  { value: 110502, label: "Darmstadt", league: 20 },
  { value: 635, label: "De Graafschap", league: 10 },
  { value: 111710, label: "Defensa", league: 353 },
  { value: 1331, label: "Denmark" },
  { value: 112531, label: "Depor. Iquique", league: 335 },
  { value: 112709, label: "Deportes Temuco", league: 335 },
  { value: 111722, label: "Deportes Tolima", league: 336 },
  { value: 101102, label: "Deportivo Cali", league: 336 },
  { value: 111723, label: "Deportivo Pasto", league: 336 },
  { value: 91, label: "Derby County", league: 14 },
  { value: 445, label: "Derry City", league: 65 },
  { value: 666, label: "Desportivo Aves", league: 308 },
  { value: 110569, label: "Dijon FCO", league: 16 },
  { value: 211, label: "Dinamo Zagreb", league: 317 },
  { value: 710, label: "Djurgårdens IF", league: 56 },
  { value: 142, label: "Doncaster", league: 60 },
  { value: 22, label: "Dortmund", league: 19 },
  { value: 503, label: "Dresden", league: 20 },
  { value: 1825, label: "Duisburg", league: 20 },
  { value: 837, label: "Dundalk", league: 65 },
  { value: 180, label: "Dundee FC", league: 50 },
  { value: 110636, label: "Düsseldorf", league: 19 },
  { value: 101047, label: "Dynamo Kyiv", league: 332 },
  { value: 62, label: "EA Guingamp", league: 16 },
  { value: 111465, label: "Ecuador" },
  { value: 111130, label: "Egypt" },
  { value: 468, label: "Elche CF", league: 54 },
  { value: 1746, label: "Empoli", league: 31 },
  { value: 162, label: "Energie Cottbus", league: 2076 },
  { value: 1318, label: "England" },
  { value: 112526, label: "Envigado", league: 336 },
  { value: 1445, label: "Esbjerg fB", league: 1 },
  { value: 294, label: "ESTAC Troyes", league: 17 },
  { value: 101083, label: "Estudiantes", league: 353 },
  { value: 112096, label: "Ettifaq FC", league: 350 },
  { value: 7, label: "Everton", league: 13 },
  { value: 112584, label: "Everton de Viña", league: 335 },
  { value: 111560, label: "Excel Mouscron", league: 4 },
  { value: 1971, label: "Excelsior", league: 10 },
  { value: 143, label: "Exeter City", league: 61 },
  { value: 469, label: "Extremadura UD", league: 54 },
  { value: 101150, label: "F.C. Tokyo", league: 349 },
  { value: 100409, label: "FC Augsburg", league: 19 },
  { value: 241, label: "FC Barcelona", league: 53 },
  { value: 896, label: "FC Basel", league: 189 },
  { value: 21, label: "FC Bayern", league: 19 },
  { value: 695, label: "FC Dallas", league: 39 },
  { value: 100628, label: "FC Emmen", league: 10 },
  { value: 1915, label: "FC Groningen", league: 10 },
  { value: 819, label: "FC København", league: 1 },
  { value: 217, label: "FC Lorient", league: 17 },
  { value: 10032, label: "FC Lugano", league: 189 },
  { value: 897, label: "FC Luzern", league: 189 },
  { value: 68, label: "FC Metz", league: 17 },
  { value: 1516, label: "FC Midtjylland", league: 1 },
  { value: 71, label: "FC Nantes", league: 16 },
  { value: 1788, label: "FC Nordsjælland", league: 1 },
  { value: 236, label: "FC Porto", league: 308 },
  { value: 982, label: "FC Seoul", league: 83 },
  { value: 110770, label: "FC Sion", league: 189 },
  { value: 898, label: "FC St. Gallen", league: 189 },
  { value: 1715, label: "FC Thun", league: 189 },
  { value: 1903, label: "FC Utrecht", league: 10 },
  { value: 894, label: "FC Zürich", league: 189 },
  { value: 226, label: "FCSM", league: 17 },
  { value: 326, label: "Fenerbahçe", league: 68 },
  { value: 246, label: "Feyenoord", league: 10 },
  { value: 1334, label: "Finland" },
  { value: 110374, label: "Fiorentina", league: 31 },
  { value: 256, label: "FK Austria Wien", league: 80 },
  { value: 918, label: "FK Bodø/Glimt", league: 41 },
  { value: 1463, label: "FK Haugesund", league: 41 },
  { value: 112260, label: "Fleetwood Town", league: 60 },
  { value: 567, label: "Fluminense", league: 7 },
  { value: 110911, label: "Foggia", league: 32 },
  { value: 561, label: "Forest Green", league: 61 },
  { value: 110169, label: "Fortuna Köln", league: 2076 },
  { value: 634, label: "Fortuna Sittard", league: 10 },
  { value: 1335, label: "France" },
  { value: 1824, label: "Frankfurt", league: 19 },
  { value: 111657, label: "Frosinone", league: 31 },
  { value: 110592, label: "FSV Zwickau", league: 2076 },
  { value: 144, label: "Fulham", league: 13 },
  { value: 1891, label: "Funchal", league: 308 },
  { value: 165, label: "Fürth", league: 20 },
  { value: 325, label: "Galatasaray", league: 68 },
  { value: 112093, label: "Gamba Osaka", league: 349 },
  { value: 112115, label: "Gangwon FC", league: 83 },
  { value: 110316, label: "Gazélec Ajaccio", league: 17 },
  { value: 518, label: "GD Chaves", league: 308 },
  { value: 110556, label: "Genoa", league: 31 },
  { value: 1337, label: "Germany" },
  { value: 1860, label: "Getafe CF", league: 53 },
  { value: 1596, label: "GIF Sundsvall", league: 56 },
  { value: 1802, label: "Gillingham", league: 60 },
  { value: 101084, label: "Gimnasia", league: 353 },
  { value: 110062, label: "Girona FC", league: 53 },
  { value: 59, label: "Girondins de Bx", league: 16 },
  { value: 111706, label: "Godoy Cruz", league: 353 },
  { value: 420, label: "Górnik Zabrze", league: 66 },
  { value: 101026, label: "Göztepe", league: 68 },
  { value: 110832, label: "Granada CF", league: 54 },
  { value: 322, label: "Grasshopper Club", league: 189 },
  { value: 1338, label: "Greece" },
  { value: 1805, label: "Grenoble Foot", league: 17 },
  { value: 92, label: "Grimsby Town", league: 61 },
  { value: 1880, label: "Guadalajara", league: 341 },
  { value: 111839, label: "Guangzhou", league: 2012 },
  { value: 112429, label: "Guangzhou R&amp;F", league: 2012 },
  { value: 112977, label: "Guizhou Hengfeng", league: 2012 },
  { value: 111588, label: "Gyeongnam FC", league: 83 },
  { value: 112444, label: "H.Consa.Sapporo", league: 349 },
  { value: 110482, label: "Hallescher FC", league: 2076 },
  { value: 28, label: "Hamburg", league: 20 },
  { value: 184, label: "Hamilton", league: 50 },
  { value: 708, label: "Hammarby IF", league: 56 },
  { value: 485, label: "Hannover 96", league: 19 },
  { value: 27, label: "Hansa Rostock", league: 2076 },
  { value: 1738, label: "Havre AC", league: 17 },
  { value: 80, label: "Hearts", league: 50 },
  { value: 112978, label: "Hebei CFFC", league: 2012 },
  { value: 111235, label: "Heidenheim", league: 20 },
  { value: 206, label: "Hellas Verona", league: 32 },
  { value: 111779, label: "Henan Jianye", league: 2012 },
  { value: 100634, label: "Heracles Almelo", league: 10 },
  { value: 166, label: "Hertha BSC", league: 19 },
  { value: 81, label: "Hibernian", league: 50 },
  { value: 100325, label: "HJK Helsinki", league: 322 },
  { value: 112425, label: "Hobro IK", league: 1 },
  { value: 698, label: "Houston Dynamo", league: 39 },
  { value: 1939, label: "Huddersfield", league: 13 },
  { value: 1952, label: "Hull City", league: 14 },
  { value: 1886, label: "Hungary" },
  { value: 111711, label: "Huracán", league: 353 },
  { value: 1341, label: "Iceland" },
  { value: 112658, label: "Icons" },
  { value: 700, label: "IF Elfsborg", league: 56 },
  { value: 319, label: "IFK Göteborg", league: 56 },
  { value: 702, label: "IFK Norrköping", league: 56 },
  { value: 113458, label: "IK Sirius", league: 56 },
  { value: 1524, label: "IK Start", league: 41 },
  { value: 111139, label: "Impact Montréal", league: 39 },
  { value: 110765, label: "Incheon United", league: 83 },
  { value: 101104, label: "Ind. Santa Fe", league: 336 },
  { value: 101103, label: "Indep. Medellín", league: 336 },
  { value: 110093, label: "Independiente", league: 353 },
  { value: 111466, label: "India" },
  { value: 111239, label: "Ingolstadt", league: 20 },
  { value: 2045, label: "Innsbruck", league: 80 },
  { value: 44, label: "Inter", league: 31 },
  { value: 1048, label: "Internacional", league: 7 },
  { value: 94, label: "Ipswich", league: 14 },
  { value: 1355, label: "Ireland" },
  { value: 1343, label: "Italy" },
  { value: 110745, label: "Jagiellonia", league: 66 },
  { value: 112903, label: "Jaguares", league: 336 },
  { value: 1478, label: "Jeju United", league: 83 },
  { value: 1477, label: "Jeonbuk Hyundai", league: 83 },
  { value: 1475, label: "Jeonnam Dragons", league: 83 },
  { value: 112162, label: "Jiangsu Suning", league: 2012 },
  { value: 101144, label: "Júbilo Iwata", league: 349 },
  { value: 101101, label: "Junior", league: 336 },
  { value: 45, label: "Juventus", league: 31 },
  { value: 674, label: "KAA Gent", league: 4 },
  { value: 29, label: "Kaiserslautern", league: 2076 },
  { value: 110929, label: "Kaizer Chiefs", league: 347 },
  { value: 1439, label: "Kalmar FF", league: 56 },
  { value: 1832, label: "Karlsruher SC", league: 2076 },
  { value: 2013, label: "KAS Eupen", league: 4 },
  { value: 101147, label: "Kashima Antlers", league: 349 },
  { value: 101145, label: "Kashiwa Reysol", league: 349 },
  { value: 111339, label: "Kasimpaşa", league: 68 },
  { value: 111730, label: "Kawasaki-F", league: 349 },
  { value: 101020, label: "Kayserispor", league: 68 },
  { value: 167, label: "KFC Uerdingen", league: 2076 },
  { value: 576, label: "Kiel", league: 20 },
  { value: 82, label: "Kilmarnock", league: 50 },
  { value: 31, label: "Köln", league: 20 },
  { value: 101033, label: "Konyaspor", league: 68 },
  { value: 111083, label: "Korona Kielce", league: 66 },
  { value: 673, label: "KRC Genk", league: 4 },
  { value: 113459, label: "Kristiansund", league: 41 },
  { value: 100081, label: "KV Kortrijk", league: 4 },
  { value: 682, label: "KV Oostende", league: 4 },
  { value: 212, label: "La Berrichonne", league: 17 },
  { value: 112523, label: "La Equidad", league: 336 },
  { value: 697, label: "LA Galaxy", league: 39 },
  { value: 110741, label: "La Spezia", league: 32 },
  { value: 112996, label: "LAFC", league: 39 },
  { value: 110395, label: "Lanús", league: 353 },
  { value: 252, label: "LASK Linz", league: 80 },
  { value: 46, label: "Lazio", league: 31 },
  { value: 347, label: "Lecce", league: 32 },
  { value: 873, label: "Lech Poznań", league: 66 },
  { value: 111091, label: "Lechia Gdańsk", league: 66 },
  { value: 8, label: "Leeds United", league: 14 },
  { value: 1871, label: "Legia Warszawa", league: 66 },
  { value: 95, label: "Leicester City", league: 13 },
  { value: 110781, label: "León", league: 341 },
  { value: 112970, label: "Leones", league: 336 },
  { value: 1853, label: "Levante UD", league: 53 },
  { value: 32, label: "Leverkusen", league: 19 },
  { value: 299, label: "Lillestrøm SK", league: 41 },
  { value: 111285, label: "Limerick FC", league: 65 },
  { value: 149, label: "Lincoln City", league: 61 },
  { value: 9, label: "Liverpool", league: 13 },
  { value: 621, label: "Livingston", league: 50 },
  { value: 1844, label: "Livorno", league: 32 },
  { value: 111035, label: "Lobos", league: 341 },
  { value: 100765, label: "Lokomotiv Moscow", league: 3006 },
  { value: 65, label: "LOSC Lille", league: 16 },
  { value: 1923, label: "Luton Town", league: 60 },
  { value: 23, label: "M'gladbach", league: 19 },
  { value: 1959, label: "Macclesfield", league: 61 },
  { value: 110588, label: "Magdeburg", league: 20 },
  { value: 573, label: "Málaga CF", league: 54 },
  { value: 320, label: "Malmö FF", league: 56 },
  { value: 10, label: "Manchester City", league: 13 },
  { value: 11, label: "Manchester Utd", league: 13 },
  { value: 1940, label: "Mansfield Town", league: 61 },
  { value: 1893, label: "Marítimo", league: 308 },
  { value: 111397, label: "Melb. Victory", league: 351 },
  { value: 112224, label: "Melbourne City", league: 351 },
  { value: 1386, label: "Mexico" },
  { value: 12, label: "Middlesbrough", league: 14 },
  { value: 112505, label: "Miedź Legnica", league: 66 },
  { value: 47, label: "Milan", league: 31 },
  { value: 101105, label: "Millonarios", league: 336 },
  { value: 97, label: "Millwall", league: 14 },
  { value: 111138, label: "Minnesota United", league: 39 },
  { value: 1798, label: "MK Dons", league: 61 },
  { value: 101007, label: "MKE Ankaragücü", league: 68 },
  { value: 417, label: "Molde FK", league: 41 },
  { value: 1028, label: "Monarcas Morelia", league: 341 },
  { value: 1032, label: "Monterrey", league: 341 },
  { value: 70, label: "Montpellier HSC", league: 16 },
  { value: 357, label: "Morecambe", league: 61 },
  { value: 1900, label: "Moreirense FC", league: 308 },
  { value: 83, label: "Motherwell", league: 50 },
  { value: 1904, label: "NAC Breda", league: 10 },
  { value: 112092, label: "Nagoya Grampus", league: 349 },
  { value: 1823, label: "Nancy", league: 17 },
  { value: 48, label: "Napoli", league: 31 },
  { value: 15019, label: "Nàstic", league: 54 },
  { value: 101121, label: "Necaxa", league: 341 },
  { value: 105035, label: "Netherlands" },
  { value: 435, label: "Neuchâtel Xamax", league: 189 },
  { value: 691, label: "New England", league: 39 },
  { value: 112828, label: "New York City FC", league: 39 },
  { value: 111473, label: "New Zealand" },
  { value: 111398, label: "Newcastle Jets", league: 351 },
  { value: 13, label: "Newcastle Utd", league: 13 },
  { value: 110396, label: "Newell's", league: 353 },
  { value: 112254, label: "Newport County", league: 61 },
  { value: 224, label: "Nîmes Olympique", league: 16 },
  { value: 1930, label: "Northampton", league: 61 },
  { value: 110081, label: "Northern Ireland" },
  { value: 1352, label: "Norway" },
  { value: 1792, label: "Norwich", league: 14 },
  { value: 14, label: "Nott'm Forest", league: 14 },
  { value: 1937, label: "Notts County", league: 61 },
  { value: 689, label: "NY Red Bulls", league: 39 },
  { value: 1456, label: "Odds BK", league: 41 },
  { value: 272, label: "Odense BK", league: 1 },
  { value: 72, label: "OGC Nice", league: 16 },
  { value: 113219, label: "Ohod Club", league: 350 },
  { value: 66, label: "OL", league: 16 },
  { value: 1920, label: "Oldham Athletic", league: 61 },
  { value: 280, label: "Olympiacos CFP", league: 63 },
  { value: 219, label: "OM", league: 16 },
  { value: 101106, label: "Once Caldas", league: 336 },
  { value: 705, label: "Örebro SK", league: 56 },
  { value: 112606, label: "Orlando City", league: 39 },
  { value: 110930, label: "Orlando Pirates", league: 347 },
  { value: 1889, label: "Os Belenenses", league: 308 },
  { value: 113173, label: "Östersunds FK", league: 56 },
  { value: 1951, label: "Oxford United", league: 60 },
  { value: 110147, label: "Pachuca", league: 341 },
  { value: 10030, label: "Paderborn", league: 20 },
  { value: 110912, label: "Padova", league: 32 },
  { value: 1843, label: "Palermo", league: 32 },
  { value: 1884, label: "Panathinaikos", league: 63 },
  { value: 393, label: "PAOK", league: 63 },
  { value: 1375, label: "Paraguay" },
  { value: 111048, label: "Paraná", league: 7 },
  { value: 73, label: "Paris", league: 16 },
  { value: 111817, label: "Paris FC", league: 17 },
  { value: 50, label: "Parma", league: 31 },
  { value: 112527, label: "Patriotas", league: 336 },
  { value: 110581, label: "Patronato", league: 353 },
  { value: 1914, label: "PEC Zwolle", league: 10 },
  { value: 111399, label: "Perth Glory", league: 351 },
  { value: 111108, label: "Peru" },
  { value: 199, label: "Perugia", league: 32 },
  { value: 200, label: "Pescara", league: 32 },
  { value: 1938, label: "Peterborough", league: 60 },
  { value: 112134, label: "Philadelphia", league: 39 },
  { value: 111086, label: "Piast Gliwice", league: 66 },
  { value: 1929, label: "Plymouth Argyle", league: 60 },
  { value: 110746, label: "Pogoń Szczecin", league: 66 },
  { value: 1474, label: "Pohang Steelers", league: 83 },
  { value: 1353, label: "Poland" },
  { value: 1928, label: "Port Vale", league: 61 },
  { value: 10031, label: "Portimonense SC", league: 308 },
  { value: 111140, label: "Portland Timbers", league: 39 },
  { value: 1790, label: "Portsmouth", league: 60 },
  { value: 1354, label: "Portugal" },
  { value: 1801, label: "Preston", league: 14 },
  { value: 531, label: "Preußen Münster", league: 2076 },
  { value: 247, label: "PSV", league: 10 },
  { value: 110152, label: "Puebla", league: 341 },
  { value: 15, label: "QPR", league: 14 },
  { value: 110150, label: "Querétaro", league: 341 },
  { value: 110827, label: "R. Oviedo", league: 54 },
  { value: 459, label: "R. Sporting", league: 54 },
  { value: 462, label: "R. Valladolid CF", league: 53 },
  { value: 244, label: "R. Zaragoza", league: 54 },
  { value: 101085, label: "Racing Club", league: 353 },
  { value: 1786, label: "Randers FC", league: 1 },
  { value: 86, label: "Rangers", league: 50 },
  { value: 112200, label: "Ranheim Fotball", league: 41 },
  { value: 480, label: "Rayo Vallecano", league: 53 },
  { value: 112172, label: "RB Leipzig", league: 19 },
  { value: 191, label: "RB Salzburg", league: 80 },
  { value: 450, label: "RC Celta", league: 53 },
  { value: 242, label: "RC Deportivo", league: 54 },
  { value: 64, label: "RC Lens", league: 17 },
  { value: 76, label: "RC Strasbourg", league: 16 },
  { value: 452, label: "RCD Espanyol", league: 53 },
  { value: 453, label: "RCD Mallorca", league: 54 },
  { value: 1793, label: "Reading", league: 14 },
  { value: 449, label: "Real Betis", league: 53 },
  { value: 243, label: "Real Madrid", league: 53 },
  { value: 111065, label: "Real Salt Lake", league: 39 },
  { value: 457, label: "Real Sociedad", league: 53 },
  { value: 111273, label: "Red Star FC", league: 17 },
  { value: 543, label: "Regensburg", league: 20 },
  { value: 744, label: "Rio Ave FC", league: 308 },
  { value: 112578, label: "Rionegro Águilas", league: 336 },
  { value: 1876, label: "River Plate", league: 353 },
  { value: 1955, label: "Rochdale", league: 60 },
  { value: 52, label: "Roma", league: 31 },
  { value: 1356, label: "Romania" },
  { value: 110580, label: "Rosario Central", league: 353 },
  { value: 298, label: "Rosenborg BK", league: 41 },
  { value: 1797, label: "Rotherham Utd", league: 14 },
  { value: 230, label: "Royal Antwerp FC", league: 4 },
  { value: 229, label: "RSC Anderlecht", league: 4 },
  { value: 1357, label: "Russia" },
  { value: 113157, label: "S-Hiroshima", league: 349 },
  { value: 111379, label: "S. Großaspach", league: 2076 },
  { value: 113160, label: "Sagan Tosu", league: 349 },
  { value: 110373, label: "Salerno", league: 32 },
  { value: 1837, label: "Sampdoria", league: 31 },
  { value: 1013, label: "San Lorenzo", league: 353 },
  { value: 112668, label: "San Luis", league: 335 },
  { value: 111713, label: "San Martín", league: 353 },
  { value: 1757, label: "Sandefjord", league: 41 },
  { value: 110178, label: "Sandhausen", league: 20 },
  { value: 2055, label: "Sangju Sangmu", league: 83 },
  { value: 1438, label: "Santa Clara", league: 308 },
  { value: 1053, label: "Santos", league: 7 },
  { value: 110144, label: "Santos Laguna", league: 341 },
  { value: 112199, label: "Sarpsborg 08 FF", league: 41 },
  { value: 111974, label: "Sassuolo", league: 31 },
  { value: 1896, label: "SC Braga", league: 308 },
  { value: 25, label: "SC Freiburg", league: 19 },
  { value: 1913, label: "SC Heerenveen", league: 10 },
  { value: 34, label: "Schalke", league: 19 },
  { value: 1359, label: "Scotland" },
  { value: 15009, label: "SCR Altach", league: 80 },
  { value: 1949, label: "Scunthorpe Utd", league: 60 },
  { value: 467, label: "SD Eibar", league: 53 },
  { value: 110839, label: "SD Huesca", league: 53 },
  { value: 111144, label: "Seattle Sounders", league: 39 },
  { value: 481, label: "Sevilla FC", league: 53 },
  { value: 101059, label: "Shakhtar Donetsk", league: 332 },
  { value: 306, label: "Shamrock Rovers", league: 65 },
  { value: 111724, label: "Shandong Luneng", league: 2012 },
  { value: 110955, label: "Shanghai Shenhua", league: 2012 },
  { value: 112540, label: "Shanghai SIPG", league: 2012 },
  { value: 1794, label: "Sheffield Utd", league: 14 },
  { value: 1807, label: "Sheffield Wed", league: 14 },
  { value: 101149, label: "Shimizu S-Pulse", league: 349 },
  { value: 113161, label: "Shonan Bellmare", league: 349 },
  { value: 127, label: "Shrewsbury", league: 60 },
  { value: 680, label: "Sint-Truiden", league: 4 },
  { value: 101041, label: "Sivasspor", league: 68 },
  { value: 111928, label: "SJ Earthquakes", league: 39 },
  { value: 919, label: "SK Brann", league: 41 },
  { value: 254, label: "SK Rapid Wien", league: 80 },
  { value: 209, label: "SK Sturm Graz", league: 80 },
  { value: 112075, label: "SKN St. Pölten", league: 80 },
  { value: 234, label: "SL Benfica", league: 308 },
  { value: 111092, label: "Śląsk Wrocław", league: 66 },
  { value: 266, label: "Slavia Praha", league: 319 },
  { value: 563, label: "Sligo Rovers", league: 65 },
  { value: 1361, label: "Slovenia" },
  { value: 210, label: "SM Caen", league: 16 },
  { value: 111714, label: "SM Tucumán", league: 353 },
  { value: 1447, label: "SønderjyskE", league: 1 },
  { value: 111099, label: "South Africa" },
  { value: 17, label: "Southampton", league: 13 },
  { value: 1954, label: "Southend United", league: 60 },
  { value: 670, label: "Sp. Charleroi", league: 4 },
  { value: 1362, label: "Spain" },
  { value: 112791, label: "SPAL", league: 31 },
  { value: 267, label: "Sparta Praha", league: 319 },
  { value: 100767, label: "Spartak Moscow", league: 3006 },
  { value: 2007, label: "Sport. Lokeren", league: 4 },
  { value: 110700, label: "Sportfreunde Lotte", league: 2076 },
  { value: 237, label: "Sporting CP", league: 308 },
  { value: 696, label: "Sporting KC", league: 39 },
  { value: 18, label: "Spurs", league: 13 },
  { value: 100804, label: "St. Johnstone", league: 50 },
  { value: 100805, label: "St. Mirren", league: 50 },
  { value: 423, label: "St. Pats", league: 65 },
  { value: 110329, label: "St. Pauli", league: 20 },
  { value: 917, label: "Stabæk Fotball", league: 41 },
  { value: 378, label: "Stade Brestois", league: 17 },
  { value: 379, label: "Stade de Reims", league: 16 },
  { value: 74, label: "Stade Rennais", league: 16 },
  { value: 232, label: "Standard Liège", league: 4 },
  { value: 361, label: "Stevenage", league: 61 },
  { value: 1806, label: "Stoke City", league: 14 },
  { value: 922, label: "Strømsgodset IF", league: 41 },
  { value: 106, label: "Sunderland", league: 60 },
  { value: 983, label: "Suwon Samsung", league: 83 },
  { value: 1785, label: "SV Mattersburg", league: 80 },
  { value: 110597, label: "SV Meppen", league: 2076 },
  { value: 492, label: "SV Wehen", league: 2076 },
  { value: 1960, label: "Swansea City", league: 14 },
  { value: 1363, label: "Sweden" },
  { value: 1934, label: "Swindon Town", league: 61 },
  { value: 1364, label: "Switzerland" },
  { value: 111400, label: "Sydney FC", league: 351 },
  { value: 112670, label: "Talleres", league: 353 },
  { value: 112983, label: "Tianjin Quanjian", league: 2012 },
  { value: 111774, label: "Tianjin TEDA", league: 2012 },
  { value: 111715, label: "Tigre", league: 353 },
  { value: 1970, label: "Tigres", league: 341 },
  { value: 111678, label: "Tijuana", league: 341 },
  { value: 1882, label: "Toluca", league: 341 },
  { value: 54, label: "Torino", league: 31 },
  { value: 111651, label: "Toronto FC", league: 39 },
  { value: 1809, label: "Toulouse FC", league: 16 },
  { value: 436, label: "Trabzonspor", league: 68 },
  { value: 15048, label: "Tranmere Rovers", league: 61 },
  { value: 703, label: "Trelleborgs FF", league: 56 },
  { value: 418, label: "Tromsø IL", league: 41 },
  { value: 10029, label: "TSG Hoffenheim", league: 19 },
  { value: 2017, label: "TSV Hartberg", league: 80 },
  { value: 1365, label: "Turkey" },
  { value: 1881, label: "U.N.A.M.", league: 341 },
  { value: 1861, label: "UD Almería", league: 54 },
  { value: 472, label: "UD Las Palmas", league: 54 },
  { value: 55, label: "Udinese", league: 31 },
  { value: 1473, label: "Ulsan Hyundai", league: 83 },
  { value: 110975, label: "Uni. Católica", league: 335 },
  { value: 112534, label: "Uni. Concepción", league: 335 },
  { value: 15029, label: "Uni. de Chile", league: 335 },
  { value: 111716, label: "Unión", league: 353 },
  { value: 1831, label: "Union Berlin", league: 20 },
  { value: 110977, label: "Unión Española", league: 335 },
  { value: 112535, label: "Unión La Calera", league: 335 },
  { value: 1387, label: "United States" },
  { value: 172, label: "Unterhaching", league: 2076 },
  { value: 111575, label: "Urawa Reds", league: 349 },
  { value: 1377, label: "Uruguay" },
  { value: 112276, label: "US Orléans", league: 17 },
  { value: 112448, label: "V-Varen Nagasaki", league: 349 },
  { value: 1887, label: "V. Guimarães", league: 308 },
  { value: 665, label: "V. Setúbal", league: 308 },
  { value: 461, label: "Valencia CF", league: 53 },
  { value: 110456, label: "Valenciennes FC", league: 17 },
  { value: 920, label: "Vålerenga Fotball", league: 41 },
  { value: 112836, label: "Vegalta Sendai", league: 349 },
  { value: 822, label: "Vejle Boldklub", league: 1 },
  { value: 101088, label: "Vélez Sarsfield", league: 353 },
  { value: 111660, label: "Vendsyssel FF", league: 1 },
  { value: 205, label: "Venezia", league: 32 },
  { value: 111487, label: "Venezuela" },
  { value: 110145, label: "Veracruz", league: 341 },
  { value: 36, label: "VfB Stuttgart", league: 19 },
  { value: 487, label: "VfL Osnabrück", league: 2076 },
  { value: 175, label: "VfL Wolfsburg", league: 19 },
  { value: 550, label: "VfR Aalen", league: 2076 },
  { value: 110468, label: "Viktoria Plzeň", league: 319 },
  { value: 483, label: "Villarreal CF", league: 53 },
  { value: 101146, label: "Vissel Kobe", league: 349 },
  { value: 1909, label: "Vitesse", league: 10 },
  { value: 1719, label: "Vitória", league: 7 },
  { value: 100651, label: "VVV-Venlo", league: 10 },
  { value: 110913, label: "Waasl. Beveren", league: 4 },
  { value: 1367, label: "Wales" },
  { value: 1803, label: "Walsall", league: 60 },
  { value: 753, label: "Waterford FC", league: 65 },
  { value: 1795, label: "Watford", league: 13 },
  { value: 111766, label: "Well. Phoenix", league: 351 },
  { value: 38, label: "Werder Bremen", league: 19 },
  { value: 109, label: "West Brom", league: 14 },
  { value: 19, label: "West Ham", league: 13 },
  { value: 101112, label: "Whitecaps FC", league: 39 },
  { value: 1917, label: "Wigan Athletic", league: 14 },
  { value: 1907, label: "Willem II", league: 10 },
  { value: 1873, label: "Wisła Kraków", league: 66 },
  { value: 1569, label: "Wisła Płock", league: 66 },
  { value: 111822, label: "Wolfsberger AC", league: 80 },
  { value: 110, label: "Wolves", league: 13 },
  { value: 112427, label: "WS Wanderers", league: 351 },
  { value: 110197, label: "Würzburg", league: 2076 },
  { value: 1933, label: "Wycombe", league: 60 },
  { value: 113259, label: "Yeni Malatyaspor", league: 68 },
  { value: 346, label: "Yeovil Town", league: 61 },
  { value: 101151, label: "Yokohama F･M", league: 349 },
  { value: 111089, label: "Z. Sosnowiec", league: 66 },
  { value: 110749, label: "Zagłębie Lubin", league: 66 },
  { value: 15005, label: "Zulte Waregem", league: 4 }
];
