var baseAppAddress = "https://webapp.octofut.com";
var loginUrl = "https://api-debug.octofut.com:8889";
var pMode = "production";

console.log("[PROCESS]", process.env);
if (process.env && process.env.NODE_ENV === "development") {
  baseAppAddress = "http://localhost:3000";
  loginUrl = "http://localhost:40887";
  pMode = "sandbox";
}
console.log("[USED URLS]", { baseAppAddress, loginUrl });

export const LOGIN_URL: string = loginUrl;
export const APP_BASE_ADDRESS: string = baseAppAddress;
export const PAYMENT_ENV: string = pMode;
