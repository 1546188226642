import React from "react";
import { Theme } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  grayColor,
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader
} from "../../assets/jss";
import color from "@material-ui/core/colors/indigo";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardIcon: {
      "&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$roseCardHeader": {
        borderRadius: "3px",
        backgroundColor: grayColor[0],
        padding: "15px",
        marginTop: "-30px",
        marginRight: "15px",
        float: "left"
      }
    },
    warningCardHeader,
    successCardHeader,
    dangerCardHeader,
    infoCardHeader,
    primaryCardHeader,
    roseCardHeader
  })
);

export function CardIcon({ ...props }) {
  const classes = useStyles({});
  const { className, children, color, ...rest } = props;
  const cardIconClasses = classNames({
    [classes.cardIcon]: true,
    [classes.warningCardHeader]: color === "warning",
    [classes.successCardHeader]: color === "success",
    [classes.dangerCardHeader]: color === "danger",
    [classes.infoCardHeader]: color === "info",
    [classes.primaryCardHeader]: color === "primary",
    [classes.roseCardHeader]: color === "rose",
    [className]: className !== undefined
  });
  return (
    <div className={cardIconClasses} {...rest}>
      {children}
    </div>
  );
}

CardIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "info",
    "primary",
    "rose"
  ])
};
