import React from "react";
import InternalDeleteIcon from "@material-ui/icons/DeleteOutlined";
import AddIcon from "@material-ui/icons/Add";
import PlusIcon from "@material-ui/icons/Add";
import MinusIcon from "@material-ui/icons/Remove";
import StartIcon from "@material-ui/icons/PlayArrow";
import StopIcon from "@material-ui/icons/Stop";
import DashboardIcon from "@material-ui/icons/Dashboard";
import BotsIcon from "@material-ui/icons/RecentActors";
import ShopListIcon from "@material-ui/icons/ShoppingBasket";
import AnalyticsIcon from "@material-ui/icons/ShowChart";
import SubscribeIcon from "@material-ui/icons/AddShoppingCart";
import TransactionIcon from "@material-ui/icons/SwapHoriz";
import SearchIcon from "@material-ui/icons/Search";
import FuzzySearchIcon from "@material-ui/icons/LocationSearching";
import CardsToUseIcon from "@material-ui/icons/Vibration";
import OpenLockIcon from "@material-ui/icons/LockOpenTwoTone";
import ClosedLockIcon from "@material-ui/icons/LockTwoTone";
import ExternalLinkIcon from "@material-ui/icons/OpenInNew";
import LeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import RightIcon from "@material-ui/icons/KeyboardArrowRight";
import SettingsIcon from "@material-ui/icons/Settings";
import OverviewIcon from "@material-ui/icons/DashboardSharp";
import RefreshIcon from "@material-ui/icons/Refresh";
import SaveIcon from "@material-ui/icons/Save";
import ResetIcon from "@material-ui/icons/Close";
import CheckedHelpIcon from "@material-ui/icons/Help";
import UncheckedHelpIcon from "@material-ui/icons/HelpOutline";
import EditIcon from "@material-ui/icons/Edit";
import HistoryIcon from "@material-ui/icons/History";
import PileIcon from "@material-ui/icons/List";
import LogsIcon from "@material-ui/icons/FormatAlignLeft";
import CaptchasIcon from "@material-ui/icons/BlurOn";
import SalesIcon from "@material-ui/icons/Store";
import EmptyPileIcon from "@material-ui/icons/FreeBreakfast";
import LanguageIcon from "@material-ui/icons/Language";
import AccountIcon from "@material-ui/icons/AccountCircle";
import SalesMadeIcon from "@material-ui/icons/Launch";
import ProfitsIcon from "@material-ui/icons/Input";
import DealsMadeIcon from "@material-ui/icons/WhatshotTwoTone";
import OctofutiumsIcon from "@material-ui/icons/MonetizationOn";
import ShutDownIcon from "@material-ui/icons/PowerSettingsNew";
import ComingSoonIcon from "@material-ui/icons/BrushOutlined";
import CloseIcon from "@material-ui/icons/Close";

function DeleteIcon() {
  return <InternalDeleteIcon style={{ color: "#ff8a8a" }} />;
}

export {
  CloseIcon,
  MinusIcon,
  PlusIcon,
  ComingSoonIcon,
  ShutDownIcon,
  SalesMadeIcon,
  ProfitsIcon,
  DealsMadeIcon,
  OctofutiumsIcon,
  AccountIcon,
  LanguageIcon,
  EmptyPileIcon,
  HistoryIcon,
  PileIcon,
  LogsIcon,
  CaptchasIcon,
  SalesIcon,
  EditIcon,
  SaveIcon,
  ResetIcon,
  CheckedHelpIcon,
  UncheckedHelpIcon,
  DeleteIcon,
  AddIcon,
  StartIcon,
  StopIcon,
  DashboardIcon,
  BotsIcon,
  ShopListIcon,
  AnalyticsIcon,
  SubscribeIcon,
  TransactionIcon,
  FuzzySearchIcon,
  CardsToUseIcon,
  SearchIcon,
  OpenLockIcon,
  ClosedLockIcon,
  ExternalLinkIcon,
  LeftIcon,
  RightIcon,
  SettingsIcon,
  OverviewIcon,
  RefreshIcon,
};
