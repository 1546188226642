import React, { useState, useEffect } from "react";
import {
  Theme,
  withStyles,
  LinearProgress,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import { useBotGuid } from "../..";
import { useRuntimeApi } from "../../../../../../api";
import {
  BotManagerApi,
  BotViewModel,
  AuctionViewModel,
} from "@futbot/runtime-api";
import { PlayerCard } from "../../../../../../components/playerCard";
import { EmptyPile } from "./EmptyPile";
import { PlayerInfo } from "../../../../../../components/playerInfo";
import { usePerfectScroll } from "../../../../../../hooks";
import { Coins } from "../../../../../../components/coins";

const style = (theme: Theme): StyleRules => ({});

export function BotPileRaw(props: { classes: any; pile: string }) {
  const { classes, pile } = props;
  const { botGuid } = useBotGuid();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [auctions, setAuctions] = useState<AuctionViewModel[]>(null);
  const api = useRuntimeApi(BotManagerApi);
  const wrapperRef = usePerfectScroll();
  useEffect(() => {
    setIsLoading(true);
    api.getBotPile({ botId: botGuid, pile }).then((b) => {
      setAuctions(b.auctions);
      setIsLoading(false);
    });
  }, [botGuid]);

  if (isLoading || !auctions) {
    return (
      <div ref={wrapperRef}>
        <LinearProgress />
      </div>
    );
  }

  if (auctions.length === 0) {
    return (
      <div ref={wrapperRef}>
        <EmptyPile />
      </div>
    );
  }

  return (
    <div>
      <div className={classes.tableWrapper} ref={wrapperRef}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Player</TableCell>
              <TableCell>Expires in</TableCell>
              <TableCell>Current bid</TableCell>
              <TableCell>Buy now price</TableCell>
            </TableRow>
            {auctions.map((auction, i) => {
              return (
                <TableRow hover key={i}>
                  <TableCell>
                    <PlayerInfo {...auction.playerCard} />
                  </TableCell>
                  <TableCell>
                    <div className={classes.creationDate}>
                      {auction.expires}
                    </div>
                  </TableCell>
                  <TableCell>
                    <Coins amount={auction.currentBid} />
                  </TableCell>
                  <TableCell>
                    <Coins amount={auction.buyNowPrice} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

export const BotPile = withStyles(style)(BotPileRaw);
