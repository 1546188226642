import React, { useCallback } from "react";
import { Theme } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { State } from "../../store/config/configureStore";
import { useMappedState } from "redux-react-hook";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    blurred: {
      textShadow: "0 0 10px #fff",
      filter: "blur(4px)"
    }
  })
);

export function Anonymizable(props: { children?: React.ReactNode }) {
  const classes = useStyles({});
  const mapStateToProps = useCallback(
    (state: State) => ({
      anonymizeData: state.misc.anonymizeData
    }),
    []
  );
  const { anonymizeData } = useMappedState(mapStateToProps);
  const { children } = props;
  return (
    <span className={classNames({ [classes.blurred]: anonymizeData })}>
      {children}
    </span>
  );
}
