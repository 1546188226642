import { useMediaQuery, Theme, useTheme } from "@material-ui/core";

export function useScreenSize() {
  const theme: Theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  const matches = useMediaQuery("(min-width:1470px)");
  return {
    isSmall: !matches,
    width:
      keys.reduce((output, key) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const matches = useMediaQuery(theme.breakpoints.up(key));
        return !output && matches ? key : output;
      }, null) || "xs"
  };
}
