import React from "react";
import { Theme } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import CheckIcon from "@material-ui/icons/Check";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "absolute",
      transform: "translate(-50%, -50%)",
      top: "50%",
      left: "50%",
      textAlign: "center",
      color: "rgba(85, 85, 85, 0.31)",
      fontSize: 30,
    },
    icon: {
      fontSize: 150,
    },
  })
);

export function NoCaptchas(props: {}) {
  const classes = useStyles({});
  const {} = props;
  return (
    <div className={classes.root}>
      <CheckIcon className={classes.icon} />
      <div>No captchas have been triggered so far</div>
    </div>
  );
}
