import React from "react";

import { RouteInfo } from "../../../../utils/routeUtils";
import { BotOverview } from "./components/botOverview/BotOverview";
import { BotSettings } from "./components/botSettings/BotSettings";
import { BotPile } from "./components/botPile/BotPile";
import { BotHistory } from "./components/botHistory/BotHistory";
import { BotCaptchaReview } from "./components/botCaptchaReview/BotCaptchaReview";
import { BotLogs } from "./components/botLogs/BotLogs";
import {
  OverviewIcon,
  SettingsIcon,
  PileIcon,
  HistoryIcon,
  LogsIcon,
  CaptchasIcon,
  SalesIcon
} from "../../../../assets/icons";

const routes: RouteInfo[] = [
  {
    path: "/overview",
    name: "bots.menu.overview",
    icon: OverviewIcon,
    component: () => <BotOverview />
  },
  {
    path: "/settings",
    name: "bots.menu.settings",
    icon: SettingsIcon,
    component: () => <BotSettings />
  },
  {
    path: "/tradepile",
    name: "bots.menu.tradepile",
    icon: PileIcon,
    component: () => <BotPile pile="tradepile" />
  },
  {
    path: "/history",
    name: "bots.menu.deals",
    icon: HistoryIcon,
    component: () => <BotHistory />
  },
  {
    path: "/logs",
    name: "bots.menu.logs",
    icon: LogsIcon,
    component: () => <BotLogs />
  },
  {
    path: "/captchas",
    name: "bots.menu.captchas",
    icon: CaptchasIcon,
    component: () => <BotCaptchaReview />
  },
  {
    path: "/sales",
    name: "bots.menu.sales",
    icon: SalesIcon,
    component: () => <div>Deals History</div>
  }
];

export default routes;
