import React, { useEffect, useState } from "react";
import { Theme, Paper, LinearProgress } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useGuid } from "../..";
import { useStatApi } from "../../../../../../api";
import { ShopListsApi, ShopListViewModel } from "@futbot/stats-api";
import { ShopListDetailsHeader } from "./ShopListDetailsHeader";
import { ShopListCardsTable } from "../shopListCardsTable";
import { ShopListFuzzySearchesTable } from "../shopListFuzzySearchesTable";
import { useLoading } from "../../../../../../hooks/useLoading";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
      height: "calc(100vh - 82px)"
    }
  })
);

export function ShopListEditor(props: { guid: number; onClose?: () => void }) {
  const classes = useStyles({});
  const api = useStatApi(ShopListsApi);
  const { isLoading, setIsLoading } = useLoading();
  const [tab, setTab] = useState(0);
  const [reload, setReload] = useState(0);
  const [shoplist, setShoplist] = useState<ShopListViewModel>(null);
  const { guid, onClose } = props;

  useEffect(() => {
    if (!guid) {
      return;
    }
    setIsLoading(true);
    api
      .getShopList({ listId: guid })
      .then(sl => {
        setShoplist(sl);
      })
      .catch(r => {
        console.log(r);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [guid, reload]);

  return (
    <Paper className={classes.root}>
      <ShopListDetailsHeader
        onClose={onClose}
        shoplist={shoplist}
        onTabSwitch={setTab}
      />
      {tab == 0 && (
        <ShopListCardsTable
          shoplist={shoplist}
          isLoading={isLoading}
          onReload={() => {
            setReload(reload + 1);
          }}
        />
      )}
      {tab == 1 && (
        <ShopListFuzzySearchesTable
          shoplist={shoplist}
          isLoading={isLoading}
          onReload={() => {
            setReload(reload + 1);
          }}
        />
      )}
    </Paper>
  );
}
