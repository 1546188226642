import React, { useState } from "react";
import {
  Theme,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    height: {
      height: 69
    },
    label: {
      fontFamily: "Cairo",
      height: 32,
      display: "inline-block",
      verticalAlign: "middle",
      fontSize: "1.2em",
      color: "#03186c"
    }
  })
);

export function AssetPicker(props: {
  initialValue?: number;
  assetLabel: string;
  anyLabel: string;
  source: { value: number; label: string }[];
  getImgLink: (value: number) => string;
  getAnyImgLink?: () => string;
  onChange?: (value: number) => void;
  className?: string;
}) {
  const {
    assetLabel,
    anyLabel,
    source,
    getImgLink,
    getAnyImgLink,
    onChange,
    initialValue,
    className
  } = props;
  const classes = useStyles({});
  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const [value, setValue] = useState(
    initialValue ? initialValue.toString() : 0
  );
  const [labelWidth, setLabelWidth] = React.useState(0);
  const [focused, setFocused] = useState(false);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current!.offsetWidth);
  }, [focused]);
  const { t, i18n } = useTranslation();
  return (
    <FormControl
      className={classNames(classes.margin, className)}
      onFocus={() => {
        setFocused(true);
      }}
      onBlur={() => {
        setFocused(false);
      }}
      variant="outlined"
      fullWidth
    >
      <InputLabel ref={inputLabel} htmlFor="outlined-age-native-simple">
        {assetLabel}
      </InputLabel>
      <Select
        className={classes.height}
        value={value}
        onChange={(e: any) => {
          setValue(e.target.value);
          if (!onChange) {
            return;
          }
          var num = parseInt(e.target.value);
          onChange(Number.isNaN(num) ? 0 : num);
        }}
        input={
          <OutlinedInput
            labelWidth={labelWidth}
            id="outlined-age-native-simple"
          />
        }
      >
        <MenuItem value={0}>
          {getAnyImgLink && (
            <img
              style={{
                marginRight: 10,
                width: 32,
                content: getAnyImgLink()
              }}
            />
          )}
          <span className={classes.label}>{anyLabel}</span>
        </MenuItem>
        {source.map(n => (
          <MenuItem value={n.value}>
            <div style={{ height: 32 }}>
              <img
                style={{
                  marginRight: 10,
                  width: 32,
                  content: getImgLink(n.value)
                }}
              />
              <span className={classes.label}>{n.label}</span>
            </div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
