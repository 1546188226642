import React, { useState, useCallback } from "react";
import classNames from "classnames";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { RouteInfo } from "../../utils/routeUtils";
import { shouldDisableLanguagePicker } from "../../utils/featureUtils";
import { useMappedState } from "redux-react-hook";
import { State } from "../../store/config/configureStore";
import { useTranslation } from "react-i18next";
import { LanguagePicker } from "../languagePicker";
import { AccountIcon } from "../../assets/icons";
import { LeftMenu } from "./LeftMenu";
import { RightMenu } from "./RightMenu";
import { useStyles } from "./useStyles";
import { useScreenSize } from "../../hooks/useScreenSize";
import { CurrencyViewer } from "../currencyViewer";

export function AppMenu(props: {
  routes?: RouteInfo[];
  modulePath?: string | string[];
  isOpen?: boolean;
  onToggle?: (isOpen: boolean) => void;
}) {
  const classes = useStyles({});
  const { t } = useTranslation();
  const { isOpen, modulePath, routes, onToggle } = props;
  const [isLeftMenuOpen, setIsLeftMenuOpen] = useState<boolean>(isOpen);
  const [isRightMenuOpen, setIsRightMenuOpen] = useState<boolean>();
  const mapStateToProps = useCallback(
    (state: State) => ({
      pathname: state.router.location.pathname
    }),
    []
  );
  const { pathname } = useMappedState(mapStateToProps);

  function getTitle() {
    var title;
    routes
      .filter(r => !r.disabled)
      .map(route => {
        if (modulePath + route.path === pathname) {
          title = t(route.name);
        }
        return null;
      });
    return title;
  }

  function toggle() {
    onToggle(!isLeftMenuOpen);
    setIsLeftMenuOpen(!isLeftMenuOpen);
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={classNames(classes.appBar, {
          [classes.appBarShift]: isLeftMenuOpen
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={toggle}
            className={classNames(classes.menuButton, {
              [classes.hide]: isLeftMenuOpen
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            color="inherit"
            className={classNames(classes.grow, classes.title)}
          >
            {getTitle()}
          </Typography>
          <div className={classes.grow} />
          <CurrencyViewer />
          {!shouldDisableLanguagePicker() && <LanguagePicker />}
          <IconButton
            color="inherit"
            onClick={() => setIsRightMenuOpen(!isRightMenuOpen)}
          >
            <AccountIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <LeftMenu
        routes={routes}
        onToggle={toggle}
        isOpen={isLeftMenuOpen}
        modulePath={modulePath}
      />
      <RightMenu
        isOpen={isRightMenuOpen}
        onClose={() => setIsRightMenuOpen(false)}
      />
    </React.Fragment>
  );
}
