import React from "react";
import { Theme, LinearProgress } from "@material-ui/core";
import { makeStyles, createStyles, withStyles } from "@material-ui/styles";
import { Coins } from "../coins";

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: "#d9e0e2"
  },
  barColorPrimary: {
    backgroundColor: "#3b97d3"
  }
})(LinearProgress);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    amountSeparator: {
      margin: 5,
      fontSize: 15,
      color: "#8d8e90"
    },
    amountProgression: {
      textAlign: "right"
    }
  })
);

export function TransactionProgress(props: { taken: number; total: number }) {
  const classes = useStyles({});
  const { taken, total } = props;
  var percentage = total <= 0 ? 0 : (taken / total) * 100;
  return (
    <React.Fragment>
      <div className={classes.amountProgression}>
        <Coins amount={taken} />
        <span className={classes.amountSeparator}>/</span>
        <Coins color={"#b1b3b4"} amount={total} />
      </div>
      <ColorLinearProgress variant="determinate" value={percentage} />
    </React.Fragment>
  );
}
