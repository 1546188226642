import React, { useState } from "react";
import { Theme, Paper, Tabs, Tab } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { SubscriptionPicker } from "./components/subscriptionPicker";

const useStyles = makeStyles((theme: Theme) => createStyles({}));

export function SubscriptionsPage(props: {}) {
  const classes = useStyles({});
  const [tab, setTab] = useState<number>(0);
  const {} = props;
  return (
    <React.Fragment>
      <Paper>
        <Tabs
          value={tab}
          onChange={(e, t) => {
            setTab(t);
          }}
        >
          <Tab label={"Buy a Subscription"} />
          <Tab label={"History"} />
        </Tabs>
      </Paper>
      {tab === 0 && <SubscriptionPicker />}
      {tab === 1 && <span>History</span>}
    </React.Fragment>
  );
}
