import React, { useState, useEffect } from "react";
import { clubs } from "./clubs";
import { AssetPicker } from "./AssetPicker";

export function getClubImage(club: number): string {
  return `url(https://www.easports.com/fifa/ultimate-team/web-app/content/7D49A6B1-760B-4491-B10C-167FBC81D58A/2019/fut/items/images/mobile/clubs/normal/${club}.png)`;
}

export function getAnyClubImage(): string {
  return `url(https://www.easports.com/fifa/ultimate-team/web-app/content/7D49A6B1-760B-4491-B10C-167FBC81D58A/2019/fut/items/images/mobile/clubs/normal/default.png)`;
}

export function ClubPicker(props: {
  initialValue?: number;
  league?: number;
  onChange?: (value: number) => void;
  className?: string;
}) {
  const { league } = props;
  const [l, setL] = useState(league);

  useEffect(() => {
    setL(league);
  }, [props]);

  return (
    <AssetPicker
      className={props.className}
      initialValue={props.initialValue}
      onChange={props.onChange}
      anyLabel={"Any Club"}
      assetLabel={"Club"}
      getAnyImgLink={getAnyClubImage}
      getImgLink={getClubImage}
      source={clubs.filter(c => c.league && c.league == l)}
    />
  );
}
