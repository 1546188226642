export const rarityMap = {};
rarityMap[1] = "rare";
rarityMap[3] = "if";
rarityMap[4] = "purple";
rarityMap[5] = "toty";
rarityMap[6] = "bluered";
rarityMap[8] = "motm";
rarityMap[11] = "tots_moments";
rarityMap[12] = "icon";
rarityMap[21] = "otw";
rarityMap[22] = "halloween";
rarityMap[30] = "fut-bd";
rarityMap[32] = "futmas";
rarityMap[48] = "ucl_rare";
rarityMap[49] = "ucl_motm";
rarityMap[64] = "toty_nominee";
rarityMap[66] = "tots";
rarityMap[70] = "ucl_tott";
rarityMap[71] = "future_stars";
rarityMap[72] = "carniball";
rarityMap[84] = "icon_moment";
rarityMap[85] = "headliners";
