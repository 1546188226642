import React, { useEffect, useState } from "react";
import {
  Theme,
  FormControl,
  InputLabel,
  Input,
  InputAdornment
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import {
  ColumnDefinition,
  InteractiveTable
} from "../../../../../../components/interactiveTable";
import { Link } from "react-router-dom";
import { pagePath } from "../..";
import { AccountSettings } from "@futbot/login-server-api";
import { values } from "lodash";
import TimeAgo from "react-timeago";
import { Octofutiums } from "../../../../../../components/octofutiums";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: "#4894fe",
      fontWeight: 500,
      textDecoration: "none"
    }
  })
);

export function AccountsTable(props: {
  isLoading?: boolean;
  isReadOnly?: boolean;
  data: AccountSettings[];
  onRefresh?: () => void;
  additionalTools?: React.ReactNode;
  onSearchPatternChanged?: (pattern: string) => void;
}) {
  const classes = useStyles({});
  const { t, i18n } = useTranslation();
  const { isLoading, isReadOnly, data, onRefresh, additionalTools } = props;

  const columns: ColumnDefinition<AccountSettings>[] = [
    {
      id: "id",
      width: "40%",
      label: "Username",
      value: a => a.username,
      render: a => (
        <Link className={classes.link} to={`${pagePath}/${a.guid}`}>
          <div>
            <b>{a.username}</b>
          </div>
          <div>{a.email}</div>
        </Link>
      )
    },
    {
      id: "createdDate",
      width: "20%",
      label: "Creation Date",
      value: a => a.creationDate,
      render: a => <TimeAgo date={a.creationDate} />
    },
    {
      id: "lastLogOn",
      width: "20%",
      label: "Last Logon",
      value: a => a.lastLogOn,
      render: a => <TimeAgo date={a.lastLogOn} />
    },
    {
      id: "remainingOctofutiums",
      width: "20%",
      align: "right",
      numeric: true,
      label: "Remaining Octofutiums",
      value: a => a.boughtOctofutiums - (a.totalExecutionTime / 60000) * 1.343,
      render: a => (
        <Octofutiums
          amount={Math.floor(
            a.boughtOctofutiums - (a.totalExecutionTime / 60000) * 1.343
          )}
        />
      )
    }
  ];
  return (
    <InteractiveTable<AccountSettings>
      isLoading={isLoading}
      isReadOnly={isReadOnly}
      data={data}
      columns={columns}
      onRefresh={onRefresh}
      getKey={t => t.guid}
      additionalTools={additionalTools}
    />
  );
}
