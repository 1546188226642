import React from "react";
import { Theme } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { RankingCard } from "../rankingCard";
import { SaleViewModel, StatisticsApi } from "@futbot/stats-api";
import { EmptyRankingRow } from "../emptyRankingRow";
import { useStatApi } from "../../../../../../api";
import { ProfitableCardRankingRow } from "./profitableCardRankingRow";

const useStyles = makeStyles((theme: Theme) => createStyles({}));

export function ProfitableCardRanking(props: {
  botId?: string;
  count?: number;
}) {
  const classes = useStyles({});
  const { t, i18n } = useTranslation();
  const statisticsApi = useStatApi(StatisticsApi);
  const { botId, count } = props;
  return (
    <RankingCard<SaleViewModel>
      count={count}
      title={t("dashboard.labels.topXprofitablePlayers")}
      fetch={async (count: number) => {
        return await statisticsApi.profitsByPlayerRanking({
          count: count,
          botId: botId
        });
      }}
      renderElement={deal => <ProfitableCardRankingRow deal={deal} />}
      renderEmptyElement={() => <EmptyRankingRow />}
    />
  );
}
