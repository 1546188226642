import React from "react";
import { RouteInfo } from "../../utils/routeUtils";
import {
  shouldDisableExternalApi,
  shouldDisableSubscriptions,
  shouldDisableComfortTrade
} from "../../utils/featureUtils";
import { BotPage } from "./pages/bots";
import { ShopListsPage } from "./pages/shoplists";
import { AnalyticsPage } from "./pages/analytics";
import { DashboardPage } from "./pages/dashboard";
import { TransactionsPage } from "./pages/transactions";
import { TransactionCardsPage } from "./pages/transactionCards";
import { SubscriptionsPage } from "./pages/subscriptions";
import {
  DashboardIcon,
  BotsIcon,
  ShopListIcon,
  FuzzySearchIcon,
  TransactionIcon,
  CardsToUseIcon,
  AnalyticsIcon,
  SubscribeIcon,
  ExternalLinkIcon
} from "../../assets/icons";
import { ExternalApiPage } from "./pages/externalApis";
import { CardTester } from "./pages/cardTester";
import { ComingSoon } from "../../components/comingSoon";

const routes: RouteInfo[] = [
  {
    path: "/dashboard",
    name: "dashboard.title",
    icon: DashboardIcon,
    component: () => <DashboardPage />,
    endOfSection: true
  },
  {
    path: "/bots",
    name: "bots.title",
    icon: BotsIcon,
    component: () => <BotPage />
  },
  {
    path: "/shoplists",
    name: "shopLists.title",
    icon: ShopListIcon,
    component: () => <ShopListsPage />,
    endOfSection: true
  },
  {
    path: "/transactions",
    name: "transactions.title",
    icon: TransactionIcon,
    disabled: shouldDisableComfortTrade(),
    component: () => <TransactionsPage />
  },
  {
    path: "/txCards",
    name: "transactionCards.title",
    icon: CardsToUseIcon,
    disabled: shouldDisableComfortTrade(),
    component: () => <TransactionCardsPage />,
    endOfSection: true
  },
  {
    path: "/analytics",
    name: "analytics.title",
    icon: AnalyticsIcon,
    component: () => <AnalyticsPage />,
    endOfSection: true
  },
  {
    path: "/externalapis",
    name: "externalApis.title",
    icon: ExternalLinkIcon,
    disabled: shouldDisableExternalApi(),
    component: () => <ExternalApiPage />,
    endOfSection: true
  },
  {
    path: "/subscribtions",
    name: "subscriptions.title",
    icon: SubscribeIcon,
    disabled: shouldDisableSubscriptions(),
    component: () => <SubscriptionsPage />
  }
];

export default routes;
