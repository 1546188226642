import React, { useEffect, useState } from "react";
import { Theme } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  TooltipProps,
  Brush,
  CartesianGrid
} from "recharts";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader
} from "../../../../../../components/card";
import AccessTime from "@material-ui/icons/AccessTime";
import {
  grayColor,
  hexToRgb,
  whiteColor,
  successColor,
  secondaryColor
} from "../../../../../../assets/jss";
import { useComponentSize } from "../../../../../../hooks";
import TimeAgo from "react-timeago";
import { Coins } from "../../../../../../components/coins";
import { useTranslation } from "react-i18next";
import { CustomProgress } from "../../../../../../components/customProgress";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(6),
      width: "100%",
      backgroundSize: "cover"
    },
    stats: {
      color: grayColor[0],
      display: "inline-flex",
      fontSize: "12px",
      lineHeight: "22px",
      "& svg": {
        top: "4px",
        width: "16px",
        height: "16px",
        position: "relative",
        marginRight: "3px",
        marginLeft: "3px"
      },
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        top: "4px",
        fontSize: "16px",
        position: "relative",
        marginRight: "3px",
        marginLeft: "3px"
      }
    },
    cardCategory: {
      color: grayColor[0],
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      paddingTop: "10px",
      marginBottom: "0"
    },
    cardCategoryWhite: {
      color: "rgba(" + hexToRgb(whiteColor) + ",.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    cardTitle: {
      color: grayColor[2],
      fontSize: "1.3em",
      lineHeight: "1.5em",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: 300,
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: grayColor[1],
        fontWeight: 400,
        lineHeight: "1"
      }
    },
    successText: {
      color: successColor[0]
    },
    tooltipContainer: {
      background: "#fff9",
      color: "#000",
      padding: 4
    },
    updatedLabel: {
      marginRight: "4px"
    },
    unavailableData: {
      color: "#ddd",
      height: 200,
      textAlign: "center"
    }
  })
);

interface CustomTooltipProps extends TooltipProps {
  hideHoursInTooltip?: boolean;
  hideDateInTooltip?: boolean;
}

export function CustomTooltip(props: CustomTooltipProps) {
  const classes = useStyles({});
  const {
    active,
    label,
    payload,
    hideHoursInTooltip,
    hideDateInTooltip
  } = props;
  if (!active || !payload || !payload.length) {
    return null;
  }
  return (
    <div className={classes.tooltipContainer}>
      <div>
        <Coins amount={payload[0].value as number} />
      </div>
      {!hideDateInTooltip && (
        <div>{new Date(payload[0].payload.date).toLocaleDateString()}</div>
      )}
      {!hideHoursInTooltip && (
        <div>{new Date(payload[0].payload.date).toLocaleTimeString()}</div>
      )}
    </div>
  );
}

export interface ChartElement {
  date?: Date;
  price?: number;
}

export function CoinEvolutionChart(props: {
  prices: ChartElement[];
  lastUpdate?: number | Date;
  isLoading?: boolean;
  color?: string;
  chartStroke?: string;
  lineStroke?: string;
  title: string;
  description: string;
  unavailableDataMessage: string;
  hideHoursInTooltip?: boolean;
  hideDateInTooltip?: boolean;
}) {
  const {
    isLoading,
    prices,
    lastUpdate,
    title,
    description,
    unavailableDataMessage,
    hideHoursInTooltip,
    hideDateInTooltip,
    color,
    chartStroke,
    lineStroke
  } = props;
  const classes = useStyles({});
  const { t } = useTranslation();
  const { size, ref } = useComponentSize();
  const [data, setData] = useState<ChartElement[]>(prices);

  useEffect(() => {
    setData(prices);
  }, [props]);

  const selectedChartStroke = chartStroke || "#333";

  return (
    <div className={classes.root} ref={ref}>
      <Card chart>
        <CardHeader color={color || "fancy"}>
          {data && data.length ? (
            <LineChart width={size.width - 100} height={200} data={data}>
              <XAxis
                stroke={selectedChartStroke}
                dataKey={a => a.date.toLocaleDateString()}
              />
              <YAxis stroke={selectedChartStroke} />
              <CartesianGrid
                stroke={selectedChartStroke + "5"}
                strokeDasharray={"5, 5"}
              />
              <Line
                type="monotone"
                dataKey="price"
                stroke={lineStroke || secondaryColor}
                dot={false}
                strokeWidth={3}
              />
              <Tooltip
                content={
                  <CustomTooltip
                    hideDateInTooltip={hideDateInTooltip}
                    hideHoursInTooltip={hideHoursInTooltip}
                  />
                }
              />
              <Brush
                fill={selectedChartStroke + "1"}
                stroke={selectedChartStroke}
              />
            </LineChart>
          ) : (
            <div className={classes.unavailableData}>
              {isLoading ? <CustomProgress /> : unavailableDataMessage}
            </div>
          )}
        </CardHeader>
        <CardBody>
          <h4 className={classes.cardTitle}>{title}</h4>
          <p className={classes.cardCategory}>{description}</p>
        </CardBody>
        <CardFooter chart>
          <div className={classes.stats}>
            <AccessTime />
            {lastUpdate ? (
              <React.Fragment>
                <span className={classes.updatedLabel}>
                  {t("misc.updated")}
                </span>
                <TimeAgo date={lastUpdate} />
              </React.Fragment>
            ) : (
              <span>{t("misc.neverUpdated")}</span>
            )}
          </div>
        </CardFooter>
      </Card>
    </div>
  );
}
