import React from "react";
import { Theme, Typography } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    information: {
      display: "flex",
      padding: "5px 15px",
      overflow: "hidden"
    },
    informationLabel: (props: any) => ({
      flex: "0 0 auto",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: props.width,
      color: "#999"
    }),
    informationsSplitter: {
      flex: "0 0 15px",
      textAlign: "center",
      color: "#999",
      paddingRight: 20
    },
    informationValue: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      flex: "1 0 auto"
    }
  })
);

export function SimpleInformation(props: {
  label: string;
  children?: React.ReactNode;
  width?: number;
}) {
  const { label, children, width } = props;
  const classes = useStyles({ width: width ? width : 200 });
  return (
    <div className={classes.information}>
      <Typography className={classes.informationLabel}>{label}</Typography>
      <Typography className={classes.informationsSplitter}>:</Typography>
      <Typography className={classes.informationValue}>
        {children || <span className={classes.informationLabel}>-</span>}
      </Typography>
    </div>
  );
}
