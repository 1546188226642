export * from "./BotSettings";
export * from "./InputBottingMode";
export * from "./InputSchedule";

export const codeFetchingModes = [
  {
    value: 0,
    label: "IMAP",
  },
  {
    value: 1,
    label: "Backup Codes",
  },
  {
    value: 2,
    label: "Authenticator",
  },
];
