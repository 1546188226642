import React, { useState, useEffect } from "react";
import { Theme, TextField, MenuItem, Paper } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useStatApi } from "../../api";
import { CardsApi, PlayerCardViewModel } from "@futbot/stats-api";
import { useDebounce } from "../../hooks";
import { PlayerInfo } from "../playerInfo";
import Downshift from "downshift";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      zIndex: 20,
      marginTop: 8,
      left: 0,
      right: 0,
      maxHeight: 250,
      overflow: "auto"
    },
    suggestion: {
      width: "100%"
    },
    inputRoot: {
      flexWrap: "wrap"
    },
    inputInput: {
      width: "auto",
      flexGrow: 1
    },
    divider: {
      height: theme.spacing(2)
    }
  })
);

function renderInput(inputProps: any) {
  const { InputProps, classes, ref, ...other } = inputProps;

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput
        },
        ...InputProps
      }}
      {...other}
    />
  );
}

interface SuggestionRenderingProps {
  suggestion: PlayerCardViewModel;
  index: number;
  itemProps: any;
  highlightedIndex: number;
  classes: any;
}

function renderSuggestion(props: SuggestionRenderingProps) {
  const { suggestion, index, itemProps, highlightedIndex, classes } = props;
  const isHighlighted = highlightedIndex === index;

  return (
    <MenuItem {...itemProps} key={suggestion.id} selected={isHighlighted}>
      <div className={classes.suggestion}>
        <PlayerInfo {...suggestion} />
      </div>
    </MenuItem>
  );
}

export function PlayerCardAutoComplete(props: {
  onSelect?: (selectedCard: PlayerCardViewModel) => any;
  className?: string;
}) {
  const { onSelect, className } = props;
  const classes = useStyles({});
  const api = useStatApi(CardsApi);
  const [value, setValue] = useState<string>("");
  const [suggestions, setSuggestions] = useState<PlayerCardViewModel[]>([]);
  const searchText = useDebounce(value);

  useEffect(() => {
    if (searchText.length < 2) {
      return;
    }
    api
      .getCardsByTerm({ term: searchText })
      .then(players => {
        setSuggestions(players);
      })
      .catch(r => {
        console.log(r);
      });
  }, [searchText]);

  return (
    <Downshift
      id="downshift-simple"
      onInputValueChange={v => {
        setValue(v);
      }}
      onChange={(e, h) => {
        if (e) {
          onSelect(e);
          h.clearSelection();
        }
      }}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        highlightedIndex,
        isOpen
      }) => (
        <div className={className}>
          {renderInput({
            fullWidth: true,
            classes,
            InputProps: getInputProps({
              placeholder: "Search a player"
            })
          })}
          <div {...getMenuProps()}>
            {isOpen ? (
              <Paper className={classes.paper} square>
                {suggestions.map((suggestion, index) =>
                  renderSuggestion({
                    suggestion,
                    index,
                    itemProps: getItemProps({
                      item: suggestion,
                      label: suggestion.commonName
                    }),
                    highlightedIndex,
                    classes
                  })
                )}
              </Paper>
            ) : null}
          </div>
        </div>
      )}
    </Downshift>
  );
}
