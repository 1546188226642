import React, { useState, useEffect } from "react";
import {
  Paper,
  LinearProgress,
  makeStyles,
  Theme,
  createStyles,
  Grid
} from "@material-ui/core";
import { pagePath, useAccountId } from "../..";
import { useRuntimeApi } from "../../../../../../api";
import {
  AccountSettings,
  AdministrationApi,
  AccountViewModel
} from "@futbot/runtime-api";
import { DetailsHeader } from "../../../../../../components/detailsHeader";
import { Anonymizable } from "../../../../../../components/anonymizable";
import { PlateformIcon } from "../../../../../../components/plateformIcon";
const useStyles = makeStyles(() =>
  createStyles({
    header: {
      fontFamily: "Cairo",
      fontSize: 20
    },
    image: {
      width: 80
    }
  })
);
export function AccountDetails() {
  const classes = useStyles({});
  const { AccountId } = useAccountId();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [account, setAccount] = useState<AccountViewModel>(null);
  const api = useRuntimeApi(AdministrationApi);

  useEffect(() => {
    setIsLoading(true);
    api.getAccountById({ accountId: AccountId }).then(a => {
      setAccount(a);
      setIsLoading(false);
    });
  }, [AccountId]);

  if (isLoading || !account) {
    return <LinearProgress />;
  }

  return (
    <Paper>
      <DetailsHeader<AccountViewModel>
        data={account}
        title={a => (
          <Grid>
            <div className={classes.header}>
              <Anonymizable>{a.username}</Anonymizable>
            </div>
          </Grid>
        )}
        tableRoute={pagePath}
      />
      {account.username}
    </Paper>
  );
}
