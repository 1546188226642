import React from "react";
import {
  Theme,
  Card,
  CardContent,
  Typography,
  IconButton,
  CardMedia
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Package } from "@futbot/stats-api";
import { PaypalButton } from "../../../../../../components/paypalButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      marginTop: 10,
      width: 400,
      float: "left",
      marginRight: 10
    },
    details: {
      display: "flex",
      flexDirection: "column"
    },
    content: {
      flex: "1 0 auto",
      textAlign: "center"
    },
    cover: {
      width: 151
    },
    controls: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    tags: {
      paddingLeft: theme.spacing(1),
      position: "absolute"
    },
    octofutiums: {
      fontFamily: "Cairo",
      textShadow: "#555141 1px 0 6px"
    },
    amount: {
      fontFamily: "Cairo",
      fontWeight: 900,
      fontSize: 33,
      textShadow: "#555141 1px 0 6px"
    },
    paypal: {
      float: "left"
    },
    tag: {
      position: "absolute",
      left: 348,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 4,
      paddingBottom: 6,
      fontSize: 11,
      color: "#fff",
      background: "#7399e7",
      boxShadow: "#a39dce -2px 3px 13px",
      textAlign: "center",
      fontWeight: 100,
      marginLeft: 4,
      marginRight: 8
    },
    price: {
      float: "left",
      width: 120,
      fontWeight: 800,
      borderRadius: 6,
      marginLeft: 4,
      marginRight: 8,
      padding: 4,
      textAlign: "center",
      background: "#fc3677",
      color: "white",
      fontSize: 19
    }
  })
);

export function SubscriptionPackage(props: Package) {
  const classes = useStyles({});
  const { description, octofutiumsAmount, title, id, price } = props;
  return (
    <Card className={classes.card}>
      <div className={classes.details}>
        <div className={classes.tags}>
          <span className={classes.tag}>
            <div>10%</div>
            <div>Off</div>
          </span>
        </div>
        <CardContent className={classes.content}>
          <div>
            <span className={classes.amount}>
              {octofutiumsAmount.toLocaleString("en")}
            </span>
            <span className={classes.octofutiums}> octofutiums</span>
          </div>
        </CardContent>
        <div className={classes.controls}>
          <span className={classes.price}>{price} €</span>
          <span className={classes.paypal}>
            <PaypalButton itemId={id} />
          </span>
        </div>
      </div>
    </Card>
  );
}
