import React, { useEffect, useState } from "react";
import { Theme, LinearProgress, Paper } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useStatApi } from "../../../../../../api";
import { PaymentApi, Package } from "@futbot/stats-api";
import { SubscriptionPackage } from "./subscriptionPackage";
import { cpus } from "os";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "calc(100vh - 145px)",
      overflow: "auto"
    }
  })
);

export function SubscriptionPicker(props: {}) {
  const classes = useStyles({});
  const api = useStatApi(PaymentApi);
  const [packages, setPackages] = useState<Package[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {} = props;

  useEffect(() => {
    setIsLoading(true);
    api
      .getPackages()
      .then(pkgs => {
        setPackages(pkgs);
      })
      .catch(reason => {
        console.log(reason);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div className={classes.root}>
      {isLoading && <LinearProgress />}
      {packages.map(pkg => (
        <React.Fragment key={pkg.id}>
          <SubscriptionPackage {...pkg} />
        </React.Fragment>
      ))}
    </div>
  );
}
