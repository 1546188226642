import React, { useState, useEffect } from "react";
import {
  Theme,
  Button,
  Toolbar,
  Checkbox,
  FormControlLabel,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  LinearProgress,
  IconButton,
  Fab,
  Paper
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import {
  StartIcon,
  StopIcon,
  RefreshIcon,
  SaveIcon,
  EditIcon,
  AddIcon,
  DeleteIcon
} from "../../../../../../assets/icons";
import {
  SellingApiConfigurationViewModel,
  SellingApiApi
} from "@futbot/runtime-api";
import { useRuntimeApi } from "../../../../../../api";
import { PlateformIcon } from "../../../../../../components/plateformIcon";
import { EditionRow } from "./EditionRow";
import { useSnackbar } from "@creatdevsolutions/notistack";
import { useTableHeaderStyles } from "../../../../../../components/interactiveTable";
import classNames from "classnames";
import { fancyHeader } from "../../../../../../assets/jss";
import { sellingApisTypes } from "../sellingApiPicker";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      ...fancyHeader
    },
    button: {
      width: 120,
      marginRight: 8
    },
    spacer: {
      flexGrow: 1
    },
    table: {
      margin: theme.spacing(3)
    }
  })
);

export function HeaderTableCell(props: {
  children?: React.ReactNode;
  background?: string;
}) {
  const { children, background } = props;
  const classes = useTableHeaderStyles({});
  return (
    <TableCell className={classNames(classes.header, classes.styledHeader)}>
      {children}
    </TableCell>
  );
}

export function ExternalApisOverview() {
  const api = useRuntimeApi(SellingApiApi);
  const classes = useStyles({});
  const headerClasses = useTableHeaderStyles({ background: "#333" });
  const { enqueueSnackbar } = useSnackbar();
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [editedRowIndex, setEditedRowIndex] = useState<number>(-1);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [reloadCount, setReloadCount] = useState<number>(0);
  const [configuration, setConfiguration] = useState<
    SellingApiConfigurationViewModel
  >(null);
  const [initialConfiguration, setInitialConfiguration] = useState<string>(
    null
  );
  useEffect(() => {
    setIsLoading(true);
    api
      .getConfiguration()
      .then(c => {
        setConfiguration(c);
        setInitialConfiguration(JSON.stringify(c));
      })
      .catch(r => {
        console.log(r);
      })
      .then(() => {
        setIsLoading(false);
      });
  }, [reloadCount]);

  useEffect(() => {
    api
      .getState()
      .then(c => {
        setIsRunning(c.boolValue);
      })
      .catch(r => {
        console.log(r);
      });
  }, [reloadCount]);

  if (isLoading) {
    return <LinearProgress />;
  }

  var settings = configuration.apiSettings;
  return (
    <React.Fragment>
      <Toolbar className={classes.toolbar}>
        <Button
          color="inherit"
          variant="outlined"
          size="small"
          disabled={isRunning}
          className={classes.button}
          onClick={() => {
            api.start().then(() => {
              enqueueSnackbar("External API connection started", {
                variant: "success"
              });
              setReloadCount(reloadCount + 1);
            });
          }}
        >
          <StartIcon />
          Start
        </Button>
        <Button
          color="inherit"
          variant="outlined"
          size="small"
          disabled={!isRunning}
          className={classes.button}
          onClick={() => {
            api.stop().then(() => {
              enqueueSnackbar("External API connection stopped", {
                variant: "error"
              });
              setReloadCount(reloadCount + 1);
            });
          }}
        >
          <StopIcon />
          Stop
        </Button>
        <Button
          color="inherit"
          variant="outlined"
          size="small"
          disabled={
            initialConfiguration === JSON.stringify(configuration) || isRunning
          }
          className={classes.button}
          onClick={() => {
            api.configure({ settings: configuration }).then(() => {
              enqueueSnackbar("Successfully saved settings", {
                variant: "success"
              });
              setReloadCount(reloadCount + 1);
            });
          }}
        >
          <SaveIcon />
          Save
        </Button>
        <FormControlLabel
          color={"inherited"}
          control={
            <Checkbox
              checked={configuration.runSellingApiOnStatup}
              name="fetchIsSSL"
              color="secondary"
              onChange={e => {
                setConfiguration({
                  ...configuration,
                  runSellingApiOnStatup: e.target.checked
                });
              }}
            />
          }
          label="Run on startup"
        />
        <div className={classes.spacer} />

        <span>{isRunning ? "RUNNING" : "STOPPED"}</span>
        <IconButton color={"inherit"}>
          <RefreshIcon />
        </IconButton>
      </Toolbar>
      <Paper className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderTableCell>Type</HeaderTableCell>
              <HeaderTableCell>Username</HeaderTableCell>
              <HeaderTableCell>Password</HeaderTableCell>
              <HeaderTableCell>API Key</HeaderTableCell>
              <HeaderTableCell>Platform</HeaderTableCell>
              <HeaderTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {settings.map((s, i) => {
              if (i === editedRowIndex) {
                return (
                  <EditionRow
                    key={i}
                    setting={s}
                    onChanged={ns => {
                      var newSettings = settings;
                      newSettings.splice(i, 1, ns);
                      setConfiguration({
                        ...configuration,
                        apiSettings: newSettings
                      });
                      setEditedRowIndex(-1);
                    }}
                  />
                );
              }
              var sellingApiType = sellingApisTypes.find(p => {
                return p.value == s.apiType;
              });
              return (
                <TableRow key={i}>
                  <TableCell>
                    {sellingApiType ? sellingApiType.label : null}
                  </TableCell>
                  <TableCell>{s.username}</TableCell>
                  <TableCell>{s.password}</TableCell>
                  <TableCell>{s.apiKey}</TableCell>
                  <TableCell>
                    <PlateformIcon plateform={s.platform} />
                  </TableCell>
                  <TableCell align={"right"}>
                    <IconButton
                      onClick={() => {
                        setEditedRowIndex(i);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        var newSettings = settings;
                        newSettings.splice(i, 1);
                        setConfiguration({
                          ...configuration,
                          apiSettings: newSettings
                        });
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell colSpan={5} align="center" />
              <TableCell align={"right"}>
                <IconButton
                  onClick={() => {
                    var newSettings = settings;
                    newSettings.push({});
                    setConfiguration({
                      ...configuration,
                      apiSettings: newSettings
                    });
                    setEditedRowIndex(newSettings.length - 1);
                  }}
                >
                  <AddIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </React.Fragment>
  );
}
