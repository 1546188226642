import React, { useState, useEffect } from "react";
import {
  Tabs,
  Tab,
  Toolbar,
  AppBar,
  makeStyles,
  Theme,
  createStyles,
  TextField,
  Button
} from "@material-ui/core";
import { useTransactionGuid } from ".";
import { useRuntimeApi } from "../../../../api";
import {
  CustomerTransactionApi,
  ConfiguredTransactionViewModel,
  BackupCode,
  BotSettingsViewModel
} from "@futbot/runtime-api";
import { TransactionProgress } from "../../../../components/transactionProgress";
import { OrdersHistory } from "../../../../components/ordersHistory";
import { PlateformIcon } from "../../../../components/plateformIcon";
import { TransactionHistory } from "./components/transactionHistory";
import { TransactionLogs } from "./components/transactionLogs";
import { useLoading } from "../../../../hooks/useLoading";
import { useTranslation } from "react-i18next";
import ChipInput from "material-ui-chip-input";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText
    },
    spacer: {
      flexGrow: 1
    },
    header: {
      background: "#0a1b30"
    },
    textField: {
      margin: 8,
      minWidth: theme.spacing(40)
    },
    button: {
      marginRight: 8,
      minWidth: theme.spacing(20)
    }
  })
);

export function TransactionsPage(props: {}) {
  const classes = useStyles({});
  const { t } = useTranslation();
  const [transaction, setTransaction] = useState<
    ConfiguredTransactionViewModel
  >();
  const [settings, setSettings] = useState<BotSettingsViewModel>({
    backupCodes: []
  });
  const [canConfigure, setCanConfigure] = useState<boolean>(true);
  const { isLoading, setIsLoading } = useLoading();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [tab, setTab] = useState<number>(0);
  const [reloadCount] = useState<number>(0);
  const { transactionGuid } = useTransactionGuid();
  const api = useRuntimeApi(CustomerTransactionApi);
  const {} = props;

  useEffect(() => {
    if (!transactionGuid) {
      return;
    }
    setIsLoading(true);
    api
      .getAutoTransactionAsCustomer({ transactionId: transactionGuid })
      .then(t => {
        setIsValid(true);
        setTransaction(t);
        if (t.settings) {
          setSettings(t.settings);
          setCanConfigure(false);
        }
      })
      .catch(r => {
        setIsValid(false);
        console.log(r);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [reloadCount]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    const newSettings = { ...settings, [name]: value };
    setSettings(newSettings);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isValid) {
    return <div>Invalid Transaction</div>;
  }

  return (
    <div>
      <AppBar position="static" color="inherit" className={classes.header}>
        <Toolbar>
          <Button
            className={classes.textField}
            color="secondary"
            variant="contained"
          >
            Start
          </Button>
          <Button
            className={classes.textField}
            color="secondary"
            variant="contained"
          >
            Stop
          </Button>
          <div className={classes.spacer} />
          <PlateformIcon plateform={transaction.transaction.platform} />
        </Toolbar>
        <TransactionProgress
          taken={transaction.transaction.takenAmount}
          total={transaction.transaction.totalAmount}
        />
      </AppBar>
      <React.Fragment>
        <AppBar position="static">
          <Tabs
            value={tab}
            onChange={(e, t) => {
              setTab(t);
            }}
          >
            <Tab label="Configure" />
            <Tab label="History" />
            <Tab label="Logs" />
            <Tab label="Help" />
          </Tabs>
        </AppBar>
        {tab == 0 && canConfigure && (
          <React.Fragment>
            <TextField
              label={t("misc.emailAddress")}
              name="username"
              className={classes.textField}
              value={settings.username}
              onChange={handleInputChange}
            />
            <TextField
              label={t("misc.password")}
              name="password"
              className={classes.textField}
              value={settings.password}
              onChange={handleInputChange}
            />
            <ChipInput
              label="Backup codes"
              helperText={
                "To get backup codes, go to : https://myaccount.ea.com/cp-ui/security"
              }
              classes={null}
              className={classes.textField}
              newChipKeyCodes={[13, 32]}
              defaultValue={settings.backupCodes
                .filter(bc => !bc.isUsed)
                .map(bc => {
                  return bc.value;
                })}
              onChange={chips => {
                var backupCodes = chips.map<BackupCode>(c => ({
                  value: c,
                  isUsed: false
                }));
                const newSettings = {
                  ...settings,
                  ["backupCodes"]: backupCodes
                };
                setSettings(newSettings);
              }}
            />
          </React.Fragment>
        )}

        {tab == 0 && !canConfigure && <div>Already configured</div>}
        {tab == 1 && (
          <TransactionHistory transaction={transaction.transaction} />
        )}
        {tab == 2 && (
          <TransactionLogs transactionId={transaction.transaction.guid} />
        )}
      </React.Fragment>
    </div>
  );
}
