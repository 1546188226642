export function shouldDisableLanguagePicker(): boolean {
  return false;
  // return process.env.REACT_APP_SIMPLE != null;
}
export function shouldDisableExternalApi(): boolean {
  return true;
  // return process.env.REACT_APP_SIMPLE != null;
}
export function shouldDisableReferalSystem(): boolean {
  return false;
  // return process.env.REACT_APP_SIMPLE != null;
}
export function shouldDisableComfortTrade(): boolean {
  return true;
  // return process.env.REACT_APP_SIMPLE != null;
}
export function shouldDisableSubscriptions(): boolean {
  return false;
  // return process.env.REACT_APP_SIMPLE != null;
}
export function shouldDisableRegistration(): boolean {
  return false;
  // return process.env.REACT_APP_SIMPLE != null;
}
