import React from "react";
import { RouteInfo } from "./utils/routeUtils";
import { ManagerModule } from "./modules/manager/ManagerModule";
import { CustomerModule } from "./modules/customer/CustomerModuler";
import { AdministratorModule } from "./modules/administrator";
import { RegisterModule, LoginModule } from "./modules/login";
import { shouldDisableRegistration } from "./utils/featureUtils";

const routes: RouteInfo[] = [
  {
    path: "/manager",
    component: () => <ManagerModule />
  },
  {
    path: "/customer",
    component: () => <CustomerModule />
  },
  {
    path: "/administrator",
    component: () => <AdministratorModule />
  },
  {
    path: "/register",
    component: () => <RegisterModule />,
    disabled: shouldDisableRegistration()
  },
  {
    path: "/",
    component: () => <LoginModule />
  }
];

export default routes;
