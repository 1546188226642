import React, { useEffect, useState } from "react";
import { Theme, Paper } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { UncheckedHelpIcon } from "../../assets/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1),
      padding: theme.spacing(1),
      fontSize: 11,
      background: "#f1f1f1"
    },
    icon: {
      color: "gray",
      margin: "0px 8px",
      verticalAlign: "bottom",
      fontSize: 20
    }
  })
);

export function HelpNote(props: { text: string; className?: string }) {
  const classes = useStyles({});
  const { t, i18n } = useTranslation();
  const { text, className } = props;
  return (
    <Paper className={classNames(classes.root, className)}>
      <UncheckedHelpIcon className={classes.icon} />
      {text}
    </Paper>
  );
}
