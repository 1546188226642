import React, { useState, useEffect, useCallback } from "react";
import ReactDOM from "react-dom";
import { Theme } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useScript } from "../../hooks/useScript";
import { usePaypal } from "../../hooks/usePaypal";
import { State } from "../../store/config/configureStore";
import { useMappedState } from "redux-react-hook";
import { CustomProgress } from "../customProgress";
import { PAYMENT_ENV } from "../../api/apiConfig";

const useStyles = makeStyles((theme: Theme) => createStyles({}));

const buttonFormat = {
  label: "generic",
  size: "medium", // small | medium | large | responsive
  shape: "rect", // pill | rect
  color: "black", // gold | blue | silver | black
  tagline: false
};

export function PaypalButton(props: {
  itemId: number;
  onError?: () => void;
  onSuccess?: (payment: any) => void;
  onCancel?: (data: any) => void;
}) {
  const classes = useStyles({});
  const paypal = usePaypal();
  const { itemId, onSuccess, onError, onCancel } = props;

  const mapStateToProps = useCallback(
    (state: State) => ({
      url: state.account.loginResponse.serverInfo.statsUrl,
      accountId: state.account.loginResponse.settings.guid
    }),
    []
  );
  const { url, accountId } = useMappedState(mapStateToProps);

  if (paypal === undefined) {
    return <CustomProgress />;
  }

  const Button = paypal.Button.driver("react", {
    React,
    ReactDOM
  });

  function onPaymentCreate(data, actions) {
    return actions.request
      .post(`${url}/api/Payment/create`, {
        itemId: itemId,
        accountId: accountId
      })
      .then(function(res) {
        console.log(res);
        return res.id;
      });
  }

  function onPaymentExecute(data, actions) {
    return actions.request
      .post(`${url}/api/Payment/execute`, {
        paymentID: data.paymentID,
        payerID: data.payerID
      })
      .then(res => {
        if (res.state === "approved") {
          if (onSuccess) {
            onSuccess(res);
          }
        }
      });
  }

  return (
    <div>
      <Button
        style={buttonFormat}
        payment={onPaymentCreate}
        env={PAYMENT_ENV}
        commit={true}
        onAuthorize={onPaymentExecute}
      />
    </div>
  );
}
