import React, { useEffect, useState } from "react";
import {
  Theme,
  Table,
  TableCell,
  TableBody,
  TableRow,
  Checkbox
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import {
  InteractiveTableHead,
  useSelectionHandler,
  useOrderHandler
} from "./interactiveTableHeader";
import { stableSort, getSorting, ColumnDefinition } from "./columnDefinition";
import { usePerfectScroll } from "../../hooks";
import {
  InteractiveTableToolbar,
  ToolbarAction,
  ToolbarActionOnSelected
} from "./interactiveTableToolbar";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      overflow: "auto"
    },
    link: {
      color: "#4894fe",
      fontWeight: 500,
      textDecoration: "none"
    },
    tableWrapper: (props: any) => ({
      overflow: "auto",
      height: `calc(100vh - ${props.wrapHeigth}px)`
    }),
    table: {
      tableLayout: "auto",
      width: "auto"
    },
    lastActivity: {
      fontWeight: 200
    }
  })
);

export function InteractiveTable<T>(props: {
  data: T[];
  wrapHeigth?: number;
  toolbarColor?: "primary" | "secondary";
  isReadOnly?: boolean;
  isLoading?: boolean;
  getKey: (value: T) => string;
  onRefresh?: () => void;
  columns: ColumnDefinition<T>[];
  actions?: ToolbarAction[];
  actionsOnSelected?: ToolbarActionOnSelected[];
  additionalTools?: React.ReactNode;
}) {
  const {
    data,
    getKey,
    columns,
    actions,
    actionsOnSelected,
    isReadOnly,
    isLoading,
    toolbarColor,
    wrapHeigth,
    onRefresh,
    additionalTools
  } = props;
  const classes = useStyles({ wrapHeigth: wrapHeigth ? wrapHeigth : 150 });
  const wrapperRef = usePerfectScroll();
  const { selected, selectAll, isSelected, setSelected } = useSelectionHandler(
    data,
    getKey
  );
  const [loading, setLoading] = useState(isLoading);
  const { order, orderBy, onRequestSort } = useOrderHandler();
  useEffect(() => {
    setSelected([]);
  }, [data]);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  function handleClick(id: string) {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  }

  return (
    <div className={classes.root}>
      <InteractiveTableToolbar
        color={toolbarColor}
        selected={selected}
        onRefresh={onRefresh}
        actions={actions}
        actionsOnSelected={actionsOnSelected}
        additionalTools={additionalTools}
      />
      <div
        className={classes.tableWrapper}
        style={{ position: "relative" }}
        ref={wrapperRef}
      >
        <Table className={classes.table}>
          <InteractiveTableHead<T>
            numSelected={selected.length}
            rowCount={data.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={onRequestSort}
            onSelectAllClick={selectAll}
            columns={columns}
          />
          <TableBody>
            {(!data || !data.length) && loading
              ? Array(15)
                  .fill(undefined)
                  .map((_, i) => (
                    <TableRow>
                      <TableCell />
                      {columns.map(column => (
                        <TableCell key={column.id} align={column.align}>
                          {column.renderLoading ? (
                            column.renderLoading()
                          ) : (
                            <Skeleton height={10} width={"100%"} />
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
              : stableSort(data, getSorting(order, orderBy, columns)).map(
                  (element, key) => {
                    const isItemSelected = isSelected(element);
                    return (
                      <TableRow
                        key={key}
                        hover
                        aria-checked={isItemSelected}
                        role="checkbox"
                        selected={isItemSelected}
                      >
                        <TableCell
                          padding="checkbox"
                          style={{ width: "100px" }}
                        >
                          <Checkbox
                            disabled={isReadOnly}
                            checked={isItemSelected}
                            onClick={event => handleClick(getKey(element))}
                          />
                        </TableCell>
                        {columns.map(column => (
                          <TableCell key={column.id} align={column.align}>
                            {column.render(element)}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  }
                )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
