import { useCallback } from "react";
import { State } from "../../../../store/config/configureStore";
import { useMappedState } from "redux-react-hook";

export * from "./ShopListsPage";

export const relativePagePath = "/shoplists";
export const pagePath = "/manager/shoplists";
export const smallBotListWidth = 260;
export const botDetailsMenuWidth = 260;
export const botDetailsContentWidth = botDetailsMenuWidth + smallBotListWidth;

export function extractGuid(pathname: string): number {
  var regex = /\/manager\/shoplists\/([0-9]*).*/g;
  var match = regex.exec(pathname);
  if (!match || match.length < 1) {
    return null;
  }
  return parseInt(match[1], 10);
}

export function extractBasePath(pathname: string): string {
  var regex = /(\/manager\/shoplists\/[0-9]*).*/g;
  var match = regex.exec(pathname);
  if (!match || match.length < 1) {
    return null;
  }
  return match[1];
}

export const useGuid = () => {
  const mapStateToProps = useCallback(
    (state: State) => ({
      guid: extractGuid(state.router.location.pathname)
    }),
    []
  );
  const { guid } = useMappedState(mapStateToProps);
  return { guid };
};

export const useBasePath = () => {
  const mapStateToProps = useCallback(
    (state: State) => ({
      basePath: extractBasePath(state.router.location.pathname)
    }),
    []
  );
  const { basePath } = useMappedState(mapStateToProps);
  return { basePath };
};
