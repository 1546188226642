import React, { useState, useEffect } from "react";
import {
  TableCell,
  TableRow,
  TextField,
  IconButton,
  CircularProgress
} from "@material-ui/core";
import { AddIcon, SaveIcon } from "../../../../../../assets/icons";
import { SellingApiSettingsViewModel } from "@futbot/runtime-api";
import { PlateformPicker } from "../../../../../../components/plateformPicker";
import { SellingApiPicker } from "../sellingApiPicker";
import { useTranslation } from "react-i18next";

export function EditionRow(props: {
  setting: SellingApiSettingsViewModel;
  onChanged?: (newValue: SellingApiSettingsViewModel) => void;
}) {
  const { setting, onChanged } = props;
  const { t } = useTranslation();
  const [value, setValue] = useState<SellingApiSettingsViewModel>(setting);
  useEffect(() => {
    setValue(setting);
  }, [props]);
  return (
    <TableRow>
      <TableCell>
        <SellingApiPicker
          initialValue={value.apiType}
          onChange={p => {
            setValue({ ...value, apiType: p });
          }}
        />
      </TableCell>
      <TableCell>
        <TextField
          placeholder={t("externalApis.labels.username")}
          value={value.username}
          onChange={e => {
            setValue({ ...value, username: e.target.value });
          }}
        />
      </TableCell>
      <TableCell>
        <TextField
          placeholder={t("externalApis.labels.password")}
          defaultValue={value.password}
          onChange={e => {
            setValue({ ...value, password: e.target.value });
          }}
        />
      </TableCell>
      <TableCell>
        <TextField
          placeholder={t("externalApis.labels.apiKey")}
          value={value.apiKey}
          onChange={e => {
            setValue({ ...value, apiKey: e.target.value });
          }}
        />
      </TableCell>
      <TableCell>
        <PlateformPicker
          initialValue={value.platform}
          onChange={p => {
            setValue({ ...value, platform: parseInt(p) });
          }}
        />
      </TableCell>
      <TableCell align={"right"}>
        <IconButton
          onClick={() => {
            onChanged(value);
          }}
        >
          <SaveIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
