import { useDispatch, useMappedState } from "redux-react-hook";
import { setLoading as dispatchIsLoading } from "../store/actions/app";
import { useCallback } from "react";
import { State } from "../store/config/configureStore";

export function useLoading() {
  const dispatch = useDispatch();
  const mapStateToProps = useCallback(
    (state: State) => ({
      isLoading: state.app.isLoading
    }),
    []
  );
  const { isLoading } = useMappedState(mapStateToProps);
  function setIsLoading(value: boolean) {
    dispatch(dispatchIsLoading(value));
  }
  return { setIsLoading, isLoading };
}
