import React, { useCallback } from "react";
import {
  Theme,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  List,
  Divider
} from "@material-ui/core";
import classNames from "classnames";
import { makeStyles, createStyles } from "@material-ui/styles";
import { LeftIcon } from "../../assets/icons";
import { NavLink } from "react-router-dom";
import { useStyles } from "./useStyles";
import { useTranslation } from "react-i18next";
import { RouteInfo } from "../../utils/routeUtils";
import { State } from "../../store/config/configureStore";
import { useMappedState } from "redux-react-hook";
import { usePerfectScroll } from "../../hooks";

export function LeftMenu(props: {
  routes: RouteInfo[];
  modulePath: string | string[];
  isOpen: boolean;
  onToggle: () => void;
}) {
  const classes = useStyles({});
  const { t } = useTranslation();
  const mapStateToProps = useCallback(
    (state: State) => ({
      isLogged: state.account.loginResponse,
      pathname: state.router.location.pathname
    }),
    []
  );
  const { pathname } = useMappedState(mapStateToProps);
  const wrapperRef = usePerfectScroll();
  const { isOpen, onToggle, routes, modulePath } = props;

  function activeRoute(routeName: string) {
    return pathname.indexOf(routeName) > -1 ? true : false;
  }

  const links = (
    <List
      className={classNames(classes.list, {
        [classes.listOpen]: isOpen
      })}
    >
      {routes
        .filter(r => !r.disabled)
        .map((route: RouteInfo, index: number) => (
          <React.Fragment key={index}>
            <NavLink
              className={classes.item}
              to={modulePath + route.path}
              key={index}
            >
              <ListItem
                className={classNames(classes.itemLink, {
                  [classes.inlineItemLink]: !isOpen,
                  [classes.selectedItem]: activeRoute(route.path)
                })}
                button
              >
                <ListItemIcon
                  className={classNames(classes.itemIcon, {
                    [classes.selectedItemText]: activeRoute(route.path)
                  })}
                >
                  {<route.icon />}
                </ListItemIcon>
                <ListItemText
                  className={classNames(classes.itemText, {
                    [classes.selectedItemText]: activeRoute(route.path)
                  })}
                  primary={isOpen ? t(route.name) : null}
                  disableTypography={true}
                />
              </ListItem>
            </NavLink>
            {route.endOfSection && (
              <Divider className={classes.divider} light />
            )}
          </React.Fragment>
        ))}
    </List>
  );

  return (
    <Drawer
      variant="permanent"
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isOpen,
        [classes.drawerClose]: !isOpen
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isOpen,
          [classes.drawerClose]: !isOpen
        })
      }}
      open={isOpen}
    >
      <div className={classes.toolbar}>
        <div className={classes.brand} />
        <IconButton
          className={classes.itemIcon}
          onClick={() => {
            onToggle();
          }}
        >
          <LeftIcon />
        </IconButton>
      </div>
      <div ref={wrapperRef} className={classes.foreground}>
        {links}
      </div>
      <div className={classes.background} />
    </Drawer>
  );
}
