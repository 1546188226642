import React from "react";
import {
  Theme,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => createStyles({}));

export const sellingApisTypes = [
  {
    value: 0,
    label: "Game Trade Easy"
  },
  {
    value: 1,
    label: "Sugar"
  }
];

export function SellingApiPicker(props: {
  initialValue?: number;
  onChange: (newValue: number) => any;
  className?: string;
  label?: string;
}) {
  const classes = useStyles({});
  const { t } = useTranslation();
  const { initialValue, onChange, className, label } = props;
  return (
    <FormControl>
      <Select
        value={initialValue}
        className={className}
        onChange={e => {
          onChange(parseInt(e.target.value as string));
        }}
      >
        {sellingApisTypes.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
