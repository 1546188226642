import React, { useState } from "react";
import {
  Theme,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import { BotSettings } from "../botSettings";
import { InputBotSettings } from "../botSettings/InputBotSettings";
import { BotViewModel, BotSettingsViewModel } from "@futbot/runtime-api";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative"
    },
    flex: {
      flex: 1
    }
  })
);

export function ConfirmationModal(props: {
  open: boolean;
  explanation?: string;
  title?: string;
  onDismiss: () => void;
  onConfirm: () => void;
}) {
  const classes = useStyles({});
  const { open, onDismiss, onConfirm, title, explanation } = props;

  return (
    <Dialog open={open} onClose={onDismiss}>
      <DialogTitle id="alert-dialog-title">
        {title ? title : "Are you sure?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {explanation ? explanation : ""}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onDismiss}>
          No
        </Button>
        <Button color="primary" onClick={onConfirm}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
