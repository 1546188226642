import React, { useEffect, useState } from "react";
import {
  Theme,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useStatApi } from "../../../../../../api";
import { ShopListsApi, ShopListViewModel } from "@futbot/stats-api";
import { useLoading } from "../../../../../../hooks/useLoading";

const useStyles = makeStyles((theme: Theme) => createStyles({}));

export function InputShopList(props: {
  initialValue?: number;
  onChange: (newValue: number) => any;
  className?: string;
}) {
  const classes = useStyles({});
  const { t, i18n } = useTranslation();
  const api = useStatApi(ShopListsApi);
  const [shopLists, setShopLists] = useState<ShopListViewModel[]>([]);
  const { initialValue, className, onChange } = props;
  useEffect(() => {
    api
      .getShopLists()
      .then(sl => {
        setShopLists(sl);
      })
      .catch(r => {
        console.log(r);
      });
  }, []);

  return (
    <FormControl className={className}>
      <InputLabel htmlFor="selectShoplist">
        {t("bots.labels.selectShoplist")}
      </InputLabel>
      <Select
        native
        value={initialValue}
        onChange={(e: any) => {
          onChange(parseInt(e.target.value));
        }}
        inputProps={{
          name: "shoplist",
          id: "selectShoplist"
        }}
      >
        <option value="" />
        {shopLists.map(option => {
          return <option value={option.id}>{option.name}</option>;
        })}
      </Select>
    </FormControl>
    // <TextField
    //   label={t("bots.labels.selectShoplist")}
    //   value={initialValue}
    //   className={className}
    //   select
    //   onChange={e => {
    //     onChange(parseInt(e.target.value));
    //   }}
    // >
    //   {shopLists.map(option => (
    //     <MenuItem key={option.id} value={option.id}>
    //       {option.name}
    //     </MenuItem>
    //   ))}
    // </TextField>
  );
}
