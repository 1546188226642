import React from "react";
import { RouteInfo } from "../../utils/routeUtils";
import { TransactionIcon } from "../../assets/icons";
import { TransactionsPage } from "./pages/transactions";

const routes: RouteInfo[] = [
  {
    path: "/transaction",
    name: "Transaction",
    icon: TransactionIcon,
    component: () => <TransactionsPage />,
    endOfSection: true
  }
];

export default routes;
