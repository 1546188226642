import React, { useEffect, useState } from "react";
import {
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { ShopListViewModel } from "@futbot/stats-api";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    dialogContent: {
      minHeight: 200,
      padding: "0 24px"
    }
  })
);

export function ShopListModal(props: {
  open: boolean;
  action: "add" | "update";
  shoplist: ShopListViewModel;
  onClose: () => void;
  onSubmit: (shoplist: ShopListViewModel) => void;
}) {
  const classes = useStyles({});
  const { t, i18n } = useTranslation();
  const { open, onClose, onSubmit, shoplist, action } = props;
  const [editedShopList, setEditedShopList] = useState(shoplist);
  const isAdd = action === "add";
  const operation = isAdd ? "Add" : "Update";
  const title = operation + " a shop list";
  useEffect(() => {
    setEditedShopList(shoplist);
  }, [props]);
  return (
    <Dialog
      fullWidth
      maxWidth={"md"}
      className={classes.root}
      open={open}
      onClose={onClose}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
    >
      <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <TextField
          label="Shop List Name"
          placeholder="Shop list name"
          fullWidth
          value={editedShopList.name}
          onChange={e => {
            const newSL = { ...editedShopList, name: e.target.value };
            setEditedShopList(newSL);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            onSubmit(editedShopList);
          }}
          color="primary"
        >
          {operation}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
