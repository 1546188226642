import React, { useEffect, useState, useCallback } from "react";
import {
  Theme,
  Paper,
  Avatar,
  Typography,
  FormControl,
  InputLabel,
  Input,
  Button,
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useDispatch, useMappedState } from "redux-react-hook";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { State } from "../../store/config/configureStore";
import { AuthenticationApi } from "@futbot/login-server-api";
import { login } from "../../store/actions/account";
import { push } from "connected-react-router";
import { shouldDisableRegistration } from "../../utils/featureUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
        3
      )}px`,
    },
    avatar: {
      margin: 8,
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: 8,
    },
    submit: {
      marginTop: theme.spacing(3),
    },
  })
);

export function LoginForm(props: { api: AuthenticationApi }) {
  const classes = useStyles({});
  const { t, i18n } = useTranslation();
  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const mapStateToProps = useCallback(
    (state: State) => ({
      loggedIn: state.account.loginResponse,
    }),
    []
  );
  const { loggedIn } = useMappedState(mapStateToProps);
  const { api } = props;

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    setLoading(true);
    setError(undefined);
    api
      .token({ request: { username, password } })
      .then((response) => {
        dispatch(login(response));
        if (response.success) {
          window.location.reload();
        } else {
          setError(response.errorMessage);
        }
      })
      .catch((reason) => {
        setError(reason);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Paper className={classes.paper}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      {loading && <div>LOADING...</div>}
      {error && <div style={{ color: "red" }}>{error}</div>}
      <form className={classes.form} onSubmit={handleSubmit}>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="username">Username</InputLabel>
          <Input
            value={username}
            name="password"
            autoFocus
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="password">Password</InputLabel>
          <Input
            type="password"
            name="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </FormControl>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Sign in
        </Button>
        {!shouldDisableRegistration() && (
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
            onClick={(e) => {
              e.preventDefault();
              dispatch(push("/register"));
            }}
          >
            Register
          </Button>
        )}
      </form>
    </Paper>
  );
}
