var storedResponse = localStorage.getItem("misc");

class Misc {
  showHelp: boolean;
  anonymizeData: boolean;
  shrinkedSubMenu: boolean;
}

const existingConfig = JSON.parse(storedResponse) as Misc;
export const initialState: Misc = existingConfig
  ? existingConfig
  : { showHelp: true, anonymizeData: false, shrinkedSubMenu: false };

export default (
  state = initialState,
  action: { type: string; payload: any }
): typeof initialState => {
  let newState = state;
  switch (action.type) {
    case "MISC_TOGGLE_HELP":
      newState = { ...state, showHelp: !state.showHelp };
      break;
    case "MISC_TOGGLE_ANONYMIZE":
      newState = { ...state, anonymizeData: !state.anonymizeData };
      break;
    case "MISC_TOGGLE_SUBMENU":
      newState = { ...state, shrinkedSubMenu: !state.shrinkedSubMenu };
      break;
  }

  localStorage.setItem("misc", JSON.stringify(newState));
  return newState;
};
