import React, { useEffect, useState } from "react";
import {
  Theme,
  Dialog,
  DialogContent,
  DialogActions,
  Button
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { ModalTitle } from "../../../../../../components/modal/ModalTitle";
import { ShopListEditor } from "./ShopListEditor";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    dialogContent: {
      minHeight: 330,
      padding: "0 24px"
    }
  })
);

export function ShopListEditorModal(props: {
  open: boolean;
  shopListId: number;
  onClose: () => void;
}) {
  const classes = useStyles({});
  const { t, i18n } = useTranslation();
  const { open, onClose, shopListId } = props;
  return (
    <Dialog
      fullWidth
      maxWidth={"md"}
      className={classes.root}
      open={open}
      onClose={onClose}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
    >
      <ShopListEditor onClose={onClose} guid={shopListId} />
    </Dialog>
  );
}
