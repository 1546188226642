import React from "react";
import { Theme, TableCell, Grid } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { GridItem } from "../../../../../../components/grid";
import { PlayerInfo } from "../../../../../../components/playerInfo";
import { Coins } from "../../../../../../components/coins";
import TimeAgo from "react-timeago";
import { SaleViewModel } from "@futbot/stats-api";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: 60,
      textAlign: "center",
      paddingTop: 16,
      fontSize: 20,
      fontFamily: "Cairo",
      color: "gray"
    }
  })
);

export function EmptyRankingRow() {
  const classes = useStyles({});
  return (
    <React.Fragment>
      <TableCell>
        <div className={classes.root}>N/A</div>
      </TableCell>
    </React.Fragment>
  );
}
