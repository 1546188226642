import React, { useEffect, useState, useCallback } from "react";
import {
  Theme,
  Paper,
  Avatar,
  Typography,
  FormControl,
  InputLabel,
  Input,
  Button,
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useDispatch, useMappedState } from "redux-react-hook";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { State } from "../../store/config/configureStore";
import { AuthenticationApi } from "@futbot/login-server-api";
import { login } from "../../store/actions/account";
import { push } from "connected-react-router";
import queryString from "query-string";
var Recaptcha = require("react-gcaptcha");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
        3
      )}px`,
    },
    avatar: {
      margin: 8,
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: 8,
    },
    submit: {
      marginTop: theme.spacing(3),
    },
  })
);

export function RegisterForm(props: { api: AuthenticationApi }) {
  const classes = useStyles({});
  const { t, i18n } = useTranslation();
  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [verification, setVerification] = useState<string>();
  const [captchaId, setCaptchaId] = useState(0);
  const [message, setMessage] = useState<string>();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const mapStateToProps = useCallback(
    (state: State) => ({
      refCode: state.router.location.search,
    }),
    []
  );
  const { refCode } = useMappedState(mapStateToProps);
  const values = queryString.parse(refCode);
  const [referalCode, setReferalCode] = useState(values.referalCode as string);
  const { api } = props;

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    setLoading(true);
    setMessage("");
    api
      .register({
        request: {
          username,
          password,
          passwordConfirmation,
          email,
          verification,
          referalCode,
        },
      })
      .then((response) => {
        setMessage(response.errorMessage);
        if (response.success) {
          api.token({ request: { username, password } }).then((response) => {
            dispatch(login(response));
            window.location.reload();
          });
        }
      })
      .catch((reason) => {
        console.log(reason);
      })
      .finally(() => {
        setCaptchaId(captchaId + 1);
        setLoading(false);
      });
  }

  return (
    <Paper className={classes.paper}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Register
      </Typography>
      {loading && <div>LOADING...</div>}
      <form className={classes.form} onSubmit={handleSubmit}>
        <FormControl margin="normal" required fullWidth disabled={loading}>
          <InputLabel htmlFor="username">Username</InputLabel>
          <Input
            value={username}
            name="password"
            autoFocus
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />
        </FormControl>
        <FormControl margin="normal" required fullWidth disabled={loading}>
          <InputLabel htmlFor="password">Password</InputLabel>
          <Input
            type="password"
            name="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </FormControl>
        <FormControl margin="normal" required fullWidth disabled={loading}>
          <InputLabel htmlFor="passwordConfirmation">
            Password Confirmation
          </InputLabel>
          <Input
            error={password && password !== passwordConfirmation}
            type="password"
            name="passwordConfirmation"
            value={passwordConfirmation}
            onChange={(e) => {
              setPasswordConfirmation(e.target.value);
            }}
          />
        </FormControl>
        <FormControl margin="normal" required fullWidth disabled={loading}>
          <InputLabel htmlFor="email">Email Address</InputLabel>
          <Input
            autoComplete="off"
            name="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </FormControl>
        {referalCode && (
          <FormControl margin="normal" required fullWidth disabled={loading}>
            <InputLabel htmlFor="referalcode">
              Referal Code (Optional)
            </InputLabel>
            <Input
              autoComplete="off"
              name="referalcode"
              value={referalCode}
              disabled
              onChange={(e) => {
                setReferalCode(e.target.value);
              }}
            />
          </FormControl>
        )}
        <FormControl margin="normal" required fullWidth disabled={loading}>
          <Recaptcha
            reset={captchaId}
            sitekey="6Ldx1DQUAAAAAEcEU8dtsAQ3GT9TsEzSbjGZ5nts"
            verifyCallback={(key) => {
              setVerification(key);
            }}
          />
        </FormControl>
        {message && <div>{message}</div>}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Register
        </Button>
        <Button
          onClick={(e) => {
            e.preventDefault();
            dispatch(push("/"));
          }}
          fullWidth
          variant="contained"
          color="secondary"
          className={classes.submit}
        >
          Sign in
        </Button>
      </form>
    </Paper>
  );
}
