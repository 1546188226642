import { useCallback } from "react";
import { State } from "../../../../store/config/configureStore";
import { useMappedState } from "redux-react-hook";

export * from "./TransactionsPage";

export const relativePagePath = "/transactions";
export const pagePath = "/manager/transactions";
export const smallListWidth = 260;
export const detailsMenuWidth = 260;
export const detailsContentWidth = detailsMenuWidth + smallListWidth;

export function extractTransactionGuid(pathname: string): string {
  var regex = /\/manager\/transactions\/([0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}).*/g;
  var match = regex.exec(pathname);
  if (!match || match.length < 1) {
    return null;
  }
  return match[1];
}

export function extractBaseTransactionPath(pathname: string): string {
  var regex = /(\/manager\/transactions\/[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}).*/g;
  var match = regex.exec(pathname);
  if (!match || match.length < 1) {
    return null;
  }
  return match[1];
}

export const useTransactionGuid = () => {
  const mapStateToProps = useCallback(
    (state: State) => ({
      transactionGuid: extractTransactionGuid(state.router.location.pathname)
    }),
    []
  );
  const { transactionGuid } = useMappedState(mapStateToProps);
  return { transactionGuid };
};

export const useTransactionBasePath = () => {
  const mapStateToProps = useCallback(
    (state: State) => ({
      basePath: extractBaseTransactionPath(state.router.location.pathname)
    }),
    []
  );
  const { basePath } = useMappedState(mapStateToProps);
  return { basePath };
};
