import React, { useState, useEffect } from "react";
import { Theme, Table, TableBody, TableRow } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { StatisticCard } from "../statisticCard";
import { LoadingRankingRow } from "../loadingRankingRow";

const useStyles = makeStyles((theme: Theme) => createStyles({}));

export function RankingCard<T>(props: {
  title: string;
  count: number;
  fetch: (count: number) => Promise<T[]>;
  renderElement: (element: T) => React.ReactNode;
  renderEmptyElement?: () => React.ReactNode;
  renderLoadingElement?: () => React.ReactNode;
}) {
  const classes = useStyles({});
  const { t, i18n } = useTranslation();
  const [reloadCount, setReloadCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [updateDate, setUpdateDate] = useState<number>(null);
  const [elements, setElements] = useState<T[]>([]);
  const {
    title,
    renderElement,
    renderEmptyElement,
    renderLoadingElement,
    fetch,
    count
  } = props;

  useEffect(() => {
    if (!fetch && !reloadCount) {
      return;
    }
    setIsLoading(true);
    fetch(count)
      .then(elems => {
        setElements(elems);
        setUpdateDate(Date.now());
      })
      .catch(r => console.log(r))
      .then(() => {
        setIsLoading(false);
      });
  }, [reloadCount]);

  return (
    <StatisticCard
      title={title}
      hideFooter
      onRenderBody={() => (
        <Table size="small">
          <TableBody>
            {isLoading ? (
              new Array(count - elements.length).fill(undefined).map((_, i) => (
                <TableRow key={i}>
                  <LoadingRankingRow />
                </TableRow>
              ))
            ) : (
              <React.Fragment>
                {elements.map((e, i) => {
                  return <TableRow key={i}>{renderElement(e)}</TableRow>;
                })}
                {renderEmptyElement &&
                  new Array(count - elements.length)
                    .fill(undefined)
                    .map((_, i) => (
                      <TableRow key={i}>{renderEmptyElement()}</TableRow>
                    ))}
              </React.Fragment>
            )}
          </TableBody>
        </Table>
      )}
    />
  );
}
