import React, { useEffect, useState } from "react";
import { Theme } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import {
  ColumnDefinition,
  InteractiveTable
} from "../../../../../../components/interactiveTable";
import { Link } from "react-router-dom";
import { DealsOfTheDayView } from "@futbot/stats-api";
import { PlayerCard } from "../../../../../../components/playerCard";
import { PlayerInfo } from "../../../../../../components/playerInfo";
import { Coins } from "../../../../../../components/coins";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: "#4894fe",
      fontWeight: 500,
      textDecoration: "none"
    }
  })
);

export function ProfitablePlayersTable(props: {
  isLoading?: boolean;
  isReadOnly?: boolean;
  data: DealsOfTheDayView[];
  onRefresh?: () => void;
}) {
  const classes = useStyles({});
  const { t, i18n } = useTranslation();
  const { isLoading, isReadOnly, data, onRefresh } = props;

  const columns: ColumnDefinition<DealsOfTheDayView>[] = [
    {
      id: "player",
      label: "Player Card",
      render: p => <PlayerInfo {...p.playerCard} />,
      value: p => p.playerCard
    },
    {
      id: "averagePrice",
      label: "Average Price",
      render: p => <Coins amount={p.averagePrice} />,
      value: p => p.averagePrice
    },
    {
      id: "minProfit",
      label: "Min Profit",
      render: p => <Coins amount={p.minProfit} color={"blue"} />,
      value: p => p.minProfit
    },
    {
      id: "maxProfit",
      label: "Max Profit",
      render: p => <Coins amount={p.maxProfit} color={"darkblue"} />,
      value: p => p.maxProfit
    },
    {
      id: "averageProfit",
      label: "Average Profit",
      render: p => <Coins amount={p.averageProfit} />,
      value: p => p.averageProfit
    },
    {
      id: "totalProfit",
      label: "Total Profit",
      render: p => <Coins amount={p.totalProfit} color={"green"} />,
      value: p => p.totalProfit
    },
    {
      id: "totalDeals",
      label: "Total Deals",
      render: p => <span>{p.totalDeals}</span>,
      value: p => p.totalDeals
    }
  ];
  return (
    <InteractiveTable<DealsOfTheDayView>
      isLoading={isLoading}
      isReadOnly={isReadOnly}
      data={data}
      columns={columns}
      onRefresh={onRefresh}
      getKey={t => t.playerCardId.toString() + t.platform}
    />
  );
}
