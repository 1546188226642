import React, { useEffect, useState, useCallback } from "react";
import {
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { title } from "process";
import CopiableLink from "../copiableLink";
import { useMappedState } from "redux-react-hook";
import { State } from "../../store/config/configureStore";

const useStyles = makeStyles((theme: Theme) => createStyles({}));

export function ReferalLinkModal(props: {
  open: boolean;
  onClose: () => void;
}) {
  const classes = useStyles({});
  const { t, i18n } = useTranslation();
  const { open, onClose } = props;
  const mapStateToProps = useCallback(
    (state: State) => ({
      referalCode:
        state &&
        state.account &&
        state.account.loginResponse &&
        state.account.loginResponse.settings
          ? state.account.loginResponse.settings.referalCode
          : ""
    }),
    []
  );
  const { referalCode } = useMappedState(mapStateToProps);
  return (
    <Dialog open={open}>
      <DialogTitle id="alert-dialog-title">
        {"Your referal link is ready !"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <span>
            Give this referal links to your friends to get awesome rewards{" "}
            <b>When they add valid bots and/or they buy subscriptions</b>
          </span>
        </DialogContentText>
        <CopiableLink
          link={`https://www.webapp.octofut.com/Register?referalCode=${referalCode}`}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
