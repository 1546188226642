import React, { useState, useEffect } from "react";
import { Theme, LinearProgress } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { SerializedEvent, LogsApi } from "@futbot/stats-api";
import { useStatApi } from "../../../../../../api";
import { LogViewerToolbar } from "../../../../../../components/logViewer/logViewerToolbar";
import { LogViewer } from "../../../../../../components/logViewer";

const useStyles = makeStyles((theme: Theme) => createStyles({}));

export function ExternalApisLogs(props: {}) {
  const classes = useStyles({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [reloadCount] = useState<number>(0);
  const [events, setEvents] = useState<SerializedEvent[]>(null);
  const logsApi = useStatApi(LogsApi);

  function refresh() {
    setIsLoading(true);
    logsApi
      .getSellingApiLogs({ page: 0 })
      .then((logs) => setEvents(logs))
      .catch((r) => console.log(r))
      .then(() => setIsLoading(false));
  }

  useEffect(() => {
    refresh();
  }, [reloadCount]);

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <React.Fragment>
      <LogViewerToolbar
        onRefreshClicked={refresh}
        onCriteriaChanged={(s) => setSearch(s)}
      />
      <LogViewer
        events={
          events
            ? events.filter(
                (e) =>
                  e.eventType.toLowerCase().indexOf(search.toLowerCase()) >=
                    0 ||
                  (e.message &&
                    e.message.toLowerCase().indexOf(search.toLowerCase()) >= 0)
              )
            : null
        }
      />
    </React.Fragment>
  );
}
