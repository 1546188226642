import React, { useState } from "react";
import { Paper, IconButton, LinearProgress, Tooltip } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useStatApi } from "../../../../../../api";
import {
  ShopListsApi,
  ShopListViewModel,
  FuzzySearchViewModel
} from "@futbot/stats-api";
import {
  InteractiveTable,
  ToolbarAction,
  ToolbarActionOnSelected,
  ColumnDefinition
} from "../../../../../../components/interactiveTable";
import { AddIcon, DeleteIcon, EditIcon } from "../../../../../../assets/icons";
import { useTranslation } from "react-i18next";
import { useActionOnItems } from "../../../../../../hooks/useActionOnItems";
import { useSnackbar } from "@creatdevsolutions/notistack";
import { FuzzySearchModal } from "../fuzzySearchModal";
import { getLeagueImage } from "../../../../../../components/assetPicker/LeaguePicker";
import { getNationImage } from "../../../../../../components/assetPicker/NationPicker";
import { getClubImage } from "../../../../../../components/assetPicker/ClubPicker";
import { getLevelImage } from "../../../../../../components/assetPicker/LevelPicker";
import { Coins } from "../../../../../../components/coins";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
      height: "calc(100vh - 139px)"
    }
  })
);

export function ShopListFuzzySearchesTable(props: {
  shoplist: ShopListViewModel;
  onReload?: () => void;
  isLoading?: boolean;
}) {
  const classes = useStyles({});
  const api = useStatApi(ShopListsApi);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [fuzzySearch, setFuzzySearch] = useState<FuzzySearchViewModel>(null);
  const [action, setAction] = useState<"add" | "update">("add");
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { actionOnSelected, bulkActionLoading } = useActionOnItems();
  const { shoplist, onReload, isLoading } = props;

  const actions: ToolbarAction[] = [
    {
      action: () => {
        setAction("add");
        setFuzzySearch({});
        setShowModal(true);
      },
      label: "Create",
      icon: AddIcon,
      variant: "outlined"
    }
  ];

  const actionsOnSelected: ToolbarActionOnSelected[] = [
    {
      action: deleteSelectedCards,
      label: "Delete",
      icon: DeleteIcon,
      variant: "outlined",
      clearSelectionAfterAction: true
    }
  ];

  async function deleteSelectedCards(selected: string[]) {
    await actionOnSelected(
      selected,
      selected.length == 1 ? null : `Removing ${selected.length} Cards...`,
      selected.length == 1
        ? `"${selected[0]}" Card Removed`
        : `${selected.length} Cards Removed`,
      async id => {
        await api.deleteFuzzySearch({
          listId: shoplist.id,
          fuzzySearch: { id: parseInt(id) }
        });
      }
    );
    onReload();
  }

  const columns: ColumnDefinition<FuzzySearchViewModel>[] = [
    {
      id: "name",
      label: "Name",
      width: "20%",
      value: c => c.name,
      render: c => c.name
    },
    {
      id: "nation",
      label: "Nation",
      width: "7%",
      value: c => c.nation,
      render: c =>
        c.nation ? (
          <img
            style={{
              marginRight: 10,
              width: 32,
              content: getNationImage(c.nation)
            }}
          />
        ) : (
          "Any"
        )
    },
    {
      id: "league",
      label: "League",
      width: "7%",
      value: c => c.league,
      render: c =>
        c.league ? (
          <img
            style={{
              marginRight: 10,
              width: 32,
              content: getLeagueImage(c.league)
            }}
          />
        ) : (
          "Any"
        )
    },
    {
      id: "club",
      label: "Club",
      width: "7%",
      value: c => c.team,
      render: c =>
        c.team ? (
          <img
            style={{
              marginRight: 10,
              width: 32,
              content: getClubImage(c.team)
            }}
          />
        ) : (
          "Any"
        )
    },
    {
      id: "level",
      label: "Level",
      width: "7%",
      value: c => c.level,
      render: c =>
        c.level ? (
          <img
            style={{
              marginRight: 10,
              width: 32,
              content: getLevelImage(c.level)
            }}
          />
        ) : (
          "Any"
        )
    },
    {
      id: "maxBuy",
      label: "Max Buy",
      width: "32%",
      value: c => c.maxBuy,
      render: c => <Coins amount={c.maxBuy} />
    },
    {
      id: "actions",
      label: "",
      align: "right",
      width: "15%",
      render: c => (
        <React.Fragment>
          <Tooltip title={t("misc.update")}>
            <IconButton
              size="small"
              onClick={() => {
                setAction("update");
                setFuzzySearch(c);
                setShowModal(true);
              }}
              color="primary"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("misc.delete")}>
            <IconButton
              size="small"
              color="secondary"
              onClick={async () => {
                await deleteSelectedCards([c.id.toString()]);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </React.Fragment>
      )
    }
  ];

  return (
    <Paper className={classes.root}>
      <FuzzySearchModal
        open={showModal}
        action={action}
        fuzzySearch={fuzzySearch}
        onClose={() => {
          setShowModal(false);
        }}
        onSubmit={async fs => {
          if (action == "add") {
            await api.addFuzzySearch({ listId: shoplist.id, fuzzySearch: fs });
          } else if (action == "update") {
            await api.updateFuzzySearch({
              listId: shoplist.id,
              fuzzySearch: fs
            });
          }
          enqueueSnackbar(
            `"${fs.name}" card ${action == "add" ? "Added" : "Updated"}`,
            { variant: "success" }
          );
          setShowModal(false);
          onReload();
        }}
      />
      {(isLoading || bulkActionLoading) && <LinearProgress />}
      <InteractiveTable<FuzzySearchViewModel>
        data={shoplist ? shoplist.fuzzySearches : []}
        wrapHeigth={212}
        toolbarColor="secondary"
        columns={columns}
        getKey={t => t.id.toString()}
        actions={actions}
        actionsOnSelected={actionsOnSelected}
        onRefresh={() => {
          onReload();
        }}
      />
    </Paper>
  );
}
