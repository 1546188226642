import React, { useState, useEffect } from "react";
import { Theme, Paper, Grid, Divider, Typography } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import {
  PlayerCardViewModel,
  HistoryApi,
  PlayerHistoryEntryViewModel,
  CardsApi,
  StatisticsApi,
} from "@futbot/stats-api";
import { PlayerCard } from "../../../../components/playerCard";
import { CoinEvolutionChart } from "../dashboard/components/coinEvolutionChart";
import { useMatch, usePerfectScroll } from "../../../../hooks";
import { useStatApi } from "../../../../api";
import { history } from "../../../../store/config/configureStore";
import playerCardBackground from "../../../../assets/images/backgrounds/5.jpg";
import { tertiaryColor } from "../../../../assets/jss";
import { PlayerPicker } from "../../../../components/playerPicker";
import { GridItem } from "../../../../components/grid";
import { SingleValueCard } from "../dashboard/components/singleValueCard";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: "calc(100vh - 85px)",
      background: "#3e2f748a",
    },
    content: {
      padding: 10,
      overflow: "auto",
      position: "relative",
      paddingLeft: 32,
      height: "calc(100vh - 145px)",
    },
    title: {
      color: tertiaryColor,
      textTransform: "uppercase",
      fontWeight: 500,
    },
    playerCard: {
      width: "100%",
      textAlign: "center",
      background: "#fff",
      padding: 30,
      backgroundImage: "url(" + playerCardBackground + ")",
      backgroundSize: "cover",
    },
  })
);

export function AnalyticsPage() {
  const classes = useStyles({});
  const { t, i18n } = useTranslation();
  const match = useMatch("/manager/analytics/:platform/:resourceId");
  const platform =
    match && match.params && (match.params as any).platform
      ? ((match.params as any).platform as number)
      : parseInt(localStorage.defP);
  const resourceId =
    match && match.params && (match.params as any).resourceId
      ? ((match.params as any).resourceId as number)
      : null;
  const historyApi = useStatApi(HistoryApi);
  const playerApi = useStatApi(CardsApi);
  const statisticsApi = useStatApi(StatisticsApi);
  const [reloadCount, setReloadCount] = useState<number>(0);
  const [card, setCard] = useState<PlayerCardViewModel>(null);
  const [currentPrice, setCurrentPrice] = useState<PlayerHistoryEntryViewModel>(
    null
  );
  const [data, setData] = useState<PlayerHistoryEntryViewModel[]>(null);
  const scrollRef = usePerfectScroll();

  useEffect(() => {
    setReloadCount(reloadCount + 1);
  }, [platform, resourceId]);

  useEffect(() => {
    if (!resourceId || !platform) {
      return;
    }
    setCurrentPrice(null);
    historyApi
      .getPlayerHistory({ platform, resourceId })
      .then((history) => {
        var h = history.reverse();
        setData(h);
        if (h && h.length) {
          setCurrentPrice(history[0]);
        }
      })
      .catch((r) => {
        console.log(r);
      });
    playerApi
      .getCardById({ id: resourceId })
      .then((c) => {
        setCard(c);
      })
      .catch((r) => {
        console.log(r);
      });
  }, [reloadCount]);

  const navigate = (p: number, c: number) => {
    localStorage.defP = p;
    var url = `/manager/analytics/${p}/${c}`;
    history.push(url);
  };

  return (
    <Paper className={classes.root}>
      <PlayerPicker
        onSelected={navigate}
        initialCard={card}
        initialPlatformId={platform}
      />
      <Divider />
      <div className={classes.content} ref={scrollRef}>
        {card && (
          <React.Fragment>
            <Typography variant="h5" className={classes.title}>
              {`${card.firstName} ${card.lastName}`}
            </Typography>
            <Grid container>
              <GridItem item sm={12} md={4} lg={3}>
                <Grid container>
                  <Paper className={classes.playerCard}>
                    <PlayerCard {...card} />
                  </Paper>
                </Grid>
              </GridItem>

              <GridItem sm={12} md={8} lg={9}>
                <CoinEvolutionChart
                  chartStroke="#333"
                  title={t("analytics.labels.playerPriceEvolution")}
                  description={t(
                    "analytics.labels.playerPriceEvolutionLast7days"
                  )}
                  unavailableDataMessage={t("analytics.labels.noData")}
                  prices={data}
                  lastUpdate={currentPrice ? currentPrice.date : null}
                />
              </GridItem>
            </Grid>
            <Grid container>
              <Grid container>
                <GridItem lg={3} md={4} sm={6}>
                  <SingleValueCard
                    title={t("analytics.labels.generatedCoins")}
                    fetch={async () => {
                      return (
                        await statisticsApi.madeCoinsCount({
                          playerCardId: card.id,
                        })
                      ).count;
                    }}
                  />
                </GridItem>
                <GridItem lg={3} md={4} sm={6}>
                  <SingleValueCard
                    title={t("analytics.labels.dealsCount")}
                    fetch={async () => {
                      return (
                        await statisticsApi.dealsCount({
                          playerCardId: card.id,
                        })
                      ).count;
                    }}
                  />
                </GridItem>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </div>
    </Paper>
  );
}
