import React from "react";
import { Theme, TableCell, Grid } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import Skeleton from "@material-ui/lab/Skeleton";
import { PlayerInfo } from "../../../../../../components/playerInfo";
import { Coins } from "../../../../../../components/coins";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: 60,
      width: 0,
      animation:
        "$fullView 0.5s forwards cubic-bezier(0.250, 0.460, 0.450, 0.940)"
    },
    icon: {
      height: 45,
      width: 45,
      borderRadius: "50%"
    },
    text: {
      height: 30,
      background: "#777",
      margin: 10,
      minWidth: 200
    },
    "@keyframes fullView": {
      "100%": {
        width: "100%"
      }
    },
    animate: {
      animation: "$shimmer 4s infinite linear",
      background:
        "linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%)",
      backgroundSize: "1000px 100%"
    },
    "@keyframes shimmer": {
      "0%": {
        backgroundPosition: "-1000px 0"
      },
      "100%": {
        backgroundPosition: "1000px 0"
      }
    }
  })
);

export function LoadingRankingRow(props: {}) {
  const classes = useStyles({});
  const { t, i18n } = useTranslation();
  const {} = props;
  return (
    <React.Fragment>
      <TableCell>
        <div className={classes.root}>
          <Grid container alignItems="center">
            <Grid item xs={2}>
              <Skeleton variant="circle" width={40} height={40} />
            </Grid>
            <Grid item xs={10}>
              <Skeleton height={30} width="100%" />
            </Grid>
          </Grid>
        </div>
      </TableCell>
    </React.Fragment>
  );
}
