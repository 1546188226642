import React, { useState, useEffect } from "react";
import { FormControlLabel, Checkbox } from "@material-ui/core";

export function InputBottingMode(props: {
  value: number;
  onChanged: (newValue: number) => void;
  className?: string;
}) {
  const { value, onChanged, className } = props;
  const [newValue, setNewValue] = useState<number>(value);

  useEffect(() => {
    setNewValue(value);
  }, [props]);

  function append(checked: boolean, weight: number) {
    var appendedValue = value + (checked ? weight : -weight);
    onChanged(appendedValue);
    setNewValue(appendedValue);
  }

  return (
    <div className={className}>
      <FormControlLabel
        control={
          <Checkbox
            checked={(newValue & 1) === 1}
            onChange={e => {
              append(e.target.checked, 1);
            }}
            color="primary"
          />
        }
        label="Snipe player cards"
      />
      <FormControlLabel
        control={
          <Checkbox
            disabled
            checked={(newValue & 8) === 8}
            onChange={e => {
              append(e.target.checked, 8);
            }}
            color="primary"
          />
        }
        label="Handle transaction orders exclusively"
      />
    </div>
  );
}
