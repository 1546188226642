export const toggleHelp = () => {
  return {
    type: "MISC_TOGGLE_HELP"
  };
};

export const toggleAnonymize = () => {
  return {
    type: "MISC_TOGGLE_ANONYMIZE"
  };
};

export const toggleSubMenu = () => {
  return {
    type: "MISC_TOGGLE_SUBMENU"
  };
};
