class App {
  isLoading: boolean;
}

export const initialState: App = {
  isLoading: false
};

export default (
  state = initialState,
  action: { type: string; payload: any }
): typeof initialState => {
  let newState = state;
  switch (action.type) {
    case "APP_LOADING":
      newState = { ...state, isLoading: action.payload };
      break;
  }
  return newState;
};
