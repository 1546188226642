import React from "react";
import { Theme } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import "./pcdisplay.css";
import { PlayerCardViewModel } from "@futbot/stats-api";
import { rarityMap } from "./rarityMap";
import { getCardColor } from "./getCardColor";

export const baseEaAssetsUrl =
  "https://www.ea.com/fifa/ultimate-team/web-app/content/21D4F1AC-91A3-458D-A64E-895AA6D871D1/2021";

const useStyles = makeStyles((theme: Theme) => createStyles({}));

export function PlayerCard(props: PlayerCardViewModel) {
  const classes = useStyles({});
  const { t, i18n } = useTranslation();
  const {
    id,
    rating,
    position,
    imageUrl,
    clubId,
    nationId,
    name,
    baseId,
    color,
    rarityId,
  } = props;

  var imgSrc = `${baseEaAssetsUrl}/fut/items/images/mobile/portraits/${baseId}.png`;
  if (rarityId > 1) {
    imgSrc = `${baseEaAssetsUrl}/fut/playerheads/mobile/single/p${id}.png`;
  }

  function fallbackSrc(ev) {
    ev.target.src = `${baseEaAssetsUrl}/fut/items/images/mobile/portraits/${baseId}.png`;
  }

  return (
    <div
      id="Player-card"
      data-revision=""
      data-level={color}
      data-rare-type={rarityId}
      className={`pcdisplay ut19 card-regular center-block ${getCardColor(
        rating
      )} ${rarityMap[rarityId]}`}
    >
      <div className="top-overlay" />
      <div className="pcdisplay-rat">{rating}</div>
      <div className="pcdisplay-name">{name}</div>
      <div className="pcdisplay-pos">{position}</div>
      <div className="pcdisplay-country">
        <img
          id="player_nation"
          src={`${baseEaAssetsUrl}/fut/items/images/mobile/flags/card/${nationId}.png`}
        />
      </div>
      <div className="pcdisplay-club">
        <img
          id="player_club"
          src={`${baseEaAssetsUrl}/fut/items/images/mobile/clubs/normal/${clubId}.png`}
        />
      </div>

      <div className="pcdisplay-picture ">
        <img
          className="pcdisplay-picture-width"
          id="player_pic"
          src={imgSrc}
          onError={fallbackSrc}
        />
      </div>
    </div>
  );
}
