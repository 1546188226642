import React from "react";
import TimeAgo from "react-timeago";
import {
  SimpleBotViewModel,
  SimpleBotSettingsViewModel
} from "@futbot/runtime-api";
import { ColumnDefinition } from "../../../../../../components/interactiveTable/columnDefinition";
import { InteractiveTable } from "../../../../../../components/interactiveTable/interactiveTable";
import { makeStyles } from "@material-ui/styles";
import { createStyles, Tooltip, IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";
import { pagePath } from "../..";
import { Anonymizable } from "../../../../../../components/anonymizable";
import { Coins } from "../../../../../../components/coins";
import { useDispatch } from "redux-react-hook";
import { BotProgress } from "./botProgress";
import {
  ToolbarAction,
  ToolbarActionOnSelected
} from "../../../../../../components/interactiveTable/interactiveTableToolbar";
import { BotSchedule } from "./botSchedule";
import {
  ExternalLinkIcon,
  StopIcon,
  StartIcon,
  DeleteIcon,
  AddIcon,
  LogsIcon
} from "../../../../../../assets/icons";
import { useTranslation } from "react-i18next";
import { push } from "connected-react-router";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      overflow: "auto"
    },
    link: {
      color: "#4894fe",
      fontWeight: 500,
      textDecoration: "none"
    },
    lastActivity: {
      fontSize: 11,
      color: "#90949c"
    },
    additionalInformation: {
      fontSize: 11
    }
  })
);
export function BotsTable(props: {
  bots: SimpleBotViewModel[];
  isReadOnly?: boolean;
  isLoading?: boolean;
  onRefresh?: () => void;
  onCreationRequest: () => void;
  onStartRequest: (bots: string[]) => Promise<void>;
  onStopRequest: (bots: string[]) => Promise<void>;
  onDeleteRequest: (bots: string[]) => Promise<void>;
}) {
  const {
    bots,
    onCreationRequest,
    onStartRequest,
    onDeleteRequest,
    onStopRequest,
    isReadOnly,
    isLoading,
    onRefresh
  } = props;
  const classes = useStyles({});
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const columns: ColumnDefinition<SimpleBotViewModel>[] = [
    {
      id: "username",
      label: "Username",
      width: "15%",
      value: bot => bot.u,
      render: bot => (
        <React.Fragment>
          <div>
            <Link
              className={classes.link}
              to={pagePath + "/" + bot.id + "/overview"}
            >
              <Anonymizable>{bot.u}</Anonymizable>
            </Link>
          </div>
          <div className={classes.lastActivity}>
            Latest activity: <TimeAgo date={bot.la} />
          </div>
        </React.Fragment>
      )
    },
    {
      id: "state",
      label: "State",
      width: "40%",
      value: bot => bot.u,
      render: bot => (
        <React.Fragment>
          <div>{bot.st}</div>
          <div className={classes.additionalInformation}>{bot.ai}</div>
        </React.Fragment>
      )
    },
    {
      id: "coins",
      label: "Coins",
      width: "8%",
      align: "right",
      numeric: true,
      value: bot => bot.u,
      render: bot => <Coins amount={bot.c} />
    },
    {
      id: "schedule",
      label: "Schedule",
      width: "12%",
      value: bot => bot.us,
      render: bot => (
        <React.Fragment>
          <BotSchedule schedule={bot.sc} disabled={!bot.us} />
        </React.Fragment>
      )
    },
    {
      id: "requestcount",
      label: "Requests",
      width: "8%",
      align: "right",
      numeric: true,
      value: bot => bot.u,
      render: bot => <BotProgress current={bot.rc} limit={bot.rl} />
    },
    {
      id: "actions",
      label: "",
      width: "12%",
      align: "right",
      numeric: true,
      value: bot => bot.id,
      render: bot => {
        var isRunning = bot.st && bot.st.indexOf("stopped") < 0;
        return (
          <React.Fragment>
            <Tooltip title={t(isRunning ? "misc.stop" : "misc.start")}>
              <IconButton
                disabled={isReadOnly}
                size="small"
                onClick={() => {
                  if (isRunning) {
                    onStopRequest([bot.id]);
                  } else {
                    onStartRequest([bot.id]);
                  }
                }}
              >
                {isRunning ? <StopIcon /> : <StartIcon />}
              </IconButton>
            </Tooltip>
            <Tooltip title={t("misc.viewLogs")}>
              <IconButton
                disabled={isReadOnly}
                size="small"
                onClick={() => {
                  dispatch(push(pagePath + "/" + bot.id + "/logs"));
                }}
              >
                <LogsIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("misc.delete")}>
              <IconButton
                disabled={isReadOnly}
                size="small"
                color="secondary"
                onClick={() => {
                  onDeleteRequest([bot.id]);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </React.Fragment>
        );
      }
    }
  ];

  const actions: ToolbarAction[] = [
    {
      action: onCreationRequest,
      label: "Create",
      variant: "outlined",
      icon: AddIcon
    }
  ];

  const actionsOnSelected: ToolbarActionOnSelected[] = [
    {
      action: onStartRequest,
      label: "Start",
      variant: "outlined",
      icon: StartIcon
    },
    {
      action: onStopRequest,
      label: "Stop",
      variant: "outlined",
      icon: StopIcon
    },
    {
      action: onDeleteRequest,
      label: "Delete",
      variant: "outlined",
      icon: DeleteIcon
    }
  ];

  return (
    <InteractiveTable<SimpleBotViewModel>
      isLoading={isLoading}
      isReadOnly={isReadOnly}
      data={bots}
      columns={columns}
      onRefresh={onRefresh}
      getKey={t => t.id}
      actions={actions}
      actionsOnSelected={actionsOnSelected}
    />
  );
}
