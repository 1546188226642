import React, { useState, useEffect } from "react";
import { LinearProgress } from "@material-ui/core";
import { useRuntimeApi } from "../../../../../../api";
import { AuctionViewModel, SellingApiApi } from "@futbot/runtime-api";
import { PlayerCard } from "../../../../../../components/playerCard";
import { EmptyPile } from "./EmptyPile";

export function ExternalApisOrders(props: {}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [auctions, setAuctions] = useState<AuctionViewModel[]>(null);
  const [reloadCount] = useState<number>(0);
  const api = useRuntimeApi(SellingApiApi);
  useEffect(() => {
    setIsLoading(true);
    api.getOrders().then(auctions => {
      setAuctions(auctions);
      setIsLoading(false);
    });
  }, [reloadCount]);

  if (isLoading || !auctions) {
    return <LinearProgress />;
  }

  if (auctions.length === 0) {
    return <EmptyPile />;
  }

  return (
    <div>
      {auctions.map((auction, i) => {
        return <PlayerCard key={i} {...auction.playerCard} />;
      })}
    </div>
  );
}
