import React, { useEffect, useState } from "react";
import { Theme, IconButton, Tooltip } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Coins } from "../coins";
import { Octofutiums } from "../octofutiums";
import { RefreshIcon } from "../../assets/icons";
import { CurrencyViewModel } from "@futbot/stats-api";
import { useRuntimeApi } from "../../api";
import { BotManagerApi } from "@futbot/runtime-api";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    currency: {
      marginRight: 10,
      cursor: "pointer"
    }
  })
);

export function CurrencyViewer(props: {}) {
  const classes = useStyles({});
  const {} = props;
  const { t, i18n } = useTranslation();
  const [currency, setCurrency] = useState<CurrencyViewModel>({
    coins: 0,
    octofutiums: 0
  });
  const [reload, setReload] = useState(0);
  const api = useRuntimeApi(BotManagerApi);
  useEffect(() => {
    api.getAvailableCoins().then(c => {
      setCurrency(c);
    });
  }, [reload]);
  return (
    <Tooltip
      title={t("misc.clickToRefresh")}
      placement="bottom-end"
      onClick={() => {
        setReload(reload + 1);
      }}
    >
      <div>
        <span className={classes.currency}>
          <Coins
            amount={currency.coins}
            alwaysDiplay={true}
            color={"#99d0dd"}
          />
        </span>
        <span className={classes.currency}>
          <Octofutiums
            amount={currency.octofutiums}
            alwaysDiplay={true}
            color={"#99d0dd"}
          />
        </span>
      </div>
    </Tooltip>
  );
}
