import React, { useCallback } from "react";
import { Theme, Checkbox, Switch, FormControlLabel } from "@material-ui/core";
import { useDispatch, useMappedState } from "redux-react-hook";
import { State } from "../../store/config/configureStore";
import { toggleAnonymize } from "../../store/actions/misc";

export function ToggleDataAnonymization(props: {}) {
  const dispatch = useDispatch();
  const mapStateToProps = useCallback(
    (state: State) => ({
      anonymizeData: state.misc.anonymizeData
    }),
    []
  );
  const { anonymizeData } = useMappedState(mapStateToProps);

  const {} = props;
  return (
    <FormControlLabel
      label="Anonymize my data"
      control={
        <Checkbox
          color="default"
          checked={anonymizeData}
          onChange={(e, c) => {
            dispatch(toggleAnonymize());
          }}
        />
      }
    />
  );
}
