import React from "react";
import { leagues } from "./leagues";
import { AssetPicker } from "./AssetPicker";

export function getAnyLeagueImage(): string {
  return `url(https://www.easports.com/fifa/ultimate-team/web-app/content/7D49A6B1-760B-4491-B10C-167FBC81D58A/2019/fut/items/images/mobile/leagueLogos/light/default.png)`;
}

export function getLeagueImage(league: number): string {
  return `url(https://www.easports.com/fifa/ultimate-team/web-app/content/7D49A6B1-760B-4491-B10C-167FBC81D58A/2019/fut/items/images/mobile/leagueLogos/light/${league}.png)`;
}

export function LeaguePicker(props: {
  initialValue?: number;
  onChange?: (value: number) => void;
  className?: string;
}) {
  const {} = props;
  return (
    <AssetPicker
      className={props.className}
      initialValue={props.initialValue}
      onChange={props.onChange}
      anyLabel={"Any League"}
      assetLabel={"League"}
      getImgLink={getLeagueImage}
      getAnyImgLink={getAnyLeagueImage}
      source={leagues}
    />
  );
}
