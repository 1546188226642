import React from "react";
import { Switch, Route } from "react-router-dom";
import { ComponentClass, FunctionComponent } from "react";

export interface RouteInfo {
  path: string;
  exact?: boolean;
  name?: string;
  disabled?: boolean;
  icon?: any;
  endOfSection?: boolean;
  component: ComponentClass | FunctionComponent;
}

export function switchRoutes(routes: RouteInfo[], rootPath: string) {
  return (
    <Switch>
      {routes
        .filter(r => !r.disabled)
        .map((route: RouteInfo, key: number) => {
          return (
            <Route
              exact={route.exact}
              path={rootPath + route.path}
              component={route.component}
              key={key}
            />
          );
        })}
    </Switch>
  );
}
