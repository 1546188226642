import React, { useState, useEffect } from "react";
import {
  Theme,
  Table,
  TableBody,
  LinearProgress,
  TableCell,
  TableRow,
  Typography,
  Icon,
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useStatApi } from "../../../../../../api";
import {
  HistoryApi,
  SaleViewModel,
  CaptchaOutcomeViewModel,
} from "@futbot/stats-api";
import { PlayerInfo } from "../../../../../../components/playerInfo";
import { Coins } from "../../../../../../components/coins";
import TimeAgo from "react-timeago";
import { useBotGuid } from "../..";
import { usePerfectScroll } from "../../../../../../hooks";
import SuccessIcon from "@material-ui/icons/Check";
import FailureIcon from "@material-ui/icons/Close";
import { NoCaptchas } from "./NoCaptchas";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    tableWrapper: {
      overflow: "auto",
      position: "relative",
      maxHeight: "calc(100vh - 160px)",
    },
  })
);

export function BotCaptchaReview(props: {}) {
  const classes = useStyles({});
  const [captchas, setCaptchas] = useState<CaptchaOutcomeViewModel[]>([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const api = useStatApi(HistoryApi);
  const { botGuid } = useBotGuid();
  const wrapperRef = usePerfectScroll();

  useEffect(() => {
    setIsLoading(true);
    api
      .getCaptchaOutcomes({ botId: botGuid, page })
      .then((svm: CaptchaOutcomeViewModel[]) => {
        setCaptchas(svm);
      })
      .catch((reason) => {
        console.log(reason);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [botGuid]);

  if (isLoading) {
    return <LinearProgress />;
  }

  if (!captchas.length) {
    return (
      <div ref={wrapperRef}>
        <NoCaptchas />
      </div>
    );
  }

  return (
    <div>
      <div className={classes.tableWrapper} ref={wrapperRef}>
        <Table>
          <TableBody>
            {captchas.map((captcha, i) => {
              return (
                <TableRow hover key={i}>
                  <TableCell>
                    {captcha.success ? (
                      <SuccessIcon fontSize="large" />
                    ) : (
                      <FailureIcon color="error" fontSize="large" />
                    )}
                  </TableCell>
                  <TableCell>
                    <Typography>
                      <React.Fragment>
                        {captcha.success
                          ? "Successfully solved a captcha "
                          : "Failed to solve a captcha "}
                        <TimeAgo date={captcha.date} />
                        {captcha.errorMessage
                          ? " (" + captcha.errorMessage + ")"
                          : ""}
                      </React.Fragment>
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
