import React from "react";
import { Theme, Tooltip } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { BotSettingsViewModel } from "@futbot/runtime-api";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    schedule: {
      minWidth: 170
    },
    slot: {
      height: 15,
      width: 5,
      margin: 1,
      display: "inline-block"
    },
    on: {
      background: "#8ae48a",
      "&:hover": {
        background: "green"
      }
    },
    off: {
      background: "#f2c9c9",
      "&:hover": {
        background: "red"
      }
    },
    disabled: {
      background: "#9e9e9e",
      "&:hover": {
        background: "black"
      }
    },
    now: {
      borderTop: "1px solid #717070",
      borderBottom: "1px solid #717070"
    }
  })
);

function padLeft(n) {
  var len = String(10).length - String(n).length + 1;
  return len > 0 ? new Array(len).join("0") + n : n;
}

export function BotSchedule(props: { schedule: number; disabled: boolean }) {
  const classes = useStyles({});
  const { schedule, disabled } = props;
  let slots = [];
  var currentHour = new Date().getHours() + 1;

  for (let i = 0; i < 24; i++) {
    var state = (schedule & (1 << i)) != 0;
    var start = padLeft(i) + ":00";
    var stop = padLeft(i + 1) + ":00";
    slots.push(
      <Tooltip
        key={i}
        title={
          disabled
            ? "Scheduler is disabled "
            : `${start} : ${state ? "On" : "Off"}`
        }
        aria-label="toggle"
      >
        <span
          className={classNames(classes.slot, {
            [classes.on]: state,
            [classes.off]: !state,
            [classes.disabled]: disabled,
            [classes.now]: i == currentHour
          })}
        />
      </Tooltip>
    );
  }
  return <div className={classes.schedule}>{slots}</div>;
}
