import React from "react";
import { switchRoutes } from "../../utils/routeUtils";
import routes from "./routes";

export const modulePath = "/customer";
const moduleSwitch = switchRoutes(routes, modulePath);

export function CustomerModule(props: {}) {
  const {} = props;
  return <React.Fragment>{moduleSwitch}</React.Fragment>;
}
