import React, { useCallback } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Theme
} from "@material-ui/core";
import { BotViewModel, SimpleBotViewModel } from "@futbot/runtime-api";
import { State } from "../../../../../../store/config/configureStore";
import { useMappedState } from "redux-react-hook";
import { Link } from "react-router-dom";
import { extractBotGuid, smallBotListWidth } from "../..";
import { makeStyles, createStyles } from "@material-ui/styles";
import { usePerfectScroll } from "../../../../../../hooks";
import classNames from "classnames";
import { Anonymizable } from "../../../../../../components/anonymizable";
import { InteractiveListHeader } from "../../../../../../components/interactiveTable";

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    tableWrapper: {
      overflow: "auto",
      position: "relative",
      maxHeight: "calc(100vh - 160px)"
    },
    cell: {
      borderLeft: "5px solid #999"
    },
    errored: {
      borderLeft: "5px solid red"
    },
    running: {
      borderLeft: "5px solid green"
    },
    link: {
      color: "#4894fe",
      fontWeight: 500,
      textDecoration: "none"
    }
  })
);

export function BotList(props: { bots: SimpleBotViewModel[] }) {
  const { bots } = props;
  const classes = useStyles({});
  const mapStateToProps = useCallback(
    (state: State) => ({
      pathname: state.router.location.pathname,
      botGuid: extractBotGuid(state.router.location.pathname)
    }),
    []
  );
  const { pathname, botGuid } = useMappedState(mapStateToProps);
  const wrapperRef = usePerfectScroll();

  return (
    <div className={classes.root}>
      <InteractiveListHeader header={"Username"} />
      <div className={classes.tableWrapper} ref={wrapperRef}>
        <Table>
          <TableBody>
            {bots.map((bot, i) => (
              <TableRow hover key={i} selected={bot.id === botGuid}>
                <TableCell
                  className={classNames(classes.cell, {
                    [classes.running]:
                      bot.st && !(bot.st.indexOf("error") >= 0),
                    [classes.errored]: bot.st && bot.st.indexOf("error") >= 0
                  })}
                >
                  <Link
                    className={classes.link}
                    to={pathname.replace(botGuid, bot.id)}
                  >
                    <Anonymizable>{bot.u}</Anonymizable>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
