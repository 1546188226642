import React from "react";
import ReactDOM from "react-dom";
import configureStore from "./store/config/configureStore";
import { StoreContext } from "redux-react-hook";
import * as serviceWorker from "./serviceWorker";
import { Switch, Route } from "react-router";
import { history } from "./store/config/configureStore";
import { ManagerModule } from "./modules/manager";
import { AdministratorModule } from "./modules/administrator";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { LoginModule, RegisterModule } from "./modules/login";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { SnackbarProvider } from "@creatdevsolutions/notistack";
import { library } from "@fortawesome/fontawesome-svg-core";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import {
  faPlaystation,
  faXbox,
  faWindows
} from "@fortawesome/free-brands-svg-icons";
import { CustomerModule } from "./modules/customer/CustomerModuler";
import { CssBaseline } from "@material-ui/core";
import { GlobalLoading } from "./components/globalLoading";
import { switchRoutes } from "./utils/routeUtils";
import routes from "./routes";

require("dotenv").config();
document.title = process.env.REACT_APP_TITLE;

const theme = createMuiTheme({
  palette: { primary: { main: "#183f94" } },
  typography: {
    fontFamily: ["Cairo", "Roboto", '"Helvetica"', '"Segoe UI"'].join(",")
  }
});

library.add(faPlaystation, faXbox, faWindows);

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: "en",

    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false
    },
    detection: {
      order: ["localStorage"],
      lookupLocalStorage: "i18n"
    }
  });
const store = configureStore();

const modulesSwitch = switchRoutes(routes, "");

ReactDOM.render(
  <React.Fragment>
    <CssBaseline />
    <SnackbarProvider maxSnack={5}>
      <ThemeProvider theme={theme}>
        <StoreContext.Provider value={store}>
          <Provider store={store}>
            <GlobalLoading />
            <ConnectedRouter history={history}>{modulesSwitch}</ConnectedRouter>
          </Provider>
        </StoreContext.Provider>
      </ThemeProvider>
    </SnackbarProvider>
  </React.Fragment>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
