import { useScript } from "./useScript";
import { useState } from "react";

export function usePaypal() {
  const [loaded, error] = useScript(
    "https://www.paypalobjects.com/api/checkout.js",
    true
  );
  const [paypal, setPaypal] = useState(undefined);

  if (paypal === undefined && loaded && (window as any).paypal !== undefined) {
    setPaypal((window as any).paypal);
  }

  return paypal;
}
