import React from "react";
import { Theme, Paper, Grid, LinearProgress } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: "grow"
    }
  })
);

export function MasterDetails(props: {
  listComponent: React.ReactNode;
  detailsComponent: React.ReactNode;
  tableComponent: React.ReactNode;
  selectedElement: any;
}) {
  const classes = useStyles({});
  const {
    listComponent,
    detailsComponent,
    tableComponent,
    selectedElement
  } = props;

  return (
    <Paper className={classes.root}>
      {selectedElement ? (
        <Grid container>
          <Grid item md={3} xl={2} xs>
            {listComponent}
          </Grid>
          <Grid item md={9} xl={10} xs>
            {detailsComponent}
          </Grid>
        </Grid>
      ) : (
        <React.Fragment>{tableComponent}</React.Fragment>
      )}
    </Paper>
  );
}
