import React from "react";
import { Theme } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import BadMoodIcon from "@material-ui/icons/MoodBad";
import { EmptyPileIcon } from "../../../../../../assets/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "absolute",
      transform: "translate(-50%, -50%)",
      top: "50%",
      left: "50%",
      textAlign: "center",
      color: "rgba(85, 85, 85, 0.31)",
      fontSize: 30
    },
    icon: {
      fontSize: 150
    }
  })
);

export function EmptyPile(props: {}) {
  const classes = useStyles({});
  const {} = props;
  return (
    <div className={classes.root}>
      <EmptyPileIcon className={classes.icon} />
      <div>There are no pending orders for now</div>
    </div>
  );
}
