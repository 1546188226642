import React, { useEffect, useState } from "react";
import { Theme } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import {
  ColumnDefinition,
  InteractiveTable
} from "../../../../../../components/interactiveTable";
import { ProxyViewModel } from "@futbot/runtime-api";
import { Link } from "react-router-dom";
import { pagePath } from "../..";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: "#4894fe",
      fontWeight: 500,
      textDecoration: "none"
    }
  })
);

export function ProxiesTable(props: {
  isLoading?: boolean;
  isReadOnly?: boolean;
  data: ProxyViewModel[];
  onRefresh?: () => void;
}) {
  const classes = useStyles({});
  const { t, i18n } = useTranslation();
  const { isLoading, isReadOnly, data, onRefresh } = props;

  const columns: ColumnDefinition<ProxyViewModel>[] = [
    {
      id: "url",
      label: "Proxy Url",
      width: "40%",
      render: proxy => (
        <Link
          className={classes.link}
          to={`${pagePath}/${proxy.settings.id}`}
        >{`${proxy.settings.type}://${proxy.settings.host}:${proxy.settings.port}`}</Link>
      )
    },
    {
      id: "usedCount",
      label: "Used Count",
      width: "20%",
      render: proxy => <div>{proxy.usedCount}</div>
    },
    {
      id: "reportedCount",
      label: "Reported Count",
      width: "20%",
      render: proxy => <div>{proxy.reportedCount}</div>
    },
    {
      id: "purpose",
      label: "Purpose",
      width: "20%",
      render: proxy => (
        <div>{proxy.settings.purpose == 0 ? "Botting" : "Captcha-Solving"}</div>
      )
    }
  ];
  return (
    <InteractiveTable<ProxyViewModel>
      isLoading={isLoading}
      isReadOnly={isReadOnly}
      data={data}
      columns={columns}
      onRefresh={onRefresh}
      getKey={t => t.settings.id.toString()}
    />
  );
}
