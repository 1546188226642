import React, { useState, useEffect } from "react";
import {
  Theme,
  LinearProgress,
  Paper,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useRuntimeApi } from "../../../../api";
import { ProxyManagerApi, ProxyViewModel } from "@futbot/runtime-api";
import { usePerfectScroll } from "../../../../hooks";
import { useLoading } from "../../../../hooks/useLoading";
import { ProxiesTable } from "./components/ProxiesTable";
import { useActionOnItems } from "../../../../hooks/useActionOnItems";
import { MasterDetails } from "../../../../components/masterDetails";
import { ProxiesList } from "./components/ProxiesList";
import { useProxyId } from ".";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: "grow"
    },
    tableWrapper: {
      overflow: "auto",
      position: "relative",
      maxHeight: "calc(100vh - 160px)"
    }
  })
);

export function ProxyPage(props: {}) {
  const classes = useStyles({});
  const { isLoading, setIsLoading } = useLoading();
  const [reload, setReload] = useState(0);
  const [proxies, setProxies] = useState<ProxyViewModel[]>([]);
  const api = useRuntimeApi(ProxyManagerApi);

  const { proxyId } = useProxyId();
  const {
    actionOnSelected,
    reloadCount,
    bulkActionLoading
  } = useActionOnItems();

  useEffect(() => {
    setIsLoading(true);
    api
      .getProxySettingsList()
      .then(proxies => {
        setProxies(proxies);
      })
      .catch(reason => {
        console.log(reason);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <Paper>
      <MasterDetails
        selectedElement={proxyId}
        detailsComponent={<div></div>}
        tableComponent={
          <ProxiesTable
            isLoading={isLoading || bulkActionLoading}
            isReadOnly={isLoading || bulkActionLoading}
            data={proxies}
            onRefresh={() => setReload(reload + 1)}
          />
        }
        listComponent={<ProxiesList data={proxies} />}
      />
    </Paper>
  );
}
