import { useCallback } from "react";
import { State } from "../../../../store/config/configureStore";
import { useMappedState } from "redux-react-hook";

export * from "./TransactionsPage";

export const relativePagePath = "/transaction";
export const pagePath = "/customer/transaction";
export const smallListWidth = 260;
export const detailsMenuWidth = 260;
export const detailsContentWidth = detailsMenuWidth + smallListWidth;

export function extractTransactionGuid(
  pathname: string
): { transactionGuid: string; isAuto: boolean } {
  var regex = /\/customer\/transaction\/([0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}).*/gi;
  var match = regex.exec(pathname);
  if (!match || match.length < 1) {
    return null;
  }
  return { transactionGuid: match[1], isAuto: match.length >= 2 };
}

export const useTransactionGuid = () => {
  const mapStateToProps = useCallback((state: State) => {
    var match = extractTransactionGuid(state.router.location.pathname);
    return {
      transactionGuid: match ? match.transactionGuid : null,
      isAuto: match ? match.isAuto : null
    };
  }, []);
  const { transactionGuid, isAuto } = useMappedState(mapStateToProps);
  return { transactionGuid, isAuto };
};
