import React from "react";
import {
  IconButton,
  Grid,
  makeStyles,
  Theme,
  createStyles
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { CloseIcon } from "../../assets/icons";
import { fancyHeader } from "../../assets/jss";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: "grow",
      paddingLeft: theme.spacing(2),
      color: theme.palette.primary.contrastText,
      height: 57,
      ...fancyHeader
    },
    header: {
      fontFamily: "Cairo",
      fontSize: 20
    },
    image: {
      width: 80
    }
  })
);
export function DetailsHeader<T>(props: {
  data: T;
  title: (data: T) => React.ReactNode;
  tableRoute: string;
}) {
  const classes = useStyles({});
  const { data, title, tableRoute } = props;
  return (
    <div className={classes.root}>
      <Grid container justify="space-between">
        {title(data)}
        <Link to={tableRoute}>
          <IconButton>
            <CloseIcon />
          </IconButton>
        </Link>
      </Grid>
    </div>
  );
}
