import React from "react";
import { nations } from "./nations";
import { AssetPicker } from "./AssetPicker";

export function getAnyNationImage(): string {
  return `url(https://www.easports.com/fifa/ultimate-team/web-app/content/7D49A6B1-760B-4491-B10C-167FBC81D58A/2019/fut/items/images/mobile/flags/list/default.png)`;
}

export function getNationImage(nation: number): string {
  return `url(https://www.easports.com/fifa/ultimate-team/web-app/content/7D49A6B1-760B-4491-B10C-167FBC81D58A/2019/fut/items/images/mobile/flags/list/${nation}.png)`;
}

export function NationPicker(props: {
  initialValue?: number;
  onChange?: (value: number) => void;
  className?: string;
}) {
  const {} = props;
  return (
    <AssetPicker
      className={props.className}
      initialValue={props.initialValue}
      onChange={props.onChange}
      anyLabel={"Any Nation"}
      assetLabel={"Nation"}
      getImgLink={getNationImage}
      getAnyImgLink={getAnyNationImage}
      source={nations}
    />
  );
}
