import React from "react";
import "./style.scss";

export function CustomProgress(props: {}) {
  const {} = props;
  return (
    <div className="spinner">
      <div className="bounce1" />
      <div className="bounce2" />
      <div className="bounce3" />
    </div>
  );
}
