import React, { useState, useEffect } from "react";
import {
  Theme,
  LinearProgress,
  Checkbox,
  Paper,
  Tooltip,
  IconButton
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useRuntimeApi } from "../../../../api";
import {
  ProviderTransactionsApi,
  CardToUseInOrderViewModel
} from "@futbot/runtime-api";
import { useSnackbar } from "@creatdevsolutions/notistack";
import {
  InteractiveTable,
  ToolbarAction,
  ToolbarActionOnSelected,
  ColumnDefinition
} from "../../../../components/interactiveTable";
import { PlayerInfo } from "../../../../components/playerInfo";
import { Coins } from "../../../../components/coins";
import {
  AddIcon,
  OpenLockIcon,
  ClosedLockIcon,
  ExternalLinkIcon,
  DeleteIcon,
  EditIcon
} from "../../../../assets/icons";
import { useTranslation } from "react-i18next";
import { useLoading } from "../../../../hooks/useLoading";
import { TransactionCardModal } from "./transactionCardModal";
import { useActionOnItems } from "../../../../hooks/useActionOnItems";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: "grow"
    }
  })
);

export function TransactionCardsPage(props: {}) {
  const classes = useStyles({});
  const api = useRuntimeApi(ProviderTransactionsApi);
  const { t } = useTranslation();
  const { isLoading, setIsLoading } = useLoading();
  const [reloadCount, setReloadCount] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [card, setCard] = useState<CardToUseInOrderViewModel>(null);
  const [action, setAction] = useState<"add" | "update">("add");
  const [transactionCards, setTransactionCards] = useState<
    CardToUseInOrderViewModel[]
  >([]);
  const { enqueueSnackbar } = useSnackbar();
  const { actionOnSelected, bulkActionLoading } = useActionOnItems();
  const {} = props;

  const actions: ToolbarAction[] = [
    {
      action: () => {
        setCard({});
        setAction("add");
        setShowModal(true);
      },
      label: "Add",
      icon: AddIcon,
      variant: "outlined"
    }
  ];

  const actionsOnSelected: ToolbarActionOnSelected[] = [
    {
      action: selected => {
        console.log("Clicked for", selected);
      },
      label: "Test",
      variant: "outlined"
    }
  ];

  const columns: ColumnDefinition<CardToUseInOrderViewModel>[] = [
    {
      id: "playerCard",
      label: "Player Card",
      width: "30%",
      value: t => t.playerCard.id.toString(),
      render: t => <PlayerInfo {...t.playerCard} />
    },
    {
      id: "suggestedBuyPrice",
      label: "Suggested Price",
      width: "20%",
      value: t => t.suggestedBuyPrice,
      render: t => {
        return t.suggestedBuyPrice ? (
          <Coins amount={t.suggestedBuyPrice} />
        ) : (
          "Auto"
        );
      }
    },
    {
      id: "buyNowPrice",
      label: "Buy Now Price",
      width: "20%",
      value: t => t.maxBuyNow,
      render: t => {
        return t.maxBuyNow ? <Coins amount={t.maxBuyNow} /> : "Auto";
      }
    },
    {
      id: "discard",
      label: "Discard",
      width: "15%",
      align: "right",
      numeric: true,
      value: t => t.discard,
      render: c => <span>{c.discard ? t("misc.yes") : t("misc.no")}</span>
    },
    {
      id: "actions",
      label: "",
      width: "10%",
      align: "right",
      value: t => t.playerCard.id,
      render: c => (
        <React.Fragment>
          <Tooltip title={t("misc.update")}>
            <IconButton
              size="small"
              onClick={() => {
                setAction("update");
                setCard(c);
                setShowModal(true);
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("misc.delete")}>
            <IconButton
              color="secondary"
              size="small"
              onClick={async () => {
                await deleteSelectedCards([c.playerCard.id.toString()]);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </React.Fragment>
      )
    }
  ];

  async function deleteSelectedCards(selected: string[]) {
    await actionOnSelected(
      selected,
      selected.length == 1 ? null : `Removing ${selected.length} Cards...`,
      selected.length == 1
        ? `"${selected[0]}" Card Removed`
        : `${selected.length} Cards Removed`,
      async id => {
        await api.deleteTransactionCard({
          cardToUse: transactionCards.find(
            c => c.playerCard.id.toString() == id
          )
        });
      }
    );
    setReloadCount(reloadCount + 1);
  }

  useEffect(() => {
    setIsLoading(true);
    api
      .getTransactionCards()
      .then(cards => {
        setTransactionCards(cards);
      })
      .catch(reason => {
        console.log(reason);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [reloadCount]);

  return (
    <Paper className={classes.root}>
      <TransactionCardModal
        card={card}
        action={action}
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        onSubmit={async ppc => {
          if (action == "add") {
            await api.addTransactionCard({ cardToUse: ppc });
          } else if (action == "update") {
            await api.updateTransactionCard({ cardToUse: ppc });
          }
          enqueueSnackbar(
            `"${ppc.playerCard.name}" card ${
              action == "add" ? "Added" : "Updated"
            }`,
            { variant: "success" }
          );
          setShowModal(false);
          setReloadCount(reloadCount + 1);
        }}
      />
      <InteractiveTable<CardToUseInOrderViewModel>
        data={transactionCards}
        columns={columns}
        getKey={t => t.playerCard.id.toString()}
        actions={actions}
        actionsOnSelected={actionsOnSelected}
      />
    </Paper>
  );
}
