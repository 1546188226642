export const leagues = [
  { value: 13, label: "Premier League (ENG 1)" },
  { value: 14, label: "EFL Championship (ENG 2)" },
  { value: 60, label: "EFL League One (ENG 3)" },
  { value: 61, label: "EFL League Two (ENG 4)" },
  { value: 16, label: "Ligue 1 Conforama (FRA 1)" },
  { value: 17, label: "Domino’s Ligue 2 (FRA 2)" },
  { value: 31, label: "Serie A TIM (ITA 1)" },
  { value: 32, label: "Calcio B (ITA 2)" },
  { value: 19, label: "Bundesliga (GER 1)" },
  { value: 20, label: "Bundesliga 2 (GER 2)" },
  { value: 2076, label: "3. Liga (GER 3)" },
  { value: 53, label: "LaLiga Santander (ESP 1)" },
  { value: 54, label: "LaLiga 1 I 2 I 3 (ESP 2)" },
  { value: 56, label: "Allsvenskan (SWE 1)" },
  { value: 335, label: "Camp. Scotiabank (CHI 1)" },
  { value: 2012, label: "CSL (CHN 1)" },
  { value: 66, label: "Ekstraklasa (POL 1)" },
  { value: 41, label: "Eliteserien (NOR 1)" },
  { value: 10, label: "Eredivisie (NED 1)" },
  { value: 322, label: "Finnliiga (FIN 1)" },
  { value: 63, label: "Hellas Liga (GRE 1)" },
  { value: 351, label: "Hyundai A-League (AUS 1)" },
  { value: 2118, label: "Icons (ICN)" },
  { value: 83, label: "K-League 1 (KOR 1)" },
  { value: 3006, label: "League of Russia (RUS 1)" },
  { value: 341, label: "LIGA Bancomer MX (MEX 1)" },
  { value: 336, label: "Liga Dimayor (COL 1)" },
  { value: 7, label: "Liga do Brasil (BRA 1)" },
  { value: 317, label: "Liga Hrvatska (CRO 1)" },
  { value: 308, label: "Liga NOS (POR 1)" },
  { value: 349, label: "Meiji Yasuda J1 (JPN 1)" },
  { value: 78, label: "Men's National (INT)" },
  { value: 39, label: "MLS (MLS)" },
  { value: 4, label: "Pro League (BEL 1)" },
  { value: 2150, label: "REWARDS (FUT)" },
  { value: 189, label: "RSL (SUI 1)" },
  { value: 353, label: "SAF (ARG 1)" },
  { value: 350, label: "Saudi Professional League (SAU 1)" },
  { value: 50, label: "Scottish Prem (SPFL)" },
  { value: 347, label: "South African FL (RSA 1)" },
  { value: 65, label: "SSE Airtricity Lge (IRL 1)" },
  { value: 68, label: "Süper Lig (TUR 1)" },
  { value: 1, label: "Superliga (DEN 1)" },
  { value: 332, label: "Ukrayina Liha (UKR 1)" },
  { value: 80, label: "Ö. Bundesliga (AUT 1)" },
  { value: 319, label: "Česká Liga (CZE 1)" }
];
