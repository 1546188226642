import React from "react";
import { Theme } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({}));

export function OrderState(props: { state: number }) {
  const classes = useStyles({});
  const { state } = props;
  var desc = "n.a";
  var color = "#000";
  switch (state) {
    case 0:
      color = "#ff9800";
      desc = "Pending...";
      break;
    case 1:
      color = "#3f51b5";
      desc = "Handled...";
      break;
    case 2:
      color = "#4caf50";
      desc = "Completed";
      break;
    case 3:
      color = "#b01c57";
      desc = "Ambiguous";
      break;
    case 4:
      color = "#bab4b6";
      desc = "Card Not Found";
      break;
    case 5:
      color = "#f44336";
      desc = "Unknown Error : 1";
      break;
    case 6:
      color = "#f44336";
      desc = "Unknown Error : 2";
      break;
    case 7:
      color = "#f44336";
      desc = "Unknown Error : 3";
      break;
  }
  return <span style={{ color: color }}>{desc}</span>;
}
