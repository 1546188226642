import React from "react";
import { RouteInfo } from "../../../../utils/routeUtils";
import { OverviewIcon, LogsIcon, PileIcon } from "../../../../assets/icons";
import { ExternalApisOverview } from "./components/externalApisOverview";
import { ExternalApisOrders } from "./components/externalApisOrders";
import { ExternalApisLogs } from "./components/externalApisLogs";

export const routes: RouteInfo[] = [
  {
    path: "",
    exact: true,
    name: "Overview",
    icon: OverviewIcon,
    component: () => <ExternalApisOverview />
  },
  {
    path: "/pending",
    name: "Orders",
    icon: PileIcon,
    component: () => <ExternalApisOrders />
  },
  {
    path: "/logs",
    name: "Logs",
    icon: LogsIcon,
    component: () => <ExternalApisLogs />
  }
];
