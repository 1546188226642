import React, { useEffect, useState } from "react";
import { Theme } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import {
  platforms,
  PlateformPicker
} from "../../../../../../components/plateformPicker";
import { InputCoins } from "../../../../../../components/inputCoins";
import {
  TransactionViewModel,
  ProviderTransactionsApi,
  TransactionReviewViewModel
} from "@futbot/runtime-api";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flexWrap: "wrap",
      height: "calc(100vh - 250px)",
      overflow: "auto"
    },
    textField: {
      margin: 8,
      minWidth: theme.spacing(40)
    }
  })
);

export function InputTransaction(props: {
  transaction: TransactionViewModel;
  onTransactionUpdate: (transaction: TransactionViewModel) => void;
}) {
  const classes = useStyles({});
  const { t, i18n } = useTranslation();
  const { transaction, onTransactionUpdate } = props;
  const [tsx, setTsx] = useState<TransactionViewModel>(transaction);

  useEffect(() => {
    setTsx(transaction);
  }, [props]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    const newTsx = { ...tsx, [name]: value };
    onTransactionUpdate(newTsx);
    setTsx(newTsx);
  };

  const handleValueChange = (name: string, value: any) => {
    const newTsx = { ...tsx, [name]: value };
    onTransactionUpdate(newTsx);
    setTsx(newTsx);
  };

  const handleInputCheck = (e: any) => {
    const { name, checked } = e.target;
    const newTsx = { ...tsx, [name]: checked };
    onTransactionUpdate(newTsx);
    setTsx(newTsx);
  };

  return (
    <React.Fragment>
      <PlateformPicker
        label="Platform"
        className={classes.textField}
        initialValue={transaction.platform}
        onChange={p => {
          handleValueChange("platform", p);
        }}
      />
      <InputCoins
        label="Amount"
        placeholder="Auto"
        className={classes.textField}
        initialValue={transaction.totalAmount}
        onChanged={value => {
          handleValueChange("totalAmount", value);
        }}
      />
    </React.Fragment>
  );
}
