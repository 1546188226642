import React from "react";
import { RouteInfo } from "../../../../utils/routeUtils";
import { TransactionViewModel } from "@futbot/runtime-api";
import { OrdersHistory } from "../../../../components/ordersHistory";
import { OverviewIcon, SettingsIcon } from "../../../../assets/icons";

const buildRoutes = (transaction: TransactionViewModel): RouteInfo[] => {
  return [
    {
      path: "/overview",
      name: "transactions.menu.overview",
      icon: OverviewIcon,
      component: () => <div>Transaction: {transaction.guid}</div>
    },
    {
      path: "/settings",
      name: "transactions.menu.orders",
      icon: SettingsIcon,
      component: () => <OrdersHistory orders={transaction.orders} />
    }
  ];
};

export default buildRoutes;
