import React, { useState, useEffect, useCallback } from "react";
import {
  BotViewModel,
  BotManagerApi,
  SimpleBotViewModel
} from "@futbot/runtime-api";
import { BotsTable } from "./components/botTable";
import { BotList } from "./components/botList/BotList";
import {
  Paper,
  withStyles,
  Grid,
  Theme,
  LinearProgress
} from "@material-ui/core";
import { useRuntimeApi } from "../../../../api";
import { useBotGuid } from ".";
import { BotDetails } from "./components/botDetails/BotDetails";
import { makeStyles, createStyles } from "@material-ui/styles";
import { BotCreationModal } from "./components/botCreationModal/BotCreationModal";
import { useSnackbar } from "@creatdevsolutions/notistack";
import { MasterDetails } from "../../../../components/masterDetails";
import { useActionOnItems } from "../../../../hooks/useActionOnItems";
import { useTranslation } from "react-i18next";
import { useLoading } from "../../../../hooks/useLoading";
import { State } from "../../../../store/config/configureStore";
import { useMappedState } from "redux-react-hook";
import { ConfirmationModal } from "./components/confirmationModal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: "grow"
    }
  })
);

export function BotPage() {
  const classes = useStyles({});
  const [bots, setBots] = useState<SimpleBotViewModel[]>([]);
  const { isLoading, setIsLoading } = useLoading();
  const [reload, setReload] = useState(0);
  const [creationModalOpen, setCreationModalOpen] = useState<boolean>(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(
    false
  );
  const [botsToDelete, setBotsToDelete] = useState<string[]>([]);
  const { botGuid } = useBotGuid();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useRuntimeApi(BotManagerApi);
  const {
    actionOnSelected,
    reloadCount,
    bulkActionLoading
  } = useActionOnItems();

  useEffect(() => {
    setIsLoading(true);
    api
      .getBots()
      .then(bots => {
        setBots(bots);
      })
      .catch(reason => {
        enqueueSnackbar(t("misc.errorOccured", { reason: reason }));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [reload]);

  const mapStateToProps = useCallback(
    (state: State) => ({
      notification: state.notifications.all
    }),
    []
  );
  const { notification } = useMappedState(mapStateToProps);
  useEffect(() => {
    if (!notification || !notification.be) {
      return;
    }
    var changedBot = bots.findIndex(b => b.id == notification.b);
    var newBots = bots;
    newBots[changedBot] = notification.be;
    setBots(newBots);
  }, [notification]);

  return (
    <React.Fragment>
      <BotCreationModal
        open={creationModalOpen}
        onSubmit={settings => {
          api
            .createNewBot({ settings: settings })
            .then(() => {
              enqueueSnackbar(t("bots.labels.botAddedSuccessfully"), {
                variant: "success"
              });
              setCreationModalOpen(false);
              setReload(reload + 1);
            })
            .catch(async r => {
              enqueueSnackbar(
                t("bots.labels.botAddedFailure", {
                  reason: r ? await r.text() : t("misc.unknownError")
                }),
                { variant: "error" }
              );
            });
        }}
        onClose={() => {
          setCreationModalOpen(false);
        }}
      />
      <ConfirmationModal
        title={t("bots.labels.botDeletionConfirm", {
          count: botsToDelete.length
        })}
        explanation={t("bots.labels.botDeletionExplanation")}
        open={confirmationModalOpen}
        onConfirm={() => {
          setConfirmationModalOpen(false);
          deleteSelectedBots(botsToDelete).then(() => {
            setBotsToDelete([]);
            setReload(reload + 1);
          });
        }}
        onDismiss={() => {
          setBotsToDelete([]);
          setConfirmationModalOpen(false);
        }}
      />
      <MasterDetails
        selectedElement={botGuid}
        listComponent={<BotList bots={bots} />}
        detailsComponent={<BotDetails />}
        tableComponent={
          <BotsTable
            isLoading={isLoading || bulkActionLoading}
            isReadOnly={isLoading || bulkActionLoading}
            bots={bots}
            onCreationRequest={() => {
              setCreationModalOpen(true);
            }}
            onStartRequest={startSelectedBots}
            onDeleteRequest={async botIds => {
              setBotsToDelete(botIds);
              setConfirmationModalOpen(true);
            }}
            onStopRequest={stopSelectedBots}
            onRefresh={() => setReload(reload + 1)}
          />
        }
      />
    </React.Fragment>
  );

  async function stopSelectedBots(bots: string[]) {
    await actionOnSelected(
      bots,
      bots.length == 1
        ? null
        : t("bots.labels.stoppingBots", {
            count: bots.length
          }),
      bots.length == 1
        ? `${bots[0]} ${t("bots.labels.stopped")}`
        : t("bots.labels.stoppedBots", {
            count: bots.length
          }),
      async botId => {
        await api.stopBot({ botId });
      }
    );
  }

  async function deleteSelectedBots(selected: string[]) {
    await actionOnSelected(
      selected,
      selected.length == 1
        ? null
        : t("bots.labels.removingBots", {
            count: selected.length
          }),
      selected.length == 1
        ? `${selected[0]} ${t("bots.labels.removed")}`
        : t("bots.labels.removedBots", {
            count: selected.length
          }),
      async botId => {
        await api.removeBot({ botId });
      }
    );
  }

  async function startSelectedBots(selected: string[]) {
    await actionOnSelected(
      selected,
      selected.length == 1
        ? null
        : t("bots.labels.startingBots", {
            count: selected.length
          }),
      selected.length == 1
        ? `${selected[0]} ${t("bots.labels.started")}`
        : t("bots.labels.startedBots", {
            count: selected.length
          }),
      async botId => {
        await api.startBot({ botId });
      }
    );
  }
}
