import React from "react";
import { Grid, CssBaseline } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { GridItem } from "../../../../components/grid";
import { useStatApi, useRuntimeApi } from "../../../../api";
import { AdministrationApi } from "@futbot/runtime-api";
import { useTranslation } from "react-i18next";
import { SingleValueCard } from "../../../manager/pages/dashboard/components/singleValueCard";
import Moment from "moment";
import {
  ProfitsIcon,
  SalesMadeIcon,
  DealsMadeIcon,
  OctofutiumsIcon
} from "../../../../assets/icons";

const dateId = Moment(new Date()).format("YYYYMMDD");

export function DashboardPage(props: {}) {
  const { t } = useTranslation();
  const api = useRuntimeApi(AdministrationApi);
  const {} = props;
  return (
    <React.Fragment>
      <CssBaseline />
      <Grid container>
        <GridItem sm={12} md={6} lg={3}>
          <SingleValueCard
            title={t("Bots Count")}
            color="success"
            fetch={async () => {
              return (await api.getBotsCount()).count;
            }}
          />
        </GridItem>
        <GridItem sm={12} md={6} lg={3}>
          <SingleValueCard
            title={t("Valid Bots Count")}
            color="success"
            fetch={async () => {
              return (await api.getValidBotsCount()).count;
            }}
          />
        </GridItem>
        <GridItem sm={12} md={6} lg={3}>
          <SingleValueCard
            title={t("Running Bots Count")}
            color="warning"
            fetch={async () => {
              return (await api.getRunningBots({})).count;
            }}
          />
        </GridItem>
      </Grid>
      <Grid container>
        <GridItem sm={12} md={6} lg={3}>
          <SingleValueCard
            title={t("Accounts Count")}
            color="info"
            fetch={async () => {
              return (await api.getAccountsCount()).count;
            }}
          />
        </GridItem>
      </Grid>
      <Grid container>
        <GridItem sm={12} md={6} lg={3}>
          <SingleValueCard
            title={t("Purchases Count")}
            color="danger"
            fetch={async () => {
              return (await api.getPurshasesCount()).count;
            }}
          />
        </GridItem>
      </Grid>
    </React.Fragment>
  );
}
