import React, { useState, useEffect } from "react";
import { ShopListViewModel, ShopListsApi } from "@futbot/stats-api";
import { ShopListsList } from "./components/shopListsList/ShopListsList";
import { Paper, Grid, Theme, LinearProgress } from "@material-ui/core";
import { useStatApi } from "../../../../api";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useSnackbar } from "@creatdevsolutions/notistack";
import { ShopListDetails } from "./components/shopListDetails";
import { useGuid } from ".";
import { ShopListsTable } from "./components/shopListsTable";
import { MasterDetails } from "../../../../components/masterDetails";
import { useLoading } from "../../../../hooks/useLoading";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: "grow"
    }
  })
);

export function ShopListsPage() {
  const classes = useStyles({});
  const [shoplists, setShopLists] = useState<ShopListViewModel[]>([]);
  const { isLoading, setIsLoading } = useLoading();
  const [reloadCount, setReloadCount] = useState<number>(0);
  const { enqueueSnackbar } = useSnackbar();
  const { guid } = useGuid();
  const api = useStatApi(ShopListsApi);

  useEffect(() => {
    setIsLoading(true);
    api
      .getShopLists()
      .then(shopLists => {
        setShopLists(shopLists);
      })
      .catch(reason => {
        console.log(reason);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [reloadCount]);

  return (
    <MasterDetails
      listComponent={<ShopListsList shopLists={shoplists} />}
      detailsComponent={<ShopListDetails />}
      tableComponent={
        <ShopListsTable
          isLoading={isLoading}
          shoplists={shoplists}
          onRefresh={() => setReloadCount(reloadCount + 1)}
        />
      }
      selectedElement={guid}
    />
  );
}
