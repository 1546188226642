import React from "react";
import { ListItem } from "@material-ui/core";
import { Link } from "react-router-dom";

export function LinkListItem(props: { to: string; children: React.ReactNode }) {
  const { to, children } = props;
  return (
    <ListItem {...{ component: Link, to } as any} button>
      {children}
    </ListItem>
  );
}
