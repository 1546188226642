import React, { useEffect, useState } from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useGuid } from "../..";
import { ShopListEditor } from "./ShopListEditor";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
      height: "calc(100vh - 82px)"
    }
  })
);

export function ShopListDetails() {
  const { guid } = useGuid();
  return <ShopListEditor guid={guid} />;
}
