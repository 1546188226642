import { useCallback } from "react";
import { State } from "../store/config/configureStore";
import { useMappedState } from "redux-react-hook";
import {
  BaseAPI as RuntimeApi,
  Configuration as RuntimeConfiguration
} from "@futbot/runtime-api";
import {
  BaseAPI as StatsApi,
  Configuration as StatsConfiguration
} from "@futbot/stats-api";

export function useRuntimeApi<T extends RuntimeApi>(
  c: new (configuration?: RuntimeConfiguration, basePath?: string) => T
) {
  const mapStateToProps = useCallback((state: State) => {
    if (!state.account.loginResponse) {
      return {};
    }
    return {
      apiKey: `${state.account.loginResponse.tokenType} ${state.account.loginResponse.accessToken}`,
      url: state.account.loginResponse.serverInfo.runtimeUrl
    };
  }, []);
  const { apiKey, url } = useMappedState(mapStateToProps);
  const config = new RuntimeConfiguration({ apiKey, basePath: url });
  const api = new c(config);
  return api;
}

export function useStatApi<T extends StatsApi>(
  c: new (configuration?: StatsConfiguration, basePath?: string) => T
) {
  const mapStateToProps = useCallback((state: State) => {
    if (!state.account.loginResponse) {
      return {};
    }
    return {
      apiKey: `${state.account.loginResponse.tokenType} ${state.account.loginResponse.accessToken}`,
      url: state.account.loginResponse.serverInfo.statsUrl
    };
  }, []);
  const { apiKey, url } = useMappedState(mapStateToProps);
  const config = new StatsConfiguration({ apiKey, basePath: url });
  const api = new c(config);
  return api;
}
