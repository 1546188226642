import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Theme, Toolbar, InputBase, Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { fade } from "@material-ui/core/styles";
import { SearchIcon } from "../../assets/icons";
import RefreshIcon from "@material-ui/icons/Refresh";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
    spacer: {
      flexGrow: 1,
    },
    leftIcon: {
      marginRight: 8,
    },
    rightIcon: {
      marginLeft: 8,
    },
    iconSmall: {
      fontSize: 20,
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
    },
    searchIcon: {
      width: theme.spacing(7),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: 120,
        "&:focus": {
          width: 200,
        },
      },
    },
  })
);

export function LogViewerToolbar(props: {
  onCriteriaChanged: (search: string) => void;
  onRefreshClicked: () => void;
}) {
  const { t } = useTranslation();
  const classes = useStyles({});
  const [search, setSearch] = useState<string>("");
  const { onCriteriaChanged, onRefreshClicked } = props;
  useEffect(() => {
    onCriteriaChanged(search);
  }, [search]);
  const {} = props;
  return (
    <Toolbar className={classes.root} variant="dense">
      <Button
        color="inherit"
        variant="outlined"
        size="small"
        onClick={() => {
          onRefreshClicked();
        }}
      >
        <RefreshIcon
          className={classNames(classes.leftIcon, classes.iconSmall)}
        />
        {t("misc.refresh")}
      </Button>
      <div className={classes.spacer} />
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Search…"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ "aria-label": "Search" }}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
    </Toolbar>
  );
}
