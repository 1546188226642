import React, { useState, useEffect } from "react";
import { Grid, Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { PlateformPicker } from "../plateformPicker";
import { PlayerCardAutoComplete } from "../playerCardAutoComplete";
import { PlayerCardViewModel } from "@futbot/stats-api";
import { PlayerInfo } from "../playerInfo";
import CloseIcon from "@material-ui/icons/Close";
import playerCardBackground from "../../../../../../assets/images/backgrounds/5.jpg";

const useStyles = makeStyles(() => ({
  root: {
    background: "#fff"
  },
  field: {
    padding: 10
  },
  fullWidth: {
    width: "100%"
  },
  closeIcon: {
    color: "red",
    marginLeft: 10
  },
  suggestion: {
    paddingLeft: 10,
    paddingTop: 2
  }
}));

export function PlayerPicker(props: {
  onSelected: (platformId: number, cardId: number) => any;
  initialCard?: PlayerCardViewModel;
  initialPlatformId?: number;
}) {
  const classes = useStyles({});
  const { initialCard, initialPlatformId, onSelected } = props;
  const [cardToSearch, setCardToSearch] = useState<PlayerCardViewModel>(
    initialCard
  );
  const [selectedPlatform, setSelectedPlatform] = useState<number>(
    initialPlatformId
  );
  const [selectedCard, setSelectedCard] = useState<number>(
    initialCard ? initialCard.id : null
  );

  useEffect(() => {
    if (!initialCard || !initialPlatformId) {
      return;
    }
    setSelectedCard(initialCard.id);
    setSelectedPlatform(initialPlatformId);
  }, [props]);

  return (
    <Grid container className={classes.root}>
      {cardToSearch ? (
        <Grid xs={6} item className={classes.suggestion}>
          <Button
            fullWidth
            onClick={() => {
              setCardToSearch(null);
            }}
          >
            <React.Fragment>
              <PlayerInfo {...cardToSearch} />
              <CloseIcon className={classes.closeIcon} />
            </React.Fragment>
          </Button>
        </Grid>
      ) : (
        <Grid xs={6} item className={classes.field}>
          <PlayerCardAutoComplete
            onSelect={c => {
              setSelectedCard(c.id);
              setCardToSearch(c);
            }}
          />
        </Grid>
      )}
      <Grid xs={4} item className={classes.field}>
        <PlateformPicker
          className={classes.fullWidth}
          initialValue={selectedPlatform}
          onChange={p => {
            setSelectedPlatform(parseInt(p));
          }}
        />
      </Grid>
      <Grid xs={2} item className={classes.field}>
        <Button
          fullWidth
          disabled={!cardToSearch}
          variant="contained"
          color="secondary"
          onClick={() => {
            if (selectedCard && selectedPlatform) {
              onSelected(selectedPlatform, selectedCard);
            }
          }}
        >
          GO
        </Button>
      </Grid>
    </Grid>
  );
}
