import React, { useState, useEffect, useCallback } from "react";
import {
  Theme,
  withStyles,
  Checkbox,
  Button,
  LinearProgress,
  Toolbar,
  Tooltip
} from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import { useBotGuid } from "../..";
import {
  BotViewModel,
  BotManagerApi,
  BotSettingsViewModel
} from "@futbot/runtime-api";
import { useRuntimeApi } from "../../../../../../api";
import classNames from "classnames";
import { InputBotSettings } from "./InputBotSettings";
import { useDispatch, useMappedState } from "redux-react-hook";
import { State } from "../../../../../../store/config/configureStore";
import { toggleHelp } from "../../../../../../store/actions/misc";
import {
  SaveIcon,
  ResetIcon,
  UncheckedHelpIcon,
  CheckedHelpIcon
} from "../../../../../../assets/icons";
import { CheckboxProps } from "@material-ui/core/Checkbox";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "@creatdevsolutions/notistack";

const WhiteCheckbox = withStyles({
  root: {
    color: "#eee",
    "&$checked": {
      color: "#fff"
    }
  },
  checked: {}
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const style = (theme: Theme): StyleRules => ({
  root: {
    width: "100%"
  },
  container: {
    flexWrap: "wrap",
    height: "calc(100vh - 250px)",
    overflow: "auto"
  },
  toolbar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  button: {
    width: 100,
    marginRight: 8
  },
  leftIcon: {
    marginRight: 8
  },
  rightIcon: {
    marginLeft: 8
  },
  iconSmall: {
    fontSize: 20
  },
  textField: {
    margin: 8,
    minWidth: theme.spacing(40)
  },
  marginLess: {
    margin: 0
  }
});

export function BotSettingsRaw(props: { classes: any }) {
  const { classes } = props;
  const { botGuid } = useBotGuid();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const [bot, setBot] = useState<BotViewModel>(null);
  const [reloadCount, setReloadCount] = useState<number>(0);
  const [botSettings, setBotSettings] = useState<BotSettingsViewModel>(null);
  const [initialBotSettings, setInitialBotSettings] = useState<string>(null);
  const api = useRuntimeApi(BotManagerApi);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    setIsLoading(true);
    api
      .getBot({ botId: botGuid })
      .then(b => {
        setBot(b);
        setBotSettings(b.settings);
        setInitialBotSettings(JSON.stringify(b.settings));
      })
      .catch()
      .then(() => {
        setIsLoading(false);
      });
  }, [botGuid, reloadCount]);

  const dispatch = useDispatch();
  const mapStateToProps = useCallback(
    (state: State) => ({
      showHelp: state.misc.showHelp
    }),
    []
  );
  const { showHelp } = useMappedState(mapStateToProps);

  if (isLoading || !bot || !botSettings) {
    return <LinearProgress />;
  }
  return (
    <div className={classes.root}>
      <Toolbar className={classes.toolbar} variant="dense" color="secondary">
        <Button
          color="inherit"
          variant="outlined"
          size="small"
          className={classes.button}
          onClick={() => {
            setIsLoading(true);
            api
              .updateBotSettings({ settings: botSettings })
              .then(() => {
                enqueueSnackbar(t("bots.labels.botUpdated"), {
                  variant: "success"
                });
                setReloadCount(reloadCount + 1);
              })
              .catch(() => {
                enqueueSnackbar(t("bots.labels.botUpdateFailed"), {
                  variant: "error"
                });
              })
              .then(() => {
                setIsLoading(false);
              });
          }}
          disabled={initialBotSettings === JSON.stringify(botSettings)}
        >
          <SaveIcon
            className={classNames(classes.leftIcon, classes.iconSmall)}
          />
          {t("misc.save")}
        </Button>
        <Button
          color="inherit"
          variant="outlined"
          size="small"
          className={classes.button}
          onClick={() => {
            setBotSettings(JSON.parse(initialBotSettings));
          }}
          disabled={initialBotSettings === JSON.stringify(botSettings)}
        >
          <ResetIcon
            className={classNames(classes.leftIcon, classes.iconSmall)}
          />
          {t("misc.discard")}
        </Button>

        <Tooltip title={t("bots.labels.showHideHelp")} placement="right">
          <WhiteCheckbox
            icon={<UncheckedHelpIcon />}
            checkedIcon={<CheckedHelpIcon />}
            checked={showHelp}
            onChange={(e, c) => {
              dispatch(toggleHelp());
            }}
          />
        </Tooltip>
      </Toolbar>
      <InputBotSettings
        settings={botSettings}
        showHelp={showHelp}
        onSettingsUpdate={settings => {
          setBotSettings(settings);
        }}
      />
    </div>
  );
}

export const BotSettings = withStyles(style)(BotSettingsRaw);
