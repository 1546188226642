import React from "react";
import { Theme } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { RankingCard } from "../rankingCard";
import { SaleViewModel, StatisticsApi } from "@futbot/stats-api";
import { TopDealRankingRow } from "./topDealRankingRow";
import { EmptyRankingRow } from "../emptyRankingRow";
import { useStatApi } from "../../../../../../api";
import Moment from "moment";

const useStyles = makeStyles((theme: Theme) => createStyles({}));
const todayId = Moment(new Date()).format("YYYYMMDD");

export function TopDealRanking(props: {
  botId?: string;
  dateId?: string;
  count?: number;
}) {
  const classes = useStyles({});
  const { t, i18n } = useTranslation();
  const statisticsApi = useStatApi(StatisticsApi);
  const { botId, dateId, count } = props;
  return (
    <RankingCard<SaleViewModel>
      count={count}
      title={t(
        dateId === todayId
          ? "dashboard.labels.todaysTopXDeals"
          : "dashboard.labels.topXdeals"
      )}
      fetch={async (count: number) => {
        return await statisticsApi.dealsRanking({
          count: count,
          botId: botId,
          dateId: dateId
        });
      }}
      renderElement={deal => <TopDealRankingRow deal={deal} />}
      renderEmptyElement={() => <EmptyRankingRow />}
    />
  );
}
