import React, { useCallback, Children } from "react";
import {
  Theme,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  Paper
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useDispatch, useMappedState } from "redux-react-hook";
import { State } from "../../store/config/configureStore";
import { RightIcon, LeftIcon } from "../../assets/icons";
import routes from "../../modules/manager/pages/bots/routes";
import { LinkListItem } from "../linkListItem";
import { toggleSubMenu } from "../../store/actions/misc";
import { RouteInfo } from "../../utils/routeUtils";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {},
    menuItemText: {
      textTransform: "uppercase",
      fontFamily: "Cairo",
      fontSize: 15,
      fontWeight: 600,
      color: "#6d6d6d"
    },
    menu: {
      width: "100%",
      height: "calc(100vh - 145px)",
      overflow: "auto",
      overflowX: "hidden"
    },
    menuWrapper: (props: any) => ({
      background: "#ededed",
      width: props.width
    }),
    content: {
      width: "100%",
      height: "calc(100vh - 145px)",
      overflow: "auto",
      position: "relative"
    },
    contentWrapper: (props: any) => ({
      width: `calc(100% - ${props.width}px)`
    })
  })
);

export function DetailsMenu(props: {
  basePath: string;
  routes: RouteInfo[];
  children: React.ReactNode;
}) {
  const dispatch = useDispatch();
  const mapStateToProps = useCallback(
    (state: State) => ({
      shrinkedSubMenu: state.misc.shrinkedSubMenu
    }),
    []
  );
  const { shrinkedSubMenu } = useMappedState(mapStateToProps);
  const { t, i18n } = useTranslation();
  const classes = useStyles({ width: shrinkedSubMenu ? 64 : 200 });
  const { basePath, routes, children } = props;
  return (
    <Grid container>
      <Grid item className={classes.menuWrapper}>
        <div className={classes.menu}>
          <List>
            <ListItem>
              <ListItemIcon
                className={classes.menuItem}
                onClick={() => {
                  dispatch(toggleSubMenu());
                }}
              >
                {shrinkedSubMenu ? <RightIcon /> : <LeftIcon />}
              </ListItemIcon>
            </ListItem>
            {routes.map((route, index) => (
              <LinkListItem to={basePath + route.path} key={index}>
                <ListItemIcon className={classes.menuItem}>
                  <route.icon />
                </ListItemIcon>
                {!shrinkedSubMenu && (
                  <ListItemText
                    className={classes.menuItemText}
                    disableTypography
                    primary={t(route.name)}
                  />
                )}
              </LinkListItem>
            ))}
          </List>
        </div>
      </Grid>
      <Grid item className={classes.contentWrapper}>
        <Paper className={classes.content}>{children}</Paper>
      </Grid>
    </Grid>
  );
}
