import React, { useState, useEffect } from "react";
import {
  Paper,
  LinearProgress,
  Grid,
  makeStyles,
  Theme,
  createStyles
} from "@material-ui/core";
import { switchRoutes } from "../../../../../../utils/routeUtils";
import routes from "../../routes";
import { pagePath, useBotGuid, useBotBasePath } from "../..";
import { DetailsHeader } from "../../../../../../components/detailsHeader";
import { useRuntimeApi } from "../../../../../../api";
import { BotViewModel, BotManagerApi } from "@futbot/runtime-api";
import { DetailsMenu } from "../../../../../../components/masterDetails/DetailsMenu";
import { Anonymizable } from "../../../../../../components/anonymizable";
import { PlateformIcon } from "../../../../../../components/plateformIcon";
import { fancyHeader } from "../../../../../../assets/jss";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontFamily: "Cairo",
      fontSize: 20
    },
    image: {
      width: 80
    }
  })
);
export function BotDetails() {
  const classes = useStyles({});
  const { botGuid } = useBotGuid();
  const { basePath } = useBotBasePath();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [bot, setBot] = useState<BotViewModel>(null);
  const api = useRuntimeApi(BotManagerApi);
  const pageSwitch = switchRoutes(routes, pagePath + "/" + botGuid);
  useEffect(() => {
    setIsLoading(true);
    api.getBot({ botId: botGuid }).then(b => {
      setBot(b);
      setIsLoading(false);
    });
  }, [botGuid]);

  if (isLoading || !bot) {
    return <LinearProgress />;
  }

  return (
    <Paper>
      <DetailsHeader<BotViewModel>
        data={bot}
        title={bot => (
          <Grid>
            <div className={classes.header}>
              <Anonymizable>{bot.settings.username}</Anonymizable>
            </div>
            <PlateformIcon
              className={classes.image}
              contrast
              plateform={bot.settings.platform}
            />
          </Grid>
        )}
        tableRoute={pagePath}
      />
      <DetailsMenu routes={routes} basePath={basePath}>
        {pageSwitch}
      </DetailsMenu>
    </Paper>
  );
}
