import React, { useEffect, useState } from "react";
import { Theme, Grid, Typography } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { TransactionReviewViewModel } from "@futbot/runtime-api";
import { Coins } from "../../../../../../components/coins";
import classNames from "classnames";
import { SimpleInformation } from "../../../../../../components/SimpleInformation";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "15px 60px"
    }
  })
);

export function TransactionSummary(props: {
  summary: TransactionReviewViewModel;
}) {
  const classes = useStyles({});
  const { t, i18n } = useTranslation();
  const { summary } = props;
  return (
    <div className={classes.root}>
      <Typography variant="h5">Open comfort trades summary</Typography>
      {summary ? (
        <React.Fragment>
          <SimpleInformation width={300} label="Total coins available">
            <Coins amount={summary.availableCoins} />
          </SimpleInformation>
          <SimpleInformation width={300} label="Number of available bots">
            {summary.availableBots}
          </SimpleInformation>
          <SimpleInformation
            width={300}
            label="Total transferrable coins available"
          >
            <Coins amount={summary.availableTransferableCoins} />
          </SimpleInformation>
          <SimpleInformation width={300} label="Number of open comfort trades">
            {summary.openTransactions}
          </SimpleInformation>
          <SimpleInformation width={300} label="Total reserved coins">
            <Coins amount={summary.potentialTransferredCoins} />
          </SimpleInformation>
        </React.Fragment>
      ) : (
        <div>Select a platform to view its comfort trade summary</div>
      )}
    </div>
  );
}
