import React from "react";
import { Outlined, Plain } from "./index";

export function GetEventStyle(
  type: string
): {
  chipStyle?: React.CSSProperties;
  textStyle?: React.CSSProperties;
} {
  switch (type) {
    case "Info":
      return {
        chipStyle: Outlined("#00c700"),
        textStyle: {
          color: "#00c700"
        }
      };
    case "Debug":
      return {
        chipStyle: Outlined("#999"),
        textStyle: {
          color: "#999"
        }
      };
    case "Warn":
      return {
        chipStyle: Outlined("orange"),
        textStyle: {
          color: "orange"
        }
      };
    case "Error":
      return {
        chipStyle: Outlined("red"),
        textStyle: {
          color: "red"
        }
      };
    case "Item Sold":
      return {
        chipStyle: Plain("#cdcb3a")
      };
    case "Item Bought":
      return {
        chipStyle: Plain("#3acdb5")
      };
    case "Price Updated":
      return {
        chipStyle: Outlined("darkturquoise")
      };
    case "Bot Stopped":
      return {
        chipStyle: Plain("red")
      };
    case "Bot Started":
      return {
        chipStyle: Plain("green")
      };
    case "Unassigned List Updated":
    case "Trade Pile Updated":
    case "Sold Items Removed":
      return {
        chipStyle: Outlined("#5b7cec"),
        textStyle: {
          color: "#5b7cec"
        }
      };
  }
  return {};
}
