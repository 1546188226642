import React, { useState, useEffect } from "react";
import { Paper, LinearProgress } from "@material-ui/core";
import { switchRoutes } from "../../../../../../utils/routeUtils";
import buildRoutes from "../../routes";
import { pagePath, useTransactionGuid, useTransactionBasePath } from "../..";
import { useStatApi } from "../../../../../../api";
import { TransactionDetailsHeader } from "./transactionDetailsHeader";
import {
  TransactionViewModel,
  ProviderTransactionsApi
} from "@futbot/stats-api";
import { DetailsMenu } from "../../../../../../components/masterDetails/DetailsMenu";

export function TransactionDetails() {
  const { transactionGuid } = useTransactionGuid();
  const { basePath } = useTransactionBasePath();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [transaction, setTransaction] = useState<TransactionViewModel>(null);
  const api = useStatApi(ProviderTransactionsApi);
  useEffect(() => {
    setIsLoading(true);
    api.getTransactionAsProvider({ transactionId: transactionGuid }).then(b => {
      setTransaction(b);
      setIsLoading(false);
    });
  }, [transactionGuid]);
  var routes = buildRoutes(transaction);
  const pageSwitch = switchRoutes(routes, pagePath + "/" + transactionGuid);

  if (isLoading || !transaction) {
    return <LinearProgress />;
  }

  return (
    <Paper>
      <TransactionDetailsHeader transaction={transaction} />
      <DetailsMenu routes={routes} basePath={basePath}>
        {pageSwitch}
      </DetailsMenu>
    </Paper>
  );
}
