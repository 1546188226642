import React from "react";
import { Theme } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ps4Logo from "../../assets/images/brands/ps_blue.png";
import pcLogo from "../../assets/images/brands/pc_orange.png";
import xboxLogo from "../../assets/images/brands/xbox_green.png";
import ps4LogoW from "../../assets/images/brands/ps_white.png";
import pcLogoW from "../../assets/images/brands/pc_white.png";
import xboxLogoW from "../../assets/images/brands/xbox_white.png";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    xbox: (props: any) => ({
      content: `url(${!props.contrast ? xboxLogo : xboxLogoW})`
    }),
    ps4: (props: any) => ({
      content: `url(${!props.contrast ? ps4Logo : ps4LogoW})`
    }),
    pc: (props: any) => ({
      content: `url(${!props.contrast ? pcLogo : pcLogoW})`
    })
  })
);

export function PlateformIcon(props: {
  contrast?: boolean;
  plateform: number;
  className?: string;
}) {
  const classes = useStyles(props);
  const { plateform, className } = props;
  return (
    <span>
      <InternalIcon />
    </span>
  );

  function InternalIcon() {
    switch (plateform) {
      case 2:
        return <img className={classNames(classes.ps4, className)} />;
      case 8:
        return <img className={classNames(classes.xbox, className)} />;
      case 16:
        return <img className={classNames(classes.pc, className)} />;
      default:
        return <React.Fragment>{"Unknown"}</React.Fragment>;
    }
  }
}
