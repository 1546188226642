import React from "react";
import { TableHead, TableRow, TableCell, Table } from "@material-ui/core";
import { useTableHeaderStyles } from "./interactiveTableHeader";

export function InteractiveListHeader(props: { header: string }) {
  const classes = useTableHeaderStyles({});
  const { header } = props;
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className={classes.header}>{header}</TableCell>
        </TableRow>
      </TableHead>
    </Table>
  );
}
