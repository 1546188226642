import React, { useEffect, useState, useCallback } from "react";
import { Theme, Dialog } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { State } from "../../store/config/configureStore";
import { useMappedState } from "redux-react-hook";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({ img: { width: 125 } })
);

export function Nothing(props: { children?: React.ReactNode }) {
  return <div>{props.children}</div>;
}

export function GlobalLoading(props: {}) {
  const classes = useStyles({});
  const { t, i18n } = useTranslation();
  const mapStateToProps = useCallback(
    (state: State) => ({
      isLoading: state.app.isLoading
    }),
    []
  );
  const { isLoading } = useMappedState(mapStateToProps);
  const {} = props;
  return (
    <Dialog open={isLoading} PaperComponent={Nothing}>
      <img className={classes.img} src="/content/loader.gif" />
    </Dialog>
  );
}
