import React from "react";
import { Theme } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { coinsImage } from "../../assets/jss";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: "#3b97d3",
      fontWeight: 700
    },
    img: {
      width: 11,
      position: "relative",
      top: 0,
      marginLeft: 3,
      content: "url(" + coinsImage + ")"
    }
  })
);

export function Coins(props: {
  amount: number;
  color?: string;
  alwaysDiplay?: boolean;
  zeroMeaning?: string;
  zeroColor?: string;
}) {
  const classes = useStyles({});
  const { amount, color, zeroMeaning, zeroColor, alwaysDiplay } = props;
  return (
    <span className={classes.root}>
      {amount || alwaysDiplay ? (
        <React.Fragment>
          <span style={{ color: color }}>{amount.toLocaleString("en")}</span>
          <img className={classes.img} />
        </React.Fragment>
      ) : (
        <span style={{ color: zeroColor ? zeroColor : color }}>
          {zeroMeaning ? zeroMeaning : "-"}
        </span>
      )}
    </span>
  );
}
