import React from "react";
import { Theme } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import classNames from "classnames";
import playerCardBackground from "../../assets/images/backgrounds/2.jpg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardBody: {
      padding: "0.9375rem 20px",
      flex: "1 1 auto",
      position: "relative",
      backgroundImage: "url(" + playerCardBackground + ")",
      backgroundPosition: "center",
      backgroundSize: "cover"
    },
    cardBodyPlain: {
      paddingLeft: "5px",
      paddingRight: "5px"
    },
    cardBodyProfile: {
      marginTop: "15px"
    }
  })
);

export function CardBody({ ...props }) {
  const classes = useStyles({});
  const { className, children, plain, profile, ...rest } = props;
  const cardBodyClasses = classNames({
    [classes.cardBody]: true,
    [classes.cardBodyPlain]: plain,
    [classes.cardBodyProfile]: profile,
    [className]: className !== undefined
  });
  return (
    <div className={cardBodyClasses} {...rest}>
      {children}
    </div>
  );
}

CardBody.propTypes = {
  className: PropTypes.string,
  plain: PropTypes.bool,
  profile: PropTypes.bool
};
