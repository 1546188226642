import React, { useState } from "react";
import {
  Theme,
  Grid,
  Typography,
  IconButton,
  Tabs,
  Tab
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { fancyHeader } from "../../../../../../assets/jss";
import { ShopListViewModel } from "@futbot/stats-api";
import { Link } from "react-router-dom";
import { pagePath } from "../..";
import {
  CloseIcon,
  FuzzySearchIcon,
  CardsToUseIcon
} from "../../../../../../assets/icons";
import { useDispatch } from "redux-react-hook";
import { push } from "connected-react-router";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      flex: "grow",
      paddingLeft: theme.spacing(2),
      color: theme.palette.primary.contrastText,
      height: 57,
      ...fancyHeader
    }
  })
);

export function ShopListDetailsHeader(props: {
  shoplist: ShopListViewModel;
  onTabSwitch: (i) => void;
  onClose?: () => void;
}) {
  const classes = useStyles({});
  const { t, i18n } = useTranslation();
  const [tab, setTab] = useState(0);
  const dispatch = useDispatch();

  const { shoplist, onTabSwitch, onClose } = props;
  return (
    <div className={classes.header}>
      <Grid container alignItems="center" justify="space-between">
        <Typography color="inherit" variant="h6">
          {shoplist ? shoplist.name : "[]"}
        </Typography>
        <Tabs
          value={tab}
          onChange={(e, t) => {
            setTab(t);
            onTabSwitch(t);
          }}
        >
          <Tab label="Cards" />
          <Tab label="Fuzzy Searches" />
        </Tabs>
        <IconButton
          onClick={() => {
            if (onClose) {
              onClose();
            } else {
              dispatch(push(pagePath));
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
    </div>
  );
}
