import React, { useEffect, useState, useCallback } from "react";
import {
  Theme,
  Table,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { usePerfectScroll } from "../../../../../../hooks";
import { useMappedState } from "redux-react-hook";
import { ProxyViewModel } from "@futbot/runtime-api";
import { State } from "../../../../../../store/config/configureStore";
import { extractProxyId } from "../..";
import { InteractiveListHeader } from "../../../../../../components/interactiveTable";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    tableWrapper: {
      overflow: "auto",
      position: "relative",
      maxHeight: "calc(100vh - 160px)"
    },
    link: {
      color: "#4894fe",
      fontWeight: 500,
      textDecoration: "none"
    }
  })
);

export function ProxiesList(props: { data: ProxyViewModel[] }) {
  const classes = useStyles({});
  const { t, i18n } = useTranslation();
  const mapStateToProps = useCallback(
    (state: State) => ({
      pathname: state.router.location.pathname,
      proxyId: extractProxyId(state.router.location.pathname)
    }),
    []
  );
  const { pathname, proxyId } = useMappedState(mapStateToProps);
  const wrapperRef = usePerfectScroll();

  const { data } = props;
  return (
    <div className={classes.root}>
      <InteractiveListHeader header={"Username"} />
      <div className={classes.tableWrapper} ref={wrapperRef}>
        <Table>
          <TableBody>
            {data.map((proxy, i) => (
              <TableRow hover key={i} selected={proxy.settings.id === proxyId}>
                <TableCell>
                  <Link
                    className={classes.link}
                    to={pathname.replace(
                      proxyId.toString(),
                      proxy.settings.id.toString()
                    )}
                  >
                    <span>{`${proxy.settings.type}://${proxy.settings.host}:${proxy.settings.port}`}</span>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
