import React from "react";
import { Theme, CircularProgress } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desc: {
      fontWeight: 600,
      color: "#0f275c"
    }
  })
);

export function BotProgress(props: { current: number; limit: number }) {
  const classes = useStyles({});
  const { current, limit } = props;
  return <div className={classes.desc}>{`${current} / ${limit}`}</div>;
}
