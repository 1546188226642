import React from "react";
import TimeAgo from "react-timeago";
import { ColumnDefinition } from "../../../../../../components/interactiveTable/columnDefinition";
import { InteractiveTable } from "../../../../../../components/interactiveTable/interactiveTable";
import {
  ToolbarAction,
  ToolbarActionOnSelected
} from "../../../../../../components/interactiveTable/interactiveTableToolbar";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
import { Anonymizable } from "../../../../../../components/anonymizable";
import { pagePath } from "../..";
import { PlateformIcon } from "../../../../../../components/plateformIcon";
import {
  TransactionPreviewViewModel,
  TransactionViewModel
} from "@futbot/stats-api";
import {
  AddIcon,
  OpenLockIcon,
  ClosedLockIcon,
  ExternalLinkIcon
} from "../../../../../../assets/icons";
import { TransactionProgress } from "../../../../../../components/transactionProgress";
import { IconButton, Tooltip } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      overflow: "auto"
    },
    link: {
      color: "#4894fe",
      fontWeight: 500,
      textDecoration: "none"
    },
    tableWrapper: {
      overflow: "auto",
      position: "relative",
      maxHeight: "calc(100vh - 150px)"
    },
    table: {
      tableLayout: "auto",
      width: "auto"
    },
    lastActivity: {
      fontWeight: 200
    },
    creationDate: {
      fontSize: 11,
      color: "#90949c"
    }
  })
);

export function TransactionsTable(props: {
  transactions: TransactionPreviewViewModel[];
  isLoading?: boolean;
  onCreationRequest: () => void;
}) {
  const classes = useStyles({});
  const { transactions, onCreationRequest, isLoading } = props;
  const columns: ColumnDefinition<TransactionPreviewViewModel>[] = [
    {
      id: "identifier",
      label: "Identifier",
      width: "35%",
      value: t => t.guid,
      render: t => (
        <React.Fragment>
          <div>
            <Link
              className={classes.link}
              to={pagePath + "/" + t.guid + "/overview"}
            >
              <Anonymizable>{t.guid}</Anonymizable>
            </Link>
          </div>
          <div className={classes.creationDate}>
            Created <TimeAgo date={t.creationDate} />
          </div>
        </React.Fragment>
      )
    },
    {
      id: "plateform",
      label: "Plateform",
      width: "10%",
      value: t => t.platform,
      render: t => <PlateformIcon plateform={t.platform} />
    },
    {
      id: "progression",
      label: "Progression",
      width: "40%",
      value: t => t.transferredAmount,
      render: t => (
        <TransactionProgress
          taken={t.transferredAmount}
          total={t.totalAmount}
        />
      )
    },
    {
      id: "actions",
      label: "",
      width: "10%",
      align: "right",
      value: t => t.isOpen,
      render: t => (
        <React.Fragment>
          <Tooltip
            title={t.isOpen && "Lock the transaction"}
            aria-label="toggle"
          >
            <IconButton size="small" disabled={!t.isOpen}>
              {t.isOpen ? <OpenLockIcon /> : <ClosedLockIcon />}
            </IconButton>
          </Tooltip>
          <Tooltip title="Open customer page" aria-label="add">
            <IconButton
              size="small"
              onClick={() => {
                window.open(`/customer/transaction/${t.guid}`, "_blank");
              }}
            >
              <ExternalLinkIcon />
            </IconButton>
          </Tooltip>
        </React.Fragment>
      )
    }
  ];

  const actions: ToolbarAction[] = [
    {
      action: onCreationRequest,
      label: "Create",
      variant: "outlined",
      icon: AddIcon
    }
  ];

  return (
    <InteractiveTable<TransactionViewModel>
      data={transactions}
      isLoading={isLoading}
      isReadOnly={isLoading}
      columns={columns}
      getKey={t => t.guid}
      actions={actions}
    />
  );
}
