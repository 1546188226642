import React, { useEffect, useState } from "react";
import {
  Theme,
  LinearProgress,
  Paper,
  FormControl,
  Input,
  InputAdornment
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { useLoading } from "../../../../hooks/useLoading";
import { useRuntimeApi } from "../../../../api";
import { useAccountId } from ".";
import { useActionOnItems } from "../../../../hooks/useActionOnItems";
import { MasterDetails } from "../../../../components/masterDetails";
import { AccountsList } from "./components/AccountsList";
import { AccountDetails } from "./components/AccountDetails";
import { AccountsTable } from "./components/AccountsTable";
import { AccountSettings } from "@futbot/login-server-api";
import { AdministrationApi } from "@futbot/runtime-api";
import { useDebounce } from "../../../../hooks";

const useStyles = makeStyles((theme: Theme) => createStyles({}));

export function AccountsPage(props: {}) {
  const classes = useStyles({});
  const { t, i18n } = useTranslation();
  const { isLoading, setIsLoading } = useLoading();
  const [reload, setReload] = useState(0);
  const [data, setData] = useState<AccountSettings[]>([]);
  const [searchPattern, setSearchPattern] = useState("");
  const search = useDebounce(searchPattern);
  const api = useRuntimeApi(AdministrationApi);
  const {} = props;
  const { AccountId } = useAccountId();
  const {
    actionOnSelected,
    reloadCount,
    bulkActionLoading
  } = useActionOnItems();

  useEffect(() => {
    setIsLoading(true);
    api
      .getAccountsList({ searchPattern: search })
      .then(d => {
        setData(d);
      })
      .catch(reason => {
        console.log(reason);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [reload, search]);

  return (
    <Paper>
      {isLoading && <LinearProgress />}
      <MasterDetails
        selectedElement={AccountId}
        detailsComponent={<AccountDetails />}
        tableComponent={
          <AccountsTable
            isLoading={isLoading || bulkActionLoading}
            isReadOnly={isLoading || bulkActionLoading}
            data={data}
            onRefresh={() => setReload(reload + 1)}
            additionalTools={
              <FormControl>
                <Input
                  value={searchPattern}
                  onChange={e => {
                    setSearchPattern(e.target.value);
                  }}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                />
              </FormControl>
            }
          />
        }
        listComponent={<AccountsList data={data} />}
      />
    </Paper>
  );
}
