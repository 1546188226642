import React from "react";
import { PlayerCardViewModel } from "@futbot/stats-api";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import "./pversion.css";
import { rarityMap } from "../playerCard/rarityMap";
import { getCardColor } from "../playerCard/getCardColor";
import classNames from "classnames";
import { baseEaAssetsUrl } from "../playerCard";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      textAlign: "left",
      paddingTop: 5,
      height: 40,
      fontWeight: "normal",
      display: "block",
      fontFamily: 'Din Pro", "Segoe UI", "Segoe WP", Tahoma, Arial, sans-serif',
    },
    club: {
      width: 20,
      verticalAlign: "middle",
    },

    country: {
      width: 20,
      verticalAlign: "middle",
    },
    image: {
      width: 38,
      position: "relative",
      top: "-2px",
      verticalAlign: "middle",
    },
    name: {
      position: "relative",
      top: "-2px",
      fontSize: 14,
      fontWeight: 600,
      fontFamily: "Poppins",

      whiteSpace: "nowrap",
      overflow: "hidden",
      verticalAlign: "middle",
      textOverflow: "ellipsis",
      display: "inline-block",
    },
    position: {
      fontSize: 10,
      color: "#a0a1a1",
      paddingLeft: 2,
      position: "relative",
      top: "-2px",
    },

    rating: {
      float: "right",
      padding: 4,
      margin: "0 2px 0 0",
      borderRadius: 2,
    },
  })
);

export interface CardProps extends PlayerCardViewModel {
  showClubImage?: boolean;
  showCountryImage?: boolean;
  showPlayerImage?: boolean;
  children?: React.ReactNode;
  className?: string;
}

export function PlayerInfo(props: CardProps) {
  const {
    rating,
    className,
    showClubImage,
    showCountryImage,
    showPlayerImage,
    clubId,
    nationId,
    id,
    baseId,
    imageUrl,
    name,
    position,
    color,
    rarityId,
  } = props;
  const classes = useStyles({});

  var imgSrc = `${baseEaAssetsUrl}/fut/items/images/mobile/portraits/${baseId}.png`;
  if (rarityId > 1) {
    imgSrc = `${baseEaAssetsUrl}/fut/playerheads/mobile/single/p${id}.png`;
  }

  function fallbackSrc(ev) {
    ev.target.src = `${baseEaAssetsUrl}/fut/items/images/mobile/portraits/${baseId}.png`;
  }

  return (
    <div className={classNames(classes.root, className)}>
      <span
        className={`rating ut19  ${getCardColor(rating)} ${
          rarityMap[rarityId]
        }`}
      >
        {rating}
      </span>
      {!showClubImage && (
        <img
          className={classes.club}
          src={`${baseEaAssetsUrl}/fut/items/images/mobile/clubs/normal/${clubId}.png`}
        />
      )}
      {!showCountryImage && (
        <img
          className={classes.country}
          src={`${baseEaAssetsUrl}/fut/items/images/mobile/flags/card/${nationId}.png`}
        />
      )}
      {!showPlayerImage && (
        <img className={classes.image} src={imgSrc} onError={fallbackSrc} />
      )}
      <span className={classes.name}>{name}</span>
      {/* <span className={classes.position}>({position})</span> */}
    </div>
  );
}
