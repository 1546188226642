import React, { useState, useEffect } from "react";
import {
  Theme,
  Toolbar,
  InputBase,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Typography
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { fade } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { SerializedEvent } from "@futbot/stats-api";
import { fontSize, borderColor } from "@material-ui/system";
import { GetEventStyle } from "./GetEventStyle";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText
    },
    spacer: {
      flexGrow: 1
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25)
      },
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto"
      }
    },
    searchIcon: {
      width: theme.spacing(7),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    inputRoot: {
      color: "inherit"
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: 120,
        "&:focus": {
          width: 200
        }
      }
    },
    logTime: {
      padding: "5px 0px 5px 16px",
      width: 10,
      color: "#999"
    },
    logType: {
      fontSize: 12
    },
    logMessage: {
      marginLeft: 8
    }
  })
);

export function Plain(
  bgColor: string,
  color: string = "white"
): React.CSSProperties {
  return {
    borderColor: bgColor,
    backgroundColor: bgColor,
    color: color
  };
}

export function Outlined(color: string): React.CSSProperties {
  return {
    borderColor: color,
    color: color
  };
}

export function LogViewer(props: { events: SerializedEvent[] }) {
  const classes = useStyles({});
  const [iEvents, setIEvents] = useState<SerializedEvent[]>(null);
  const { events } = props;

  useEffect(() => {
    setIEvents(events);
  }, [props]);

  return (
    <React.Fragment>
      {iEvents && iEvents.length ? (
        <Table size="small">
          <TableBody>
            {iEvents.map(event => {
              var styles = GetEventStyle(event.eventType);
              return (
                <TableRow>
                  <TableCell
                    className={classes.logTime}
                  >{`[${event.date.toLocaleTimeString()}]`}</TableCell>
                  <TableCell>
                    <React.Fragment>
                      <Chip
                        label={event.eventType}
                        size="small"
                        variant="outlined"
                        style={styles.chipStyle}
                      />
                      <span
                        className={classes.logMessage}
                        style={styles.textStyle}
                      >
                        {event.message}
                      </span>
                    </React.Fragment>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <div>No logs available</div>
      )}
    </React.Fragment>
  );
}
