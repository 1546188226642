import { StoreContext } from "redux-react-hook";
import { createMatchSelector } from "connected-react-router";
import { useContext } from "react";

export function useMatch(pattern: string) {
  const store = useContext(StoreContext);
  const matchSelector = createMatchSelector(pattern);
  const match = matchSelector(store.getState());
  return match;
}
