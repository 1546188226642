export const nations = [
  { value: 52, label: "Argentina" },
  { value: 54, label: "Brazil" },
  { value: 14, label: "England" },
  { value: 18, label: "France" },
  { value: 21, label: "Germany" },
  { value: 34, label: "Netherlands" },
  { value: 27, label: "Italy" },
  { value: 38, label: "Portugal" },
  { value: 45, label: "Spain" },
  { value: 149, label: "Afghanist." },
  { value: 1, label: "Albania" },
  { value: 97, label: "Algeria" },
  { value: 98, label: "Angola" },
  { value: 63, label: "Antigua B." },
  { value: 52, label: "Argentina" },
  { value: 3, label: "Armenia" },
  { value: 195, label: "Australia" },
  { value: 4, label: "Austria" },
  { value: 5, label: "Azerbaijan" },
  { value: 66, label: "Barbados" },
  { value: 6, label: "Belarus" },
  { value: 7, label: "Belgium" },
  { value: 67, label: "Belize" },
  { value: 99, label: "Benin" },
  { value: 68, label: "Bermuda" },
  { value: 53, label: "Bolivia" },
  { value: 8, label: "Bosnia-H." },
  { value: 54, label: "Brazil" },
  { value: 9, label: "Bulgaria" },
  { value: 101, label: "Burk. Faso" },
  { value: 102, label: "Burundi" },
  { value: 103, label: "Cameroon" },
  { value: 70, label: "Canada" },
  { value: 104, label: "Cape Verde" },
  { value: 105, label: "CAR" },
  { value: 106, label: "Chad" },
  { value: 55, label: "Chile" },
  { value: 155, label: "China PR" },
  { value: 56, label: "Colombia" },
  { value: 214, label: "Comoros" },
  { value: 107, label: "Congo" },
  { value: 72, label: "Costa Rica" },
  { value: 10, label: "Croatia" },
  { value: 73, label: "Cuba" },
  { value: 11, label: "Cyprus" },
  { value: 12, label: "Czech Rep." },
  { value: 13, label: "Denmark" },
  { value: 207, label: "Dom. Rep." },
  { value: 110, label: "DR Congo" },
  { value: 112, label: "E. Guinea" },
  { value: 57, label: "Ecuador" },
  { value: 111, label: "Egypt" },
  { value: 76, label: "El Salvad." },
  { value: 14, label: "England" },
  { value: 113, label: "Eritrea" },
  { value: 208, label: "Estonia" },
  { value: 114, label: "Ethiopia" },
  { value: 16, label: "Faroe Isl." },
  { value: 197, label: "Fiji" },
  { value: 17, label: "Finland" },
  { value: 18, label: "France" },
  { value: 115, label: "Gabon" },
  { value: 116, label: "Gambia" },
  { value: 20, label: "Georgia" },
  { value: 21, label: "Germany" },
  { value: 117, label: "Ghana" },
  { value: 22, label: "Greece" },
  { value: 77, label: "Grenada" },
  { value: 157, label: "Guam" },
  { value: 78, label: "Guatemala" },
  { value: 118, label: "Guinea" },
  { value: 119, label: "Guinea-Bis" },
  { value: 79, label: "Guyana" },
  { value: 80, label: "Haiti" },
  { value: 81, label: "Honduras" },
  { value: 158, label: "Hong Kong" },
  { value: 23, label: "Hungary" },
  { value: 24, label: "Iceland" },
  { value: 161, label: "Iran" },
  { value: 162, label: "Iraq" },
  { value: 25, label: "Ireland" },
  { value: 26, label: "Israel" },
  { value: 27, label: "Italy" },
  { value: 108, label: "Ivory Coast" },
  { value: 82, label: "Jamaica" },
  { value: 163, label: "Japan" },
  { value: 164, label: "Jordan" },
  { value: 165, label: "Kazakhstan" },
  { value: 120, label: "Kenya" },
  { value: 166, label: "Korea DPR" },
  { value: 167, label: "Korea Rep." },
  { value: 219, label: "Kosovo" },
  { value: 28, label: "Latvia" },
  { value: 122, label: "Liberia" },
  { value: 123, label: "Libya" },
  { value: 29, label: "Liechten." },
  { value: 30, label: "Lithuania" },
  { value: 31, label: "Luxemburg" },
  { value: 19, label: "Macedonia" },
  { value: 124, label: "Madagascar" },
  { value: 126, label: "Mali" },
  { value: 32, label: "Malta" },
  { value: 127, label: "Mauritania" },
  { value: 128, label: "Mauritius" },
  { value: 83, label: "Mexico" },
  { value: 33, label: "Moldova" },
  { value: 15, label: "Montenegro" },
  { value: 84, label: "Montserrat" },
  { value: 129, label: "Morocco" },
  { value: 130, label: "Mozambique" },
  { value: 35, label: "N. Ireland" },
  { value: 85, label: "N.Antilles" },
  { value: 131, label: "Namibia" },
  { value: 34, label: "Netherlands" },
  { value: 215, label: "New Caled." },
  { value: 198, label: "New Zealand" },
  { value: 132, label: "Niger" },
  { value: 133, label: "Nigeria" },
  { value: 36, label: "Norway" },
  { value: 178, label: "Oman" },
  { value: 180, label: "Palestinian Authority" },
  { value: 87, label: "Panama" },
  { value: 58, label: "Paraguay" },
  { value: 59, label: "Peru" },
  { value: 181, label: "Philippines" },
  { value: 37, label: "Poland" },
  { value: 38, label: "Portugal" },
  { value: 88, label: "Puerto Rico" },
  { value: 39, label: "Romania" },
  { value: 40, label: "Russia" },
  { value: 134, label: "Rwanda" },
  { value: 140, label: "S. Africa" },
  { value: 135, label: "São Tomé &amp, Príncipe" },
  { value: 183, label: "Saudi Ara." },
  { value: 42, label: "Scotland" },
  { value: 136, label: "Senegal" },
  { value: 51, label: "Serbia" },
  { value: 138, label: "Sierra L." },
  { value: 43, label: "Slovakia" },
  { value: 44, label: "Slovenia" },
  { value: 218, label: "South Sudan" },
  { value: 45, label: "Spain" },
  { value: 89, label: "St Kitts Nevis" },
  { value: 90, label: "St Lucia" },
  { value: 141, label: "Sudan" },
  { value: 92, label: "Suriname" },
  { value: 46, label: "Sweden" },
  { value: 47, label: "Switzerl." },
  { value: 186, label: "Syria" },
  { value: 143, label: "Tanzania" },
  { value: 188, label: "Thailand" },
  { value: 144, label: "Togo" },
  { value: 93, label: "Trinidad" },
  { value: 145, label: "Tunisia" },
  { value: 48, label: "Turkey" },
  { value: 146, label: "Uganda" },
  { value: 49, label: "Ukraine" },
  { value: 190, label: "United Arab Emirates" },
  { value: 60, label: "Uruguay" },
  { value: 95, label: "USA" },
  { value: 191, label: "Uzbekistan" },
  { value: 61, label: "Venezuela" },
  { value: 50, label: "Wales" },
  { value: 147, label: "Zambia" },
  { value: 148, label: "Zimbabwe" }
];
