import React, { useState } from "react";
import { Theme, Slide, Tooltip, IconButton } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import {
  ColumnDefinition,
  InteractiveTable,
  ToolbarAction,
  ToolbarActionOnSelected,
} from "../../../../../../components/interactiveTable";
import { ShopListViewModel, ShopListsApi } from "@futbot/stats-api";
import { Link } from "react-router-dom";
import { Anonymizable } from "../../../../../../components/anonymizable";
import { pagePath } from "../..";
import {
  AddIcon,
  EditIcon,
  DeleteIcon,
  SettingsIcon,
} from "../../../../../../assets/icons";
import { ShopListModal } from "../shopListModal";
import { useSnackbar } from "@creatdevsolutions/notistack";
import { useStatApi } from "../../../../../../api";
import { useActionOnItems } from "../../../../../../hooks/useActionOnItems";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: "auto",
    },
    link: {
      color: "#4894fe",
      fontWeight: 500,
      textDecoration: "none",
    },
  })
);

export function ShopListsTable(props: {
  shoplists: ShopListViewModel[];
  onRefresh?: () => void;
  isLoading?: boolean;
}) {
  const classes = useStyles({});
  const { shoplists, onRefresh, isLoading } = props;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [shoplist, setShoplist] = useState<ShopListViewModel>({});
  const [action, setAction] = useState<"add" | "update">("add");
  const { enqueueSnackbar } = useSnackbar();
  const api = useStatApi(ShopListsApi);
  const { t } = useTranslation();
  const { actionOnSelected, bulkActionLoading } = useActionOnItems();

  const actions: ToolbarAction[] = [
    {
      action: () => {
        setShoplist({});
        setAction("add");
        setShowModal(true);
      },
      label: "New",
      icon: AddIcon,
      variant: "outlined",
    },
  ];

  const actionsOnSelected: ToolbarActionOnSelected[] = [
    {
      action: deleteSelected,
      label: "Delete",
      icon: DeleteIcon,
      variant: "outlined",
      clearSelectionAfterAction: true,
    },
  ];

  async function deleteSelected(selected: string[]) {
    await actionOnSelected(
      selected,
      selected.length == 1 ? null : `Removing ${selected.length} Shop lists...`,
      selected.length == 1
        ? `"${selected[0]}" Shop list Removed`
        : `${selected.length} Shop lists Removed`,
      async (id) => {
        await api.deleteShopList({
          shopList: { id: parseInt(id) },
        });
      }
    );
    onRefresh();
  }

  const columns: ColumnDefinition<ShopListViewModel>[] = [
    {
      id: "identifier",
      label: "Identifier",
      width: "35%",
      value: (sl) => sl.name,
      render: (sl) => (
        <Link className={classes.link} to={pagePath + "/" + sl.id}>
          <Anonymizable>{sl.name}</Anonymizable>
        </Link>
      ),
    },
    {
      id: "cardsNumber",
      label: "Cards",
      width: "15%",
      value: (sl) => sl.searches.length,
      render: (sl) => <React.Fragment>{sl.searches.length}</React.Fragment>,
    },
    {
      id: "fuzzySearches",
      label: "Fuzzy Searches",
      width: "15%",
      value: (sl) => sl.fuzzySearches.length,
      render: (sl) => (
        <React.Fragment>{sl.fuzzySearches.length}</React.Fragment>
      ),
    },
    {
      id: "actions",
      label: "",
      align: "right",
      width: "30%",
      render: (c) => (
        <React.Fragment>
          <Tooltip title={t("misc.rename")}>
            <IconButton
              size="small"
              onClick={() => {
                setAction("update");
                setShoplist(c);
                setShowModal(true);
              }}
              color="primary"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("misc.delete")}>
            <IconButton
              size="small"
              color="secondary"
              onClick={async () => {
                await deleteSelected([c.id.toString()]);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </React.Fragment>
      ),
    },
  ];

  return (
    <React.Fragment>
      <ShopListModal
        open={showModal}
        action={action}
        shoplist={shoplist}
        onClose={() => {
          setShowModal(false);
        }}
        onSubmit={async (sl) => {
          if (action == "add") {
            await api.createNewShopList({ shopList: sl });
          } else if (action == "update") {
            await api.updateShopList({ shopList: sl });
          }
          enqueueSnackbar(
            `Shop List : "${sl.name}" ${action == "add" ? "Added" : "Updated"}`,
            { variant: "success" }
          );
          setShowModal(false);
          onRefresh();
        }}
      />
      <InteractiveTable<ShopListViewModel>
        isLoading={isLoading || bulkActionLoading}
        isReadOnly={isLoading || bulkActionLoading}
        data={shoplists}
        columns={columns}
        actionsOnSelected={actionsOnSelected}
        actions={actions}
        getKey={(t) => t.id.toString()}
        onRefresh={onRefresh}
      />
    </React.Fragment>
  );
}
