import React, { useEffect, useState } from "react";
import {
  Theme,
  AppBar,
  Dialog,
  Toolbar,
  Typography,
  Button,
  Grid
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { useRuntimeApi } from "../../../../../../api";
import {
  TransactionViewModel,
  ProviderTransactionsApi,
  TransactionReviewViewModel
} from "@futbot/runtime-api";
import { useSnackbar } from "@creatdevsolutions/notistack";
import { TransactionSummary } from "./transactionSummary";
import { InputTransaction } from "./inputTransaction";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative"
    },
    flex: {
      flex: 1
    }
  })
);

export function TransactionCreationModal(props: {
  open: boolean;
  onClose: () => void;
  transaction: TransactionViewModel;
  onSubmit: (Transaction: TransactionViewModel) => void;
}) {
  const classes = useStyles({});
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { open, onClose, onSubmit, transaction } = props;
  const [newTransaction, setNewTransaction] = useState<TransactionViewModel>(
    transaction
  );
  const [transactionPreview, setTransactionPreview] = useState<
    TransactionReviewViewModel
  >();

  useEffect(() => {
    setNewTransaction(transaction);
  }, [props]);

  const api = useRuntimeApi(ProviderTransactionsApi);
  useEffect(() => {
    if (!newTransaction || !newTransaction.platform) {
      return;
    }
    api
      .getTransactionPreview({ transaction: newTransaction })
      .then(preview => {
        setTransactionPreview(preview);
      })
      .catch(r => {
        enqueueSnackbar("Failed to get transaction summary: " + r, {
          variant: "error"
        });
      });
  }, [newTransaction.platform]);
  return (
    <Dialog fullWidth maxWidth={"md"} open={open} onClose={onClose}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            Create a new comfort trade
          </Typography>
          <Button color="inherit" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="inherit"
            onClick={() => {
              onSubmit(newTransaction);
            }}
          >
            Create
          </Button>
        </Toolbar>
      </AppBar>
      <Grid container>
        <Grid md={4}>
          <InputTransaction
            transaction={newTransaction}
            onTransactionUpdate={tsx => {
              setNewTransaction(tsx);
            }}
          />
        </Grid>
        <Grid md={8}>
          <TransactionSummary summary={transactionPreview} />
        </Grid>
      </Grid>
    </Dialog>
  );
}
