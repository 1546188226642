import React, { useState, useEffect } from "react";
import { Theme, Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { BotSettingsViewModel } from "@futbot/runtime-api";
import { green, red, grey } from "@material-ui/core/colors";
import classNames from "classnames";
import { StyleRules } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    slot: {
      width: theme.spacing(10),
      height: theme.spacing(10),
      margin: 1
    },
    active: {
      color: theme.palette.getContrastText(green[400]),
      backgroundColor: green[400],
      "&:hover": {
        color: theme.palette.getContrastText(green[200]),
        backgroundColor: green[200]
      }
    },
    inactive: {
      color: theme.palette.getContrastText(red[400]),
      backgroundColor: red[400],
      "&:hover": {
        color: theme.palette.getContrastText(red[200]),
        backgroundColor: red[200]
      }
    },
    disabled: {
      color: theme.palette.getContrastText(grey[400]),
      backgroundColor: grey[400]
    }
  })
);

export function InputSchedule(props: {
  schedule: number;
  disabled?: boolean;
  onChanged: (schedule: number) => void;
}) {
  const classes = useStyles({});
  const { schedule, onChanged, disabled } = props;
  const [iSchedule, setISchedule] = useState<number>(schedule);
  useEffect(() => {
    setISchedule(schedule);
  }, [props]);
  return (
    <div>
      {new Array(24).fill(undefined).map((_, i) => {
        var s = (schedule & (1 << i)) != 0;
        return (
          <Button
            className={classNames(classes.slot, {
              [classes.active]: s,
              [classes.inactive]: !s,
              [classes.disabled]: disabled
            })}
            key={i}
            disabled={disabled}
            onClick={() => {
              var newSchedule = s ? schedule - (1 << i) : schedule + (1 << i);
              setISchedule(newSchedule);
              onChanged(newSchedule);
            }}
          >
            {(i % 12).toString().padStart(2, "0") +
              " " +
              (i / 12 >= 1 ? "PM" : "AM")}
          </Button>
        );
      })}
    </div>
  );
}
