import { useCallback } from "react";
import { State } from "../../../../store/config/configureStore";
import { useMappedState } from "redux-react-hook";

export * from "./AccountsPage";
const menu = "administrator";
const subMenu = "accounts";
export const relativePagePath = `/${subMenu}`;
export const pagePath = `/${menu}/${subMenu}`;

export function extractAccountId(pathName: string): string | undefined {
  var splitted = pathName.split("/");
  return splitted.length >= 4 &&
    splitted[1].toLowerCase() === menu &&
    splitted[2].toLowerCase() === subMenu
    ? splitted[3]
    : undefined;
}

export const useAccountId = () => {
  const mapStateToProps = useCallback(
    (state: State) => ({
      AccountId: extractAccountId(state.router.location.pathname),
    }),
    []
  );
  const { AccountId } = useMappedState(mapStateToProps);
  return { AccountId };
};
