import React, { useState, useEffect } from "react";
import {
  Theme,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { OrderViewModel } from "@futbot/stats-api";
import { usePerfectScroll } from "../../hooks";
import { PlayerInfo } from "../playerInfo";
import TimeAgo from "react-timeago";
import { Coins } from "../coins";
import { OrderState } from "../orderState";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    tableWrapper: {
      overflow: "auto",
      position: "relative",
      maxHeight: "calc(100vh - 160px)"
    },
    date: {
      fontSize: 11,
      color: "#90949c"
    },
    header: {
      fontFamily: "Cairo",
      fontSize: 15,
      textTransform: "uppercase",
      fontWeight: 600
    }
  })
);

export function OrdersHistory(props: { orders: OrderViewModel[] }) {
  const classes = useStyles({ test: Boolean });
  const { orders } = props;
  const [displayedOrders, setDisplayedOrders] = useState<OrderViewModel[]>([]);
  const wrapperRef = usePerfectScroll();

  useEffect(() => {
    setDisplayedOrders(orders);
  }, [props]);

  return (
    <div>
      <div className={classes.tableWrapper} ref={wrapperRef}>
        <Table>
          <TableHead>
            <TableCell className={classes.header}>Player Card</TableCell>
            <TableCell className={classes.header}>Sell Price</TableCell>
            <TableCell className={classes.header}>Market Price</TableCell>
            <TableCell className={classes.header}>
              Effective Transferred Amount
            </TableCell>
            <TableCell className={classes.header}>Outcome</TableCell>
          </TableHead>
          <TableBody>
            {displayedOrders.map((order, i) => {
              var transferred = order.suggestedBuyPrice
                ? order.buyNowPrice - order.suggestedBuyPrice
                : 0;
              return (
                <TableRow hover key={i}>
                  <TableCell>
                    <PlayerInfo {...order.playerCard} />
                  </TableCell>
                  <TableCell>
                    <Coins amount={order.buyNowPrice} color={"#447ce2"} />
                  </TableCell>
                  <TableCell>
                    <Coins amount={order.suggestedBuyPrice} color={"#c643bb"} />
                  </TableCell>
                  <TableCell>
                    <Coins amount={Math.floor(transferred)} color={"#1f731f"} />
                  </TableCell>
                  <TableCell align={"right"}>
                    <OrderState state={order.state} />
                    <div className={classes.date}>
                      {order.handlingTime ? (
                        <TimeAgo date={order.handlingTime} />
                      ) : (
                        <TimeAgo date={order.registerTime} />
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
