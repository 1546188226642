import { useRef, useState, useLayoutEffect, useCallback } from "react";

function getSize(element: any) {
  if (!element) {
    return {
      width: 0,
      height: 0
    };
  }

  return {
    width: element.offsetWidth,
    height: element.offsetHeight
  };
}

export function useComponentSize() {
  const ref = useRef(null);
  let [size, setSize] = useState(getSize(ref ? ref.current : {}));

  const handleResize = useCallback(
    function handleResize() {
      if (ref.current) {
        setSize(getSize(ref.current));
      }
    },
    [ref]
  );

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [ref.current]);

  return { size, ref };
}
