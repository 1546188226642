import React from "react";
import {
  Theme,
  Icon,
  Typography,
  CircularProgress,
  Tooltip
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardIcon
} from "../../../../../../components/card";
import { grayColor } from "../../../../../../assets/jss";
import { CustomProgress } from "../../../../../../components/customProgress";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headedCard: {
      marginTop: theme.spacing(6)
    },
    cardCategory: {
      color: grayColor[0],
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      paddingTop: "10px",
      marginBottom: "0"
    },
    cardTitle: {
      display: "block",
      color: grayColor[2],
      cursor: "pointer",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: 300,
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: grayColor[1],
        fontWeight: 400,
        lineHeight: "1"
      }
    },
    stats: {
      color: grayColor[0],
      display: "inline-flex",
      fontSize: "12px",
      lineHeight: "22px",
      "& svg": {
        top: "4px",
        width: "16px",
        height: "16px",
        position: "relative",
        marginRight: "3px",
        marginLeft: "3px"
      },
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        top: "4px",
        fontSize: "16px",
        position: "relative",
        marginRight: "3px",
        marginLeft: "3px"
      }
    }
  })
);

export function StatisticCard(props: {
  title: string;
  tooltip?: string;
  color?: string;
  onRenderBody: () => any;
  onRenderFooter?: () => any;
  onClick?: () => any;
  icon?: any;
  isLoading?: boolean;
  hideFooter?: boolean;
}) {
  const classes = useStyles({});
  const {
    title,
    color,
    onRenderBody,
    onRenderFooter,
    isLoading,
    tooltip,
    hideFooter,
    onClick
  } = props;
  return (
    <Card
      className={classNames({
        [classes.headedCard]: props.icon !== undefined
      })}
    >
      <CardHeader color="fancy" stats icon>
        {props.icon && (
          <CardIcon color={color}>
            <props.icon />
          </CardIcon>
        )}
        <p className={classes.cardCategory}>{title}</p>
        {isLoading ? (
          <CustomProgress />
        ) : tooltip ? (
          <Tooltip
            title={tooltip}
            hidden={true}
            aria-label={tooltip}
            placement="bottom-end"
            onClick={onClick}
          >
            <Typography variant="h5" className={classes.cardTitle}>
              {onRenderBody()}
            </Typography>
          </Tooltip>
        ) : (
          <Typography variant="h5" className={classes.cardTitle}>
            {onRenderBody()}
          </Typography>
        )}
      </CardHeader>
      {!hideFooter && (
        <CardFooter stats>
          <div className={classes.stats}>
            {onRenderFooter ? onRenderFooter() : "-"}
          </div>
        </CardFooter>
      )}
    </Card>
  );
}
