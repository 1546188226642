import React, { useState, useEffect } from "react";
import {
  Theme,
  AppBar,
  Tabs,
  Tab,
  TextField,
  MenuItem,
  Divider,
  FormControlLabel,
  Checkbox,
  Switch,
  Paper,
  Button,
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import {
  codeFetchingModes,
  InputBottingMode,
  InputSchedule,
} from "../botSettings";
import ChipInput from "material-ui-chip-input";
import { BackupCode } from "@futbot/login-server-api";
import { BotSettingsViewModel } from "@futbot/runtime-api";
import {
  platforms,
  PlateformPicker,
} from "../../../../../../components/plateformPicker";
import { InputShopList } from "./InputShopList";
import { HelpNote } from "../../../../../../components/helpNote";
import { useTranslation } from "react-i18next";
import { shouldDisableExternalApi } from "../../../../../../utils/featureUtils";
import { ShopListEditorModal } from "../../../shoplists/components/shopListDetails/ShopListEditorModal";
import { Anonymizable } from "../../../../../../components/anonymizable";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flexWrap: "wrap",
      height: "calc(100vh - 250px)",
      overflow: "auto",
    },
    textField: {
      margin: 8,
      minWidth: theme.spacing(40),
    },
    editLink: {
      marginTop: 25,
      color: "blue",
      textTransform: "capitalize",
    },
  })
);

export function InputBotSettings(props: {
  showHelp: boolean;
  settings: BotSettingsViewModel;
  onSettingsUpdate: (settings: BotSettingsViewModel) => void;
}) {
  const { showHelp, settings, onSettingsUpdate } = props;
  const classes = useStyles({});
  const { t } = useTranslation();
  const [tab, setTab] = useState<number>(0);
  const [shopListEditorOpen, setShopListEditorOpen] = useState(false);
  const [botSettings, setBotSettings] = useState<BotSettingsViewModel>(
    settings
  );

  useEffect(() => {
    setBotSettings(props.settings);
  }, [props]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    const newSettings = { ...botSettings, [name]: value };
    onSettingsUpdate(newSettings);
    setBotSettings(newSettings);
  };

  const handleValueChange = (name: string, value: any) => {
    const newSettings = { ...botSettings, [name]: value };
    onSettingsUpdate(newSettings);
    setBotSettings(newSettings);
  };

  const handleInputCheck = (e: any) => {
    const { name, checked } = e.target;
    const newSettings = { ...botSettings, [name]: checked };
    onSettingsUpdate(newSettings);
    setBotSettings(newSettings);
  };

  return (
    <React.Fragment>
      <AppBar position="static">
        <Tabs
          value={tab}
          onChange={(e, t) => {
            setTab(t);
          }}
        >
          <Tab label={t("bots.labels.loginSettings")} />
          <Tab label={t("bots.labels.logicSettings")} />
          <Tab label={t("bots.labels.safetySettings")} />
          <Tab label={t("bots.labels.shopList")} />
        </Tabs>
      </AppBar>
      <div className={classes.container}>
        {tab === 0 && (
          <React.Fragment>
            <Anonymizable>
              <TextField
                label={t("misc.emailAddress")}
                name="username"
                className={classes.textField}
                value={botSettings.username}
                onChange={handleInputChange}
              />
            </Anonymizable>
            <Anonymizable>
              <TextField
                label={t("misc.password")}
                name="password"
                className={classes.textField}
                value={botSettings.password}
                onChange={handleInputChange}
              />
            </Anonymizable>
            <Anonymizable>
              <PlateformPicker
                label={t("misc.platform")}
                className={classes.textField}
                initialValue={botSettings.platform}
                onChange={(p) => {
                  handleValueChange("platform", p);
                }}
              />
            </Anonymizable>
            <Anonymizable>
              <TextField
                label={t("bots.labels.codeFetchingMode")}
                name="codeFetchingMode"
                className={classes.textField}
                value={botSettings.codeFetchingMode}
                select
                onChange={handleInputChange}
              >
                {codeFetchingModes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Anonymizable>
            <Divider />
            {botSettings.codeFetchingMode == 0 && (
              <React.Fragment>
                <Anonymizable>
                  <TextField
                    label={t("bots.labels.imapEmail")}
                    name="fetchEmail"
                    disabled={botSettings.codeFetchingMode != 0}
                    className={classes.textField}
                    value={botSettings.fetchEmail}
                    onChange={handleInputChange}
                  />
                </Anonymizable>
                <Anonymizable>
                  <TextField
                    label={t("bots.labels.imapPassword")}
                    name="fetchPassword"
                    disabled={botSettings.codeFetchingMode != 0}
                    className={classes.textField}
                    value={botSettings.fetchPassword}
                    onChange={handleInputChange}
                  />
                </Anonymizable>
                <Anonymizable>
                  <TextField
                    label={t("bots.labels.imapHost")}
                    name="fetchHost"
                    disabled={botSettings.codeFetchingMode != 0}
                    className={classes.textField}
                    value={botSettings.fetchHost}
                    onChange={handleInputChange}
                  />
                </Anonymizable>
                <Anonymizable>
                  <TextField
                    label={t("bots.labels.imapPort")}
                    name="fetchPort"
                    type="number"
                    disabled={botSettings.codeFetchingMode != 0}
                    className={classes.textField}
                    value={botSettings.fetchPort}
                    onChange={handleInputChange}
                  />
                </Anonymizable>
                <Anonymizable>
                  <FormControlLabel
                    className={classes.textField}
                    control={
                      <Checkbox
                        checked={botSettings.fetchIsSSL}
                        disabled={botSettings.codeFetchingMode != 0}
                        onChange={handleInputCheck}
                        name="fetchIsSSL"
                        color="primary"
                      />
                    }
                    label={t("bots.labels.imapSSL")}
                  />
                </Anonymizable>
                {showHelp && <HelpNote text={t("bots.labels.imapHelp")} />}
              </React.Fragment>
            )}
            {botSettings.codeFetchingMode == 1 && (
              <ChipInput
                label="Backup codes"
                helperText={
                  showHelp &&
                  "To get backup codes, go to : https://myaccount.ea.com/cp-ui/security"
                }
                classes={null}
                className={classes.textField}
                newChipKeyCodes={[13, 32]}
                defaultValue={botSettings.backupCodes
                  .filter((bc) => !bc.isUsed)
                  .map((bc) => {
                    return bc.value;
                  })}
                onChange={(chips) => {
                  var backupCodes = chips.map<BackupCode>((c) => ({
                    value: c,
                    isUsed: false,
                  }));
                  const newSettings = {
                    ...botSettings,
                    ["backupCodes"]: backupCodes,
                  };
                  onSettingsUpdate(newSettings);
                  setBotSettings(newSettings);
                }}
              />
            )}
            {botSettings.codeFetchingMode == 2 && (
              <Anonymizable>
                <TextField
                  label={t("bots.labels.gAuthSecret")}
                  name="gAuthSecret"
                  disabled={botSettings.codeFetchingMode != 2}
                  className={classes.textField}
                  value={botSettings.gAuthSecret}
                  onChange={handleInputChange}
                />
              </Anonymizable>
            )}
          </React.Fragment>
        )}
        {tab === 1 && (
          <React.Fragment>
            <InputBottingMode
              className={classes.textField}
              value={botSettings.mode}
              onChanged={(value) => {
                const newSettings = { ...botSettings, ["mode"]: value };
                onSettingsUpdate(newSettings);
                setBotSettings(newSettings);
              }}
            />
            <Divider />
            <TextField
              label={t("bots.labels.maxNumberOfSameCards")}
              helperText={showHelp && t("bots.labels.maxNumberOfSameCardsHelp")}
              name="maxNumberOfSameCard"
              type="number"
              className={classes.textField}
              value={botSettings.maxNumberOfSameCard}
              onChange={handleInputChange}
            />
            <Divider />
            <TextField
              label={t("bots.labels.minProfitPercentage")}
              helperText={showHelp && t("bots.labels.minProfitPercentageHelp")}
              name="buyingOtherwisePercentage"
              type="number"
              className={classes.textField}
              value={botSettings.buyingOtherwisePercentage}
              onChange={handleInputChange}
            />
            <TextField
              label={t("bots.labels.oversellingSteps")}
              helperText={showHelp && t("bots.labels.oversellingStepsHelp")}
              name="overSellingSteps"
              type="number"
              className={classes.textField}
              value={botSettings.overSellingSteps}
              onChange={handleInputChange}
            />
            <Divider />
            <FormControlLabel
              className={classes.textField}
              control={
                <Checkbox
                  checked={botSettings.onlyTradeWithShopListCards}
                  onChange={handleInputCheck}
                  name="onlyTradeWithShopListCards"
                  color="primary"
                />
              }
              label={t("bots.labels.onlyTradeWithShopListCards")}
            />
            <Divider />
            <TextField
              label={t("bots.labels.binIndexForBuying")}
              name="usedPriceIndexForSniping"
              type="number"
              className={classes.textField}
              value={botSettings.usedPriceIndexForSniping}
              onChange={handleInputChange}
            />
            <TextField
              label={t("bots.labels.binIndexForSelling")}
              name="usedPriceIndexForListing"
              type="number"
              className={classes.textField}
              value={botSettings.usedPriceIndexForListing}
              onChange={handleInputChange}
            />
            {!shouldDisableExternalApi() && (
              <React.Fragment>
                <Divider />
                <FormControlLabel
                  className={classes.textField}
                  control={
                    <Checkbox
                      checked={botSettings.sellCoinsToExternalApis}
                      disabled={botSettings.codeFetchingMode != 0}
                      onChange={handleInputCheck}
                      name="sellCoinsToExternalApis"
                      color="primary"
                    />
                  }
                  label={t("bots.labels.sellCoinsToApi")}
                />
                <Divider />
                <TextField
                  label={t("bots.labels.minimumCoinsForTransfers")}
                  helperText={
                    showHelp && t("bots.labels.minimumCoinsForTransfersHelp")
                  }
                  name="minCoinsToTransferCoins"
                  type="number"
                  disabled={!botSettings.sellCoinsToExternalApis}
                  className={classes.textField}
                  value={botSettings.minCoinsToTransferCoins}
                  onChange={handleInputChange}
                />
                <TextField
                  label={t("bots.labels.maximumCoinsToTransferPerDay")}
                  helperText={
                    showHelp &&
                    t("bots.labels.maximumCoinsToTransferPerDayHelp")
                  }
                  name="maxOrderSpent"
                  type="number"
                  disabled={!botSettings.sellCoinsToExternalApis}
                  className={classes.textField}
                  value={botSettings.maxOrderSpent}
                  onChange={handleInputChange}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        {tab === 2 && (
          <React.Fragment>
            <FormControlLabel
              className={classes.textField}
              control={
                <Checkbox
                  name="autoSolveCaptchas"
                  checked={botSettings.autoSolveCaptchas}
                  onChange={handleInputCheck}
                />
              }
              label={t("bots.labels.automaticallySolveCaptchas")}
            />
            <TextField
              label={t("bots.labels.maximumCaptchasPerDay")}
              name="maxCaptchasPerDay"
              type="number"
              className={classes.textField}
              value={botSettings.maxCaptchasPerDay}
              onChange={handleInputChange}
            />
            <Divider />
            <TextField
              label={t("bots.labels.minimumDelayBetweenRequests")}
              helperText={
                showHelp && t("bots.labels.minimumDelayBetweenRequestsHelp")
              }
              name="minDelayBetweenRequests"
              type="number"
              className={classes.textField}
              value={botSettings.minDelayBetweenRequests}
              onChange={handleInputChange}
            />
            <TextField
              label={t("bots.labels.maximumDelayBetweenRequests")}
              helperText={
                showHelp && t("bots.labels.maximumDelayBetweenRequestsHelp")
              }
              name="maxDelayBetweenRequests"
              type="number"
              className={classes.textField}
              value={botSettings.maxDelayBetweenRequests}
              onChange={handleInputChange}
            />
            <Divider />
            <TextField
              label={t("bots.labels.minimumRestingDelay")}
              helperText={
                showHelp &&
                t("bots.labels.minimumRestingDelayHelp", {
                  requestsCount: botSettings.maxRequestCountBySession,
                })
              }
              name="minRestingDelay"
              type="number"
              className={classes.textField}
              value={botSettings.minRestingDelay}
              onChange={handleInputChange}
            />
            <TextField
              label={t("bots.labels.maximumRestingDelay")}
              helperText={
                showHelp &&
                t("bots.labels.maximumRestingDelayHelp", {
                  requestsCount: botSettings.maxRequestCountBySession,
                })
              }
              name="maxRestingDelay"
              type="number"
              className={classes.textField}
              value={botSettings.maxRestingDelay}
              onChange={handleInputChange}
            />
            <Divider />
            <TextField
              label={t("bots.labels.maximumRequestsCountPerCycle")}
              helperText={
                showHelp && t("bots.labels.maximumRequestsCountPerCycleHelp")
              }
              name="maxRequestCountBySession"
              type="number"
              className={classes.textField}
              value={botSettings.maxRequestCountBySession}
              onChange={handleInputChange}
            />
            <Divider />
            <FormControlLabel
              className={classes.textField}
              control={
                <Switch
                  name="useSchedule"
                  checked={botSettings.useSchedule}
                  onChange={handleInputCheck}
                />
              }
              label="Scheduler"
            />
            <InputSchedule
              schedule={botSettings.schedule}
              disabled={!botSettings.useSchedule}
              onChanged={(newSchedule) => {
                handleValueChange("schedule", newSchedule);
              }}
            />
          </React.Fragment>
        )}

        {tab === 3 && (
          <React.Fragment>
            <InputShopList
              initialValue={botSettings.shopListId}
              className={classes.textField}
              onChange={(newValue) => {
                handleValueChange("shopListId", newValue);
              }}
            />
            <Button
              className={classes.editLink}
              onClick={() => {
                setShopListEditorOpen(true);
              }}
            >
              Quick Edit
            </Button>
            <ShopListEditorModal
              open={shopListEditorOpen}
              onClose={() => {
                setShopListEditorOpen(false);
              }}
              shopListId={botSettings.shopListId}
            />
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
}
