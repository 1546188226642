import React from "react";
import { Theme } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { TransactionViewModel } from "@futbot/runtime-api";
import { OrdersHistory } from "../../../../../../components/ordersHistory";

const useStyles = makeStyles((theme: Theme) => createStyles({}));

export function TransactionHistory(props: {
  transaction: TransactionViewModel;
}) {
  const classes = useStyles({});
  const { transaction } = props;
  return <OrdersHistory orders={transaction.orders} />;
}
