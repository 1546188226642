import React, { useState, useEffect } from "react";
import {
  Theme,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  FormControlLabel,
  Switch
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { PricedPlayerViewModel } from "@futbot/stats-api";
import { PlayerCard } from "../../../../components/playerCard";
import { PlayerCardAutoComplete } from "../../../../components/playerCardAutoComplete";
import { InputCoins } from "../../../../components/inputCoins";
import { ModalTitle } from "../../../../components/modal/ModalTitle";
import {
  AddTransactionCardRequest,
  CardToUseInOrderViewModel
} from "@futbot/runtime-api";

const useStyles = makeStyles(() => ({
  root: {},
  dialogContent: {
    minHeight: 330,
    padding: "0 24px"
  }
}));

export function TransactionCardModal(props: {
  open: boolean;
  action: "add" | "update";
  card: CardToUseInOrderViewModel;
  onClose: () => void;
  onSubmit: (card: CardToUseInOrderViewModel) => void;
}) {
  const classes = useStyles({});
  const { open, onClose, onSubmit, card, action } = props;
  const [editedCard, setEditedCard] = useState(card);
  const isAdd = action === "add";
  const operation = isAdd ? "Add" : "Update";
  const title = operation + " a player card";
  useEffect(() => {
    setEditedCard(card);
  }, [props]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    const newCard = { ...editedCard, [name]: value };
    setEditedCard(newCard);
  };

  const handleValueChange = (name, value) => {
    const newCard = { ...editedCard, [name]: value };
    setEditedCard(newCard);
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"md"}
      className={classes.root}
      open={open}
      onClose={onClose}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
    >
      <ModalTitle>{title}</ModalTitle>
      <DialogContent className={classes.dialogContent}>
        {isAdd && (
          <PlayerCardAutoComplete
            onSelect={c => {
              setEditedCard({ playerCard: c });
            }}
          />
        )}
        {editedCard && editedCard.playerCard ? (
          <Grid container>
            <Grid item md={6}>
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="row"
              >
                <PlayerCard {...editedCard.playerCard} />
              </Grid>
            </Grid>
            <Grid item md={6}>
              <Grid container zeroMinWidth>
                <InputCoins
                  label="Suggested Buy Price"
                  placeholder="Auto"
                  initialValue={editedCard.suggestedBuyPrice}
                  onChanged={value => {
                    handleValueChange("suggestedBuyPrice", value);
                  }}
                />
                <InputCoins
                  label="Max Buy Now"
                  placeholder="Required *"
                  initialValue={editedCard.maxBuyNow}
                  onChanged={value => {
                    handleValueChange("maxBuyNow", value);
                  }}
                />
                <FormControlLabel
                  control={
                    <Switch
                      name="discard"
                      checked={editedCard.discard}
                      onChange={e => {
                        handleValueChange("discard", e.target.checked);
                      }}
                    />
                  }
                  label="Discard instead of listing"
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <div>No player selected</div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            onSubmit(editedCard);
          }}
          color="primary"
        >
          {operation}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
