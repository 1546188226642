import React, { useState, useEffect } from "react";
import {
  Toolbar,
  Typography,
  Theme,
  Button,
  IconButton
} from "@material-ui/core";
import classNames from "classnames";
import { makeStyles, createStyles } from "@material-ui/styles";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import { fancyHeader, secondaryColor, tertiaryColor } from "../../assets/jss";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import { RefreshIcon } from "../../assets/icons";

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.contrastText,
      ...fancyHeader
    },
    altBg: {
      background: secondaryColor
    },
    selectBg: {
      background: tertiaryColor
    },
    highlight: {
      color: theme.palette.primary.main
    },
    spacer: {
      flex: "1 1 100%"
    },
    actions: {
      flex: "0 0 auto"
    },
    title: {
      flex: "0 0 auto",
      color: theme.palette.text.secondary
    },
    action: {
      marginRight: 8,
      width: 100
    }
  })
);

export interface ToolbarAction {
  variant?: "text" | "outlined" | "contained";
  icon?: React.ComponentType<SvgIconProps>;
  action: () => void;
  label: string;
}

export interface ToolbarActionOnSelected {
  clearSelectionAfterAction?: boolean;
  variant?: "text" | "outlined" | "contained";
  icon?: React.ComponentType<SvgIconProps>;
  action: (selected: any[]) => void;
  label: string;
}

export function InteractiveTableToolbar(props: {
  color?: "primary" | "secondary";
  readonly?: boolean;
  actions?: ToolbarAction[];
  actionsOnSelected?: ToolbarActionOnSelected[];
  onRefresh?: () => void;
  selected?: any[];
  additionalTools?: React.ReactNode;
}) {
  const classes = useToolbarStyles({});
  const {
    readonly,
    selected,
    actions,
    actionsOnSelected,
    color,
    onRefresh,
    additionalTools
  } = props;

  const [isReadonly, setIsReadonly] = useState(readonly);
  useEffect(() => {
    setIsReadonly(readonly);
  }, [props]);

  let numSelected = selected ? selected.length : 0;
  return (
    <Toolbar
      variant={color == "secondary" ? "dense" : "regular"}
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
        [classes.altBg]: color == "secondary",
        [classes.selectBg]: numSelected > 0
      })}
    >
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <React.Fragment>
            {actionsOnSelected &&
              actionsOnSelected.map((a, i) => (
                <Button
                  key={i}
                  className={classes.action}
                  disabled={isReadonly}
                  variant={a.variant}
                  size="small"
                  color="inherit"
                  onClick={() => {
                    a.action(selected);
                  }}
                >
                  {a.icon && <a.icon />}
                  {a.label}
                </Button>
              ))}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {actions &&
              actions.map((a, i) => (
                <Button
                  key={i}
                  className={classes.action}
                  disabled={isReadonly}
                  variant={a.variant}
                  size="small"
                  color="inherit"
                  onClick={() => {
                    a.action();
                  }}
                >
                  {a.icon && <a.icon />}
                  {a.label}
                </Button>
              ))}
          </React.Fragment>
        )}
        {additionalTools && <React.Fragment>{additionalTools}</React.Fragment>}
      </div>
      <div className={classes.spacer} />
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <IconButton
            disabled={isReadonly}
            size="small"
            color="inherit"
            onClick={() => {
              onRefresh && onRefresh();
            }}
          >
            <RefreshIcon />
          </IconButton>
        )}
      </div>
    </Toolbar>
  );
}
