import React, { useCallback } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Theme
} from "@material-ui/core";
import { State } from "../../../../../../store/config/configureStore";
import { useMappedState } from "redux-react-hook";
import { Link } from "react-router-dom";
import { extractTransactionGuid, smallListWidth } from "../..";
import { makeStyles, createStyles } from "@material-ui/styles";
import { usePerfectScroll } from "../../../../../../hooks";
import classNames from "classnames";
import { Anonymizable } from "../../../../../../components/anonymizable";
import { TransactionPreviewViewModel } from "@futbot/stats-api";
import { InteractiveListHeader } from "../../../../../../components/interactiveTable";

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    tableWrapper: {
      overflow: "auto",
      position: "relative",
      maxHeight: "calc(100vh - 160px)"
    },
    cell: {
      borderLeft: "5px solid #999"
    },
    errored: {
      borderLeft: "5px solid red"
    },
    link: {
      color: "#4894fe",
      fontWeight: 500,
      textDecoration: "none"
    },
    header: {
      fontFamily: "Cairo",
      fontSize: 15,
      textTransform: "uppercase",
      fontWeight: 600
    }
  })
);

export function TransactionsList(props: {
  transactions: TransactionPreviewViewModel[];
}) {
  const { transactions } = props;
  const classes = useStyles({});
  const mapStateToProps = useCallback(
    (state: State) => ({
      pathname: state.router.location.pathname,
      transactionGuid: extractTransactionGuid(state.router.location.pathname)
    }),
    []
  );
  const { pathname, transactionGuid } = useMappedState(mapStateToProps);
  const wrapperRef = usePerfectScroll();

  return (
    <div className={classes.root}>
      <InteractiveListHeader header={"Identifier"} />
      <div className={classes.tableWrapper} ref={wrapperRef}>
        <Table>
          <TableBody>
            {transactions.map((transaction, i) => (
              <TableRow
                hover
                key={i}
                selected={transaction.guid === transactionGuid}
              >
                <TableCell
                  className={classNames(classes.cell, {
                    [classes.errored]: !transaction.isOpen
                  })}
                >
                  <Link
                    className={classes.link}
                    to={pathname.replace(transactionGuid, transaction.guid)}
                  >
                    <Anonymizable>{transaction.guid}</Anonymizable>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
