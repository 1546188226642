import React from "react";
import Grid from "@material-ui/core/Grid";
import { Theme } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      padding: "0 15px !important"
    }
  })
);

export function GridItem({ ...props }) {
  const classes = useStyles({});
  const { children, ...rest } = props;
  return (
    <Grid item {...rest} className={classes.grid}>
      {children}
    </Grid>
  );
}
