import React, { useState } from "react";
import { Theme, Drawer, Button, Divider } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { ToggleDataAnonymization } from "./ToggleDataAnonymization";
import { useDispatch } from "redux-react-hook";
import { logout } from "../../store/actions/account";
import { ShutDownIcon } from "../../assets/icons";
import { ReferalLinkModal } from "./referalLinkModal";
import { shouldDisableReferalSystem } from "../../utils/featureUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spacer: {
      flex: "1 1 100%"
    }
  })
);

export function RightMenu(props: { isOpen: boolean; onClose: () => void }) {
  const classes = useStyles({});
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [referalLinkModalOpen, setReferalLinkModalOpen] = useState(false);
  const { isOpen, onClose } = props;
  return (
    <React.Fragment>
      <ReferalLinkModal
        open={referalLinkModalOpen}
        onClose={() => {
          setReferalLinkModalOpen(false);
        }}
      />
      <Drawer anchor="right" open={isOpen} onClose={onClose}>
        <ToggleDataAnonymization />
        <Divider />
        {!shouldDisableReferalSystem() && (
          <React.Fragment>
            <Button
              color="primary"
              onClick={() => {
                setReferalLinkModalOpen(true);
              }}
            >
              Get Referal Link
            </Button>
            <Divider />
          </React.Fragment>
        )}
        <div className={classes.spacer} />
        <Button color="inherit" onClick={() => dispatch(logout())}>
          <ShutDownIcon />
          {t("logout")}
        </Button>
      </Drawer>
    </React.Fragment>
  );
}
