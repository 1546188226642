import React, { useState, useCallback } from "react";
import { AppMenu } from "../../components/appMenu";
import routes from "./routes";
import { switchRoutes } from "../../utils/routeUtils";
import { withStyles } from "@material-ui/core";
import ManagerModuleStyles from "../../assets/jss/modules/managerStyle";
import classNames from "classnames";
import { State } from "../../store/config/configureStore";
import { useMappedState, useDispatch } from "redux-react-hook";
import { Redirect } from "react-router";
import { useNotificationGetter } from "../../hooks/useNotificationGetter";
import { useGlobalNotifier } from "../../hooks/useGlobalNotifier";

export const modulePath = "/manager";
const moduleSwitch = switchRoutes(routes, modulePath);

export function ManagerModuleRaw(props: any) {
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const { classes } = props;
  const mapStateToProps = useCallback(
    (state: State) => ({
      loginResponse: state.account.loginResponse,
      pathname: state.router.location.pathname
    }),
    []
  );
  const { loginResponse } = useMappedState(mapStateToProps);
  // useNotificationGetter();
  // useGlobalNotifier();

  if (!loginResponse) {
    return <Redirect to="/" />;
  }

  return (
    <div className={classes.wrapper}>
      <AppMenu
        routes={routes}
        modulePath={modulePath}
        isOpen={isMenuOpen}
        onToggle={(isOpen: boolean) => {
          setIsMenuOpen(isOpen);
        }}
      />
      <div
        className={classNames(classes.mainPanel, {
          [classes.shrinkedMainPanel]: !isMenuOpen
        })}
      >
        <div className={classes.content}>{moduleSwitch}</div>
      </div>
    </div>
  );
}

export const ManagerModule = withStyles(ManagerModuleStyles)(ManagerModuleRaw);
