import { ReactNode } from "react";

export interface ColumnDefinition<T> {
  id: string;
  numeric?: boolean;
  disablePadding?: boolean;
  label: string;
  width?: number | string;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  value?: (element: T) => any;
  render?: (element: T) => ReactNode;
  renderLoading?: () => ReactNode;
}

export function desc<T>(a: T, b: T, selector: (element: T) => any) {
  if (selector(b) < selector(a)) {
    return -1;
  }
  if (selector(b) > selector(a)) {
    return 1;
  }
  return 0;
}

export function stableSort<T>(array: T[], cmp: any): T[] {
  const stabilizedThis = array.map((el: any, index: any) => [el, index]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el: any) => el[0]);
}

export function getSorting<T>(
  order: string,
  orderBy: string,
  columns: ColumnDefinition<T>[]
) {
  var column = columns.filter(c => c.id == orderBy);
  if (!column || !column.length) {
    return (a, b) => 0;
  }
  return order === "desc"
    ? (a: T, b: T) => desc(a, b, column[0].value)
    : (a: T, b: T) => -desc(a, b, column[0].value);
}
