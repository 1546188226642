import React from "react";
import { Theme, Paper } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { switchRoutes } from "../../../../utils/routeUtils";
import { DetailsMenu } from "../../../../components/masterDetails/DetailsMenu";
import { routes } from "./routes";

export const pagePath = "/manager/externalapis";
const useStyles = makeStyles(() => createStyles({}));

export function ExternalApiPage(props: {}) {
  const pageSwitch = switchRoutes(routes, pagePath);
  const {} = props;
  return (
    <Paper>
      <DetailsMenu routes={routes} basePath={pagePath}>
        {pageSwitch}
      </DetailsMenu>
    </Paper>
  );
}
