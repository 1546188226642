import { useSnackbar } from "@creatdevsolutions/notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLoading } from "./useLoading";

export function useActionOnItems() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { isLoading, setIsLoading } = useLoading();
  const [reloadCount, setReloadCount] = useState<number>(0);

  const waitFor = (ms: number) => new Promise(r => setTimeout(r, ms));

  async function asyncForEach<T>(
    array: T[],
    callback: (element: T, index: number, array: T[]) => Promise<void>
  ) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  const actionOnSelected = async (
    botIds: string[],
    pendingActionLabel: string,
    finishedActionLabel: string,
    callback: (botId: string) => void
  ) => {
    pendingActionLabel && enqueueSnackbar(pendingActionLabel);
    setIsLoading(true);
    await asyncForEach(botIds, async (botId, i) => {
      await waitFor(1000);
      try {
        await callback(botId);
      } catch (reason) {
        enqueueSnackbar(t("misc.errorOccured", { reason: reason }));
      }
    });
    setReloadCount(reloadCount + 1);
    setIsLoading(false);
    enqueueSnackbar(finishedActionLabel, {
      variant: "success"
    });
  };

  return { actionOnSelected, bulkActionLoading: isLoading, reloadCount };
}
