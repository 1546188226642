import { useCallback } from "react";
import { State } from "../../../../store/config/configureStore";
import { useMappedState } from "redux-react-hook";

export * from "./BotPage";

export const relativePagePath = "/bots";
export const pagePath = "/manager/bots";
export const smallBotListWidth = 260;
export const botDetailsMenuWidth = 260;
export const botDetailsContentWidth = botDetailsMenuWidth + smallBotListWidth;

export function extractBotGuid(pathname: string): string {
  var regex = /\/manager\/bots\/([0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}).*/g;
  var match = regex.exec(pathname);
  if (!match || match.length < 1) {
    return null;
  }
  return match[1];
}

export function extractBaseBotPath(pathname: string): string {
  var regex = /(\/manager\/bots\/[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}).*/g;
  var match = regex.exec(pathname);
  if (!match || match.length < 1) {
    return null;
  }
  return match[1];
}

export const useBotGuid = () => {
  const mapStateToProps = useCallback(
    (state: State) => ({
      botGuid: extractBotGuid(state.router.location.pathname)
    }),
    []
  );
  const { botGuid } = useMappedState(mapStateToProps);
  return { botGuid };
};

export const useBotBasePath = () => {
  const mapStateToProps = useCallback(
    (state: State) => ({
      basePath: extractBaseBotPath(state.router.location.pathname)
    }),
    []
  );
  const { basePath } = useMappedState(mapStateToProps);
  return { basePath };
};
