import React, { useState, useEffect } from "react";
import { Theme, Paper, LinearProgress, Grid } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useTransactionGuid } from ".";
import { useSnackbar } from "@creatdevsolutions/notistack";
import { useStatApi, useRuntimeApi } from "../../../../api";
import { TransactionsTable } from "./components/transactionsTable";
import { TransactionsList } from "./components/transactionsList";
import { TransactionDetails } from "./components/transactionDetails";
import {
  ProviderTransactionsApi,
  TransactionPreviewViewModel
} from "@futbot/stats-api";
import {
  ProviderTransactionsApi as RuntimeProviderTransactionApi,
  TransactionViewModel
} from "@futbot/runtime-api";
import { MasterDetails } from "../../../../components/masterDetails";
import { useLoading } from "../../../../hooks/useLoading";
import { TransactionCreationModal } from "./components/transactionCreationModal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: "grow"
    }
  })
);

export function TransactionsPage(props: {}) {
  const classes = useStyles({});
  const [transactions, setTransactions] = useState<
    TransactionPreviewViewModel[]
  >([]);
  const { isLoading, setIsLoading } = useLoading();
  const [reloadCount, setReloadCount] = useState<number>(0);
  const [transaction, setTransaction] = useState<TransactionViewModel>({});
  const [creationModalOpen, setCreationModalOpen] = useState(false);
  const { transactionGuid } = useTransactionGuid();
  const { enqueueSnackbar } = useSnackbar();
  const statApi = useStatApi(ProviderTransactionsApi);
  const runtimeApi = useRuntimeApi(RuntimeProviderTransactionApi);
  useEffect(() => {
    setIsLoading(true);
    statApi
      .getTransactions({ page: 0 })
      .then(transactions => {
        setTransactions(transactions);
      })
      .catch(reason => {
        console.log(reason);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [reloadCount]);

  const {} = props;
  return (
    <React.Fragment>
      <TransactionCreationModal
        transaction={transaction}
        open={creationModalOpen}
        onClose={() => {
          setCreationModalOpen(false);
        }}
        onSubmit={tsx => {
          setIsLoading(true);
          runtimeApi
            .createTransaction({ transaction: tsx })
            .then(() => {
              enqueueSnackbar("Successfully created the comfort trade");
              setReloadCount(reloadCount + 1);
              setTransaction({});
              setCreationModalOpen(false);
            })
            .catch(r => {
              enqueueSnackbar("Failed to create the comfort trade", {
                variant: "error"
              });
            })
            .finally(() => {
              setIsLoading(false);
            });
        }}
      ></TransactionCreationModal>
      <MasterDetails
        listComponent={<TransactionsList transactions={transactions} />}
        detailsComponent={<TransactionDetails />}
        tableComponent={
          <TransactionsTable
            isLoading={isLoading}
            onCreationRequest={() => {
              setCreationModalOpen(true);
            }}
            transactions={transactions}
          />
        }
        selectedElement={transactionGuid}
      />
    </React.Fragment>
  );
}
