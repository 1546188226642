import React, { useState, useCallback } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles, { StyleRules } from "@material-ui/core/styles/withStyles";
import { Theme, makeStyles, createStyles } from "@material-ui/core";
import { Configuration, AuthenticationApi } from "@futbot/login-server-api";
import { useDispatch, useMappedState } from "redux-react-hook";
import { login } from "../../store/actions/account";
import { State } from "../../store/config/configureStore";
import { Redirect } from "react-router";
import BackgroundImage from "../../assets/images/backgrounds/main.jpg";
import { LoginForm } from "./loginForm";
import { RegisterForm } from "./registerForm";
import { LOGIN_URL } from "../../api/apiConfig";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      backgroundImage: "url(" + BackgroundImage + ")",
      backgroundSize: "cover",
      backgroundPositionY: "bottom",
      height: "100vh"
    },
    main: {
      width: "auto",
      display: "block", // Fix IE 11 issue.
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      paddingTop: theme.spacing(8),
      [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
        width: 400,
        marginLeft: "auto",
        marginRight: "auto"
      }
    }
  })
);

const config = new Configuration({
  basePath: LOGIN_URL
});
const api = new AuthenticationApi(config);

export function LoginModule() {
  return <AuthenticationContainer purpose="login" />;
}

export function RegisterModule() {
  return <AuthenticationContainer purpose="register" />;
}

function AuthenticationContainer(props: { purpose: "login" | "register" }) {
  const classes = useStyles({});
  const mapStateToProps = useCallback(
    (state: State) => ({
      loggedIn:
        state.account.loginResponse && state.account.loginResponse.accessToken
    }),
    []
  );
  const { loggedIn } = useMappedState(mapStateToProps);
  const { purpose } = props;

  if (loggedIn) {
    return <Redirect to="/manager/dashboard" />;
  }

  return (
    <div className={classes.wrapper}>
      <main className={classes.main}>
        <CssBaseline />
        {purpose === "login" && <LoginForm api={api} />}
        {purpose === "register" && <RegisterForm api={api} />}
      </main>
    </div>
  );
}
