import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/icons/FileCopy";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import DirectionsIcon from "@material-ui/icons/Directions";
import { TextField, Button } from "@material-ui/core";
import useClipboard from "react-use-clipboard";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center"
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10,
    fontSize: 36
  },
  divider: {
    height: 28,
    margin: 4
  }
}));

export default function CopiableLink(props: { link: string }) {
  const classes = useStyles({});
  const { link } = props;
  const [isCopied, setCopied] = useClipboard(link);
  return (
    <div className={classes.root}>
      <TextField
        fullWidth
        disabled={true}
        value={link}
        className={classes.input}
      />
      <Button color="primary" onClick={setCopied} disabled={isCopied}>
        {isCopied ? "Copied" : "Copy"}
      </Button>
    </div>
  );
}
