import React, { useEffect, useState } from "react";
import { Theme, DialogTitle } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { fancyHeader } from "../../assets/jss";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: "white",
      fontWeight: 600,
      lineHeight: 30,
      marginBottom: 15,
      textTransform: "uppercase",
      ...fancyHeader
    }
  })
);

export function ModalTitle(props: { children?: React.ReactNode }) {
  const classes = useStyles({});
  const { t, i18n } = useTranslation();
  const { children } = props;
  return <DialogTitle className={classes.root}>{children}</DialogTitle>;
}
