import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import {
  connectRouter,
  routerMiddleware,
  RouterState
} from "connected-react-router";
import accountReducer, {
  initialState as accountInitialState
} from "../reducers/account";
import appReducer, { initialState as appInitialState } from "../reducers/app";
import miscReducer, {
  initialState as miscInitialState
} from "../reducers/misc";
import notificationsReducer, {
  initialState as notificationsInitialState
} from "../reducers/notifications";
const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const history = createBrowserHistory();

const initialState = {
  app: appInitialState,
  account: accountInitialState,
  misc: miscInitialState,
  notifications: notificationsInitialState,
  router: null as RouterState
};

export type State = typeof initialState;

export default function configureStore() {
  const store = createStore(
    combineReducers({
      account: accountReducer,
      app: appReducer,
      misc: miscReducer,
      notifications: notificationsReducer,
      router: connectRouter(history)
    }),

    composeEnhancers(applyMiddleware(routerMiddleware(history), thunk))
  );
  return store;
}
