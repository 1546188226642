import { useState, useEffect } from "react";

export default function useLongPress(
  callback = () => {},
  ms = 300,
  continuous = false
) {
  const [startLongPress, setStartLongPress] = useState(false);
  const [clickCount, setClickCount] = useState(0);

  function getTimeout() {
    if (clickCount < 10) {
      return ms;
    } else if (clickCount < 30) {
      return ms / 2;
    }
    return ms / 4;
  }

  useEffect(() => {
    let timerId;
    if (startLongPress) {
      timerId = setTimeout(() => {
        callback();
        if (continuous) setClickCount(clickCount + 1);
      }, getTimeout());
    } else {
      clearTimeout(timerId);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [startLongPress, clickCount]);

  return {
    onMouseDown: () => {
      setStartLongPress(true);
      setClickCount(clickCount + 1);
    },
    onMouseUp: () => {
      setStartLongPress(false);
      setClickCount(0);
    },
    onMouseLeave: () => {
      setStartLongPress(false);
      setClickCount(0);
    },
    onTouchStart: () => {
      setStartLongPress(true);
      setClickCount(clickCount + 1);
    },
    onTouchEnd: () => {
      setStartLongPress(false);
      setClickCount(0);
    }
  };
}
