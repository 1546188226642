import React from "react";
import { Theme, TextField, MenuItem } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({}));

export const platforms = [
  {
    value: 2,
    label: "Playstation 4"
  },
  {
    value: 8,
    label: "Xbox One"
  },
  {
    value: 16,
    label: "PC"
  }
];

export function PlateformPicker(props: {
  initialValue?: number;
  onChange: (newValue: string) => any;
  className?: string;
  label?: string;
}) {
  const classes = useStyles({});
  const { initialValue, onChange, className, label } = props;
  return (
    <TextField
      label={label}
      value={initialValue}
      className={className}
      select
      onChange={e => {
        onChange(e.target.value);
      }}
    >
      {platforms.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}
