import React from "react";
import { Theme, TableCell, Grid } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { PlayerInfo } from "../../../../../../components/playerInfo";
import { Coins } from "../../../../../../components/coins";
import TimeAgo from "react-timeago";
import { SaleViewModel } from "@futbot/stats-api";
import { Anonymizable } from "../../../../../../components/anonymizable";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minHeight: 60,
    },
    meta: {
      color: "gray",
      fontSize: 11,
    },
  })
);

export function TopDealRankingRow(props: { deal: SaleViewModel }) {
  const classes = useStyles({});
  const { deal } = props;
  return (
    <React.Fragment>
      <TableCell>
        <div className={classes.root}>
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <PlayerInfo {...deal.playerCard} />
            </Grid>
            <Grid item>
              <Coins amount={deal.profit} />
            </Grid>
          </Grid>
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <span className={classes.meta}>
                <Anonymizable>{deal.botUsername}</Anonymizable>
              </span>
            </Grid>
            <Grid item>
              <span className={classes.meta}>
                <TimeAgo date={deal.date} />
              </span>
            </Grid>
          </Grid>
        </div>
      </TableCell>
    </React.Fragment>
  );
}
