import React, { useState, useEffect } from "react";
import { Theme, useMediaQuery } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { StatisticCard } from "../statisticCard";
import TimeAgo from "react-timeago";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import { useTranslation } from "react-i18next";
import { toFriendlyNumber } from "../../../../../../assets/utils";
import { useScreenSize } from "../../../../../../hooks/useScreenSize";

const useStyles = makeStyles((theme: Theme) => createStyles({}));

export function SingleValueCard(props: {
  icon?: React.ComponentType<SvgIconProps>;
  color?: string;
  title: string;
  fetch: () => Promise<number>;
}) {
  const classes = useStyles({});
  const { icon, title, color, fetch } = props;
  const [value, setValue] = useState<number>();
  const { t } = useTranslation();
  const [reloadCount, setReloadCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [updateDate, setUpdateDate] = useState<number>(null);
  const { isSmall } = useScreenSize();
  useEffect(() => {
    if (!fetch && !reloadCount) {
      return;
    }
    setIsLoading(true);
    fetch()
      .then(v => {
        setValue(v);
        setUpdateDate(Date.now());
      })
      .catch(r => console.log(r))
      .then(() => {
        setIsLoading(false);
      });
  }, [reloadCount, props]);
  return (
    <StatisticCard
      title={title}
      tooltip={t("misc.clickToRefresh")}
      color={color}
      icon={icon}
      isLoading={isLoading}
      onClick={() => {
        setReloadCount(reloadCount + 1);
      }}
      onRenderBody={() =>
        value && (
          <React.Fragment>
            {!isSmall ? value.toLocaleString() : toFriendlyNumber(value, 2)}
          </React.Fragment>
        )
      }
      onRenderFooter={() => (
        <React.Fragment>
          <span>{t("misc.updated")} &nbsp;</span>
          <TimeAgo date={updateDate} />
        </React.Fragment>
      )}
    />
  );
}
