import React, { useState } from "react";
import {
  Theme,
  Paper,
  IconButton,
  LinearProgress,
  Tooltip,
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useGuid } from "../..";
import { useStatApi } from "../../../../../../api";
import {
  ShopListsApi,
  ShopListViewModel,
  PricedPlayerViewModel,
} from "@futbot/stats-api";
import { PlayerInfo } from "../../../../../../components/playerInfo";
import { Coins } from "../../../../../../components/coins";
import {
  InteractiveTable,
  ToolbarAction,
  ToolbarActionOnSelected,
  ColumnDefinition,
} from "../../../../../../components/interactiveTable";
import { AddIcon, DeleteIcon, EditIcon } from "../../../../../../assets/icons";
import { useTranslation } from "react-i18next";
import { useActionOnItems } from "../../../../../../hooks/useActionOnItems";
import { CardDetailsModal } from "../cardDetailsModal/CardDetailsModal";
import { useSnackbar } from "@creatdevsolutions/notistack";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
      height: "calc(100vh - 139px)",
    },
  })
);

export function ShopListCardsTable(props: {
  shoplist: ShopListViewModel;
  onReload?: () => void;
  isLoading?: boolean;
}) {
  const classes = useStyles({});
  const api = useStatApi(ShopListsApi);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [card, setCard] = useState<PricedPlayerViewModel>(null);
  const [action, setAction] = useState<"add" | "update">("add");
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { actionOnSelected, bulkActionLoading } = useActionOnItems();
  const { shoplist, onReload, isLoading } = props;

  const actions: ToolbarAction[] = [
    {
      action: () => {
        setAction("add");
        setCard({});
        setShowModal(true);
      },
      label: "Create",
      icon: AddIcon,
      variant: "outlined",
    },
  ];

  const actionsOnSelected: ToolbarActionOnSelected[] = [
    {
      action: deleteSelectedCards,
      label: "Delete",
      icon: DeleteIcon,
      variant: "outlined",
      clearSelectionAfterAction: true,
    },
  ];

  async function deleteSelectedCards(selected: string[]) {
    await actionOnSelected(
      selected,
      selected.length == 1 ? null : `Removing ${selected.length} Cards...`,
      selected.length == 1
        ? `"${selected[0]}" Card Removed`
        : `${selected.length} Cards Removed`,
      async (id) => {
        await api.deletePlayer({
          listId: shoplist.id,
          pricedPlayer: { playerCard: { id: parseInt(id) } },
        });
      }
    );
    onReload();
  }

  const columns: ColumnDefinition<PricedPlayerViewModel>[] = [
    {
      id: "playerCard",
      label: "Player Card",
      width: "35%",
      value: (c) => c.playerCard.id.toString(),
      render: (c) => <PlayerInfo {...c.playerCard} />,
    },
    {
      id: "buyPrice",
      label: "Buy Price",
      width: "10%",
      value: (c) => c.forceBuyPrice,
      render: (c) => {
        return (
          <Coins amount={c.forceBuyPrice} zeroMeaning="Auto" zeroColor="#666" />
        );
      },
    },
    {
      id: "sellPrice",
      label: "Sell Price",
      width: "10%",
      value: (c) => c.forceSellPrice,
      render: (c) => {
        return (
          <Coins
            amount={c.forceSellPrice}
            zeroMeaning="Auto"
            zeroColor="#666"
          />
        );
      },
    },
    {
      id: "doNotSell",
      label: "Do not Sell",
      width: "15%",
      value: (c) => c.doNotList,
      render: (c) => {
        return <div>{c.doNotList ? "Yes" : "-"}</div>;
      },
    },
    {
      id: "maxCardNumber",
      label: "Max stack",
      width: "10%",
      value: (c) => c.maxNumberOfSameCard,
      render: (c) => (
        <span>
          {c.maxNumberOfSameCard ? c.maxNumberOfSameCard : "Inherited"}
        </span>
      ),
    },
    {
      id: "actions",
      label: "",
      align: "right",
      width: "15%",
      render: (c) => (
        <React.Fragment>
          <Tooltip title={t("misc.update")}>
            <IconButton
              size="small"
              onClick={() => {
                setAction("update");
                setCard(c);
                setShowModal(true);
              }}
              color="primary"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("misc.delete")}>
            <IconButton
              size="small"
              color="secondary"
              onClick={async () => {
                await deleteSelectedCards([c.playerCard.id.toString()]);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </React.Fragment>
      ),
    },
  ];

  return (
    <Paper className={classes.root}>
      <CardDetailsModal
        open={showModal}
        action={action}
        card={card}
        onClose={() => {
          setShowModal(false);
        }}
        onSubmit={async (ppc) => {
          if (action == "add") {
            await api.addPlayer({ listId: shoplist.id, pricedPlayer: ppc });
          } else if (action == "update") {
            await api.updatePlayer({ listId: shoplist.id, pricedPlayer: ppc });
          }
          enqueueSnackbar(
            `"${ppc.playerCard.name}" card ${
              action == "add" ? "Added" : "Updated"
            }`,
            { variant: "success" }
          );
          setShowModal(false);
          onReload();
        }}
      />
      <InteractiveTable<PricedPlayerViewModel>
        data={shoplist ? shoplist.searches : []}
        isReadOnly={isLoading || bulkActionLoading}
        isLoading={isLoading || bulkActionLoading}
        wrapHeigth={212}
        toolbarColor="secondary"
        columns={columns}
        getKey={(t) => t.playerCard.id.toString()}
        actions={actions}
        actionsOnSelected={actionsOnSelected}
        onRefresh={() => {
          onReload();
        }}
      />
    </Paper>
  );
}
