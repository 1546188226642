import React, { useState } from "react";
import {
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  Tooltip,
  TableSortLabel,
  makeStyles,
  createStyles,
  Theme
} from "@material-ui/core";
import { ColumnDefinition } from "./columnDefinition";
import classNames from "classnames";

export function useSelectionHandler<T>(
  array?: Array<T>,
  callback?: (value: T) => string
) {
  const [selected, setSelected] = useState<string[]>([]);
  function selectAll(event: any) {
    if (event.target.checked) {
      const newSelecteds = array.map(callback);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }
  const isSelected = (element: T) => selected.indexOf(callback(element)) !== -1;
  return { selected, selectAll, isSelected, setSelected };
}

export function useOrderHandler() {
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState(null);

  function onRequestSort(property: string) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  return { order, orderBy, onRequestSort };
}

export const useTableHeaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    header: {
      fontFamily: "Cairo",
      fontSize: 15,
      textTransform: "uppercase",
      fontWeight: 600,
      height: 57
    },
    styledHeader: (props: any) => ({
      background: props.background
    })
  })
);

export function InteractiveTableHead<T>(props: {
  numSelected: number;
  onSelectAllClick?: (event: any) => void;
  order?: "asc" | "desc";
  orderBy?: string;
  rowCount: number;
  onRequestSort?: (property: string) => void;
  columns: ColumnDefinition<T>[];
}) {
  const classes = useTableHeaderStyles({});
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    columns
  } = props;
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {columns.map(column => (
          <TableCell
            key={column.id}
            className={classes.header}
            style={{ width: column.width }}
            align={column.numeric ? "right" : "left"}
            padding={column.disablePadding ? "none" : "default"}
            sortDirection={orderBy === column.id ? order : false}
          >
            <Tooltip
              title="Sort"
              placement={column.numeric ? "bottom-end" : "bottom-start"}
              enterDelay={300}
            >
              <TableSortLabel
                active={orderBy === column.id}
                direction={order}
                onClick={e => {
                  onRequestSort(column.id);
                }}
              >
                {column.label}
              </TableSortLabel>
            </Tooltip>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
