import React, { useState, useEffect } from "react";
import {
  Theme,
  Button,
  Divider,
  LinearProgress,
  Toolbar,
  Grid,
  TableCell
} from "@material-ui/core";
import { useBotGuid } from "../..";
import { useRuntimeApi, useStatApi } from "../../../../../../api";
import { BotManagerApi, BotViewModel } from "@futbot/runtime-api";
import { makeStyles, createStyles } from "@material-ui/styles";
import StartIcon from "@material-ui/icons/PlayArrow";
import StopIcon from "@material-ui/icons/Stop";
import classNames from "classnames";
import { SimpleInformation } from "../../../../../../components/SimpleInformation";
import { Coins } from "../../../../../../components/coins";
import { GridItem } from "../../../../../../components/grid";
import { SingleValueCard } from "../../../dashboard/components/singleValueCard";
import { IncomeHistoryChart } from "../../../dashboard/components/incomeHistoryChart";
import { OutcomeHistoryChart } from "../../../dashboard/components/outcomeHistoryChart";
import { StatisticsApi, SaleViewModel } from "@futbot/stats-api";
import { useSnackbar } from "@creatdevsolutions/notistack";
import Moment from "moment";
import { RankingCard } from "../../../dashboard/components/rankingCard";
import { PlayerInfo } from "../../../../../../components/playerInfo";
import { useTranslation } from "react-i18next";
import { TopDealRanking } from "../../../dashboard/components/topDealRanking";
import { ProfitableCardRanking } from "../../../dashboard/components/profitableCardRanking";
import { RefreshIcon } from "../../../../../../assets/icons";
import { shouldDisableExternalApi } from "../../../../../../utils/featureUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
    },
    separator: {
      marginTop: 30
    },
    toolbar: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText
    },
    button: {
      width: 100,
      marginRight: 8
    },
    leftIcon: {
      marginRight: 8
    },
    rightIcon: {
      marginLeft: 8
    },
    iconSmall: {
      fontSize: 20
    },
    informations: {
      zIndex: 999,
      position: "relative",
      boxShadow: "0px 3px 6px #d1cace",
      background: "#ececec"
    },
    stats: {
      overflow: "auto",
      position: "relative",
      maxHeight: "calc(100vh - 300px)"
    }
  })
);

export function BotOverview() {
  const classes = useStyles({});
  const { t } = useTranslation();
  const { botGuid } = useBotGuid();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reloadCount, setReloadCount] = useState<number>(0);
  const [bot, setBot] = useState<BotViewModel>(null);
  const api = useRuntimeApi(BotManagerApi);
  const statisticsApi = useStatApi(StatisticsApi);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setIsLoading(true);
    api.getBot({ botId: botGuid }).then(b => {
      setBot(b);
      setIsLoading(false);
    });
  }, [botGuid, reloadCount]);

  if (isLoading || !bot) {
    return <LinearProgress />;
  }

  const dateId = Moment(new Date()).format("YYYYMMDD");

  return (
    <div className={classes.root}>
      <Toolbar className={classes.toolbar} variant="dense">
        <Button
          color="inherit"
          variant="outlined"
          size="small"
          className={classes.button}
          onClick={() => {
            api
              .startBot({ botId: botGuid })
              .then(() => {
                enqueueSnackbar(t("bots.labels.started"));
                setReloadCount(reloadCount + 1);
              })
              .catch(r => console.log(r));
          }}
        >
          <StartIcon
            className={classNames(classes.leftIcon, classes.iconSmall)}
          />
          {t("misc.start")}
        </Button>
        <Button
          color="inherit"
          variant="outlined"
          size="small"
          className={classes.button}
          onClick={() => {
            api
              .stopBot({ botId: botGuid })
              .then(() => {
                enqueueSnackbar(t("bots.labels.stopped"));
                setReloadCount(reloadCount + 1);
              })
              .catch(r => console.log(r));
          }}
        >
          <StopIcon
            className={classNames(classes.leftIcon, classes.iconSmall)}
          />
          {t("misc.stop")}
        </Button>
        <Button
          color="inherit"
          variant="outlined"
          size="small"
          className={classes.button}
          onClick={() => {
            setReloadCount(reloadCount + 1);
          }}
        >
          <RefreshIcon
            className={classNames(classes.leftIcon, classes.iconSmall)}
          />
          {t("misc.refresh")}
        </Button>
      </Toolbar>
      <Divider />
      <div className={classes.informations}>
        <SimpleInformation label={t("misc.coins")}>
          <Coins amount={bot.coins} />
        </SimpleInformation>
        <SimpleInformation label={t("misc.state")}>
          {bot.state}
        </SimpleInformation>
        <SimpleInformation label={t("misc.additionalInformations")}>
          {bot.additionalInformations}
        </SimpleInformation>
      </div>
      <Divider />
      <Grid container className={classes.stats}>
        <Grid container>
          <GridItem md={12} lg={6}>
            <TopDealRanking botId={botGuid} count={5} />
          </GridItem>
          <GridItem md={12} lg={6}>
            <ProfitableCardRanking botId={botGuid} count={5} />
          </GridItem>
        </Grid>
        <Grid container>
          <GridItem lg={4}>
            <SingleValueCard
              title={`${t("dashboard.labels.totalMadeCoins")} ${t(
                "misc.today"
              )}`}
              fetch={async () => {
                return (await statisticsApi.madeCoinsCount({
                  botId: botGuid,
                  dateId: dateId
                })).count;
              }}
            />
          </GridItem>
          {!shouldDisableExternalApi() && (
            <GridItem lg={4}>
              <SingleValueCard
                title={`${t("dashboard.labels.totalSoldCoins")} ${t(
                  "misc.today"
                )}`}
                fetch={async () => {
                  return (await statisticsApi.soldCoinsCount({
                    botId: botGuid,
                    dateId: dateId
                  })).count;
                }}
              />
            </GridItem>
          )}
          <GridItem lg={4}>
            <SingleValueCard
              title={`${t("dashboard.labels.totalDealsMade")} ${t(
                "misc.today"
              )}`}
              fetch={async () => {
                return (await statisticsApi.dealsCount({
                  botId: botGuid,
                  dateId: dateId
                })).count;
              }}
            />
          </GridItem>
        </Grid>
        <Grid container className={classes.separator}>
          <GridItem md={12} lg={12} className={classes.separator}>
            <IncomeHistoryChart botId={botGuid} chartStroke={"#333"} />
          </GridItem>
        </Grid>
        <Grid container>
          <GridItem lg={4}>
            <SingleValueCard
              title={t("dashboard.labels.totalMadeCoins")}
              fetch={async () => {
                return (await statisticsApi.madeCoinsCount({ botId: botGuid }))
                  .count;
              }}
            />
          </GridItem>
          {!shouldDisableExternalApi() && (
            <GridItem lg={4}>
              <SingleValueCard
                title={t("dashboard.labels.totalSoldCoins")}
                fetch={async () => {
                  return (await statisticsApi.soldCoinsCount({
                    botId: botGuid
                  })).count;
                }}
              />
            </GridItem>
          )}
          <GridItem lg={4}>
            <SingleValueCard
              title={t("dashboard.labels.totalDealsMade")}
              fetch={async () => {
                return (await statisticsApi.dealsCount({ botId: botGuid }))
                  .count;
              }}
            />
          </GridItem>
        </Grid>
      </Grid>
    </div>
  );
}
