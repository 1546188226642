import { WsNotification } from "../actions/notifications";

class Notifications {
  [id: string]: WsNotification;
}

export const initialState: Notifications = {
  all: null
};

export default (
  state = initialState,
  action: { type: string; payload: any }
): typeof initialState => {
  let newState = state;
  switch (action.type) {
    case "NOTIFICATION_RECEIVED":
      let newNtf = action.payload as WsNotification;
      if (!newNtf) {
        break;
      }
      if (newNtf.b) {
        newState = { ...state, all: newNtf, [newNtf.b]: newNtf };
      } else {
        newState = { ...state, all: newNtf };
      }
      break;
  }
  return newState;
};
