import React, { useEffect, useState } from "react";
import { Theme, LinearProgress, Paper } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { useLoading } from "../../../../hooks/useLoading";
import { useRuntimeApi, useStatApi } from "../../../../api";
import { useActionOnItems } from "../../../../hooks/useActionOnItems";
import { MasterDetails } from "../../../../components/masterDetails";
import { ProfitablePlayersTable } from "./components/ProfitablePlayersTable";
import { HistoryApi, DealsOfTheDayView } from "@futbot/stats-api";

const useStyles = makeStyles((theme: Theme) => createStyles({}));

export function ProfitablePlayersPage(props: {}) {
  const classes = useStyles({});
  const { t, i18n } = useTranslation();
  const { isLoading, setIsLoading } = useLoading();
  const [reload, setReload] = useState(0);
  const [data, setData] = useState<DealsOfTheDayView[]>([]);
  const api = useStatApi(HistoryApi);
  const {} = props;

  const {
    actionOnSelected,
    reloadCount,
    bulkActionLoading
  } = useActionOnItems();

  useEffect(() => {
    setIsLoading(true);
    api
      .getProfitableCardsOfToday({ platform: 2 })
      .then(d => {
        setData(d);
      })
      .catch(reason => {
        console.log(reason);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <Paper>
      <ProfitablePlayersTable
        isLoading={isLoading || bulkActionLoading}
        isReadOnly={isLoading || bulkActionLoading}
        data={data}
        onRefresh={() => setReload(reload + 1)}
      />
    </Paper>
  );
}
