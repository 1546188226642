import { useCallback } from "react";
import { State } from "../../../../store/config/configureStore";
import { useMappedState } from "redux-react-hook";
export * from "./ProxyPage";

const menu = "administrator";
const subMenu = "proxies";
export const relativePagePath = `/${subMenu}`;
export const pagePath = `/${menu}/${subMenu}`;

export function extractProxyId(pathName: string): number | undefined {
  var splitted = pathName.split("/");
  return splitted.length >= 4 &&
    splitted[1].toLowerCase() === menu &&
    splitted[2].toLowerCase() === subMenu
    ? parseInt(splitted[3])
    : undefined;
}

export const useProxyId = () => {
  const mapStateToProps = useCallback(
    (state: State) => ({
      proxyId: extractProxyId(state.router.location.pathname),
    }),
    []
  );
  const { proxyId } = useMappedState(mapStateToProps);
  return { proxyId };
};
