import React, { useState, useEffect } from "react";
import { Theme } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useStatApi } from "../../../../../../api";
import { HistoryApi, StatisticsApi } from "@futbot/stats-api";
import { CoinEvolutionChart, ChartElement } from "../coinEvolutionChart";
import { useTranslation } from "react-i18next";

const NumberOfDays = 30;

const useStyles = makeStyles((theme: Theme) => createStyles({}));

export function IncomeHistoryChart(props: {
  botId?: string;
  color?: string;
  chartStroke?: string;
  lineStroke?: string;
}) {
  const classes = useStyles({});
  const { t } = useTranslation();
  const api = useStatApi(StatisticsApi);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [reloadCount, setReloadCount] = useState<number>(0);
  const [updateDate, setUpdateDate] = useState<number>(null);
  const [data, setData] = useState<ChartElement[]>();
  const { botId, color, chartStroke, lineStroke } = props;

  useEffect(() => {
    setIsLoading(true);
    api
      .getProfits({ numberOfDays: NumberOfDays, botId: botId })
      .then(profits => {
        setData(
          profits
            .sort((s1, s2) => {
              return s1.date > s2.date ? 1 : -1;
            })
            .map(p => {
              return { price: p.profit, date: p.date };
            })
        );
        setUpdateDate(Date.now());
      })
      .catch(reason => {
        console.log(reason);
      })
      .then(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <CoinEvolutionChart
      color={color}
      lineStroke={lineStroke}
      chartStroke={chartStroke}
      title={t("dashboard.labels.coinsIncomeEvolution")}
      description={t("dashboard.labels.profitDuringXDays", {
        days: NumberOfDays
      })}
      isLoading={isLoading}
      unavailableDataMessage={t("dashboard.labels.unavailableData")}
      prices={data}
      hideHoursInTooltip
      lastUpdate={updateDate}
    />
  );
}
