import React from "react";
import { Grid, CssBaseline } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { GridItem } from "../../../../components/grid";
import { IncomeHistoryChart } from "./components/incomeHistoryChart";
import { OutcomeHistoryChart } from "./components/outcomeHistoryChart";
import { useStatApi } from "../../../../api";
import { StatisticsApi, PaymentApi } from "@futbot/stats-api";
import { SingleValueCard } from "./components/singleValueCard";
import { useTranslation } from "react-i18next";
import Moment from "moment";
import {
  ProfitsIcon,
  SalesMadeIcon,
  DealsMadeIcon,
  OctofutiumsIcon
} from "../../../../assets/icons";
import { TopDealRanking } from "./components/topDealRanking";
import { ProfitableCardRanking } from "./components/profitableCardRanking";
import { shouldDisableExternalApi } from "../../../../utils/featureUtils";

const dateId = Moment(new Date()).format("YYYYMMDD");

export function DashboardPage(props: {}) {
  const { t } = useTranslation();
  const statisticsApi = useStatApi(StatisticsApi);
  const paymentApi = useStatApi(PaymentApi);
  const {} = props;
  return (
    <React.Fragment>
      <CssBaseline />
      <Grid container>
        <Grid container>
          <GridItem md={12} lg={4}>
            <TopDealRanking count={5} />
          </GridItem>
          <GridItem md={12} lg={4}>
            <ProfitableCardRanking count={5} />
          </GridItem>
          <GridItem md={12} lg={4}>
            <TopDealRanking count={5} dateId={dateId} />
          </GridItem>
        </Grid>
        <Grid container>
          <GridItem sm={12} md={6} lg={3}>
            <SingleValueCard
              title={t("dashboard.labels.totalMadeCoins")}
              color="success"
              icon={ProfitsIcon}
              fetch={async () => {
                return (await statisticsApi.madeCoinsCount({})).count;
              }}
            />
          </GridItem>
          {!shouldDisableExternalApi() && (
            <GridItem sm={12} md={6} lg={3}>
              <SingleValueCard
                icon={SalesMadeIcon}
                title={t("dashboard.labels.totalSoldCoins")}
                color="info"
                fetch={async () => {
                  return (await statisticsApi.soldCoinsCount({})).count;
                }}
              />
            </GridItem>
          )}
          <GridItem sm={12} md={6} lg={3}>
            <SingleValueCard
              icon={DealsMadeIcon}
              title={t("dashboard.labels.totalDealsMade")}
              color="danger"
              fetch={async () => {
                return (await statisticsApi.dealsCount({})).count;
              }}
            />
          </GridItem>
          <GridItem sm={12} md={6} lg={3}>
            <SingleValueCard
              icon={OctofutiumsIcon}
              title={t("dashboard.labels.remainingOctofutiums")}
              color="warning"
              fetch={async () => {
                var value = await paymentApi.getRemainingOctofutiums();
                return value.octofutiums;
              }}
            />
          </GridItem>
        </Grid>
        <Grid container>
          <GridItem md={12} lg={6}>
            <IncomeHistoryChart
              color={"success"}
              lineStroke="#fff"
              chartStroke="#fff"
            />
          </GridItem>
          {!shouldDisableExternalApi() && (
            <GridItem md={12} lg={6}>
              <OutcomeHistoryChart
                color={"info"}
                lineStroke="#fff"
                chartStroke="#fff"
              />
            </GridItem>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
