import React from "react";
import { RouteInfo } from "../../utils/routeUtils";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { ProxyPage } from "./pages/proxy";
import { AccountsPage } from "./pages/accounts";
import { DashboardPage } from "./pages/dashboard";
import { ProfitablePlayersPage } from "./pages/profitablePlayers";

const routes: RouteInfo[] = [
  {
    path: "/dashboard",
    name: "Dashboad",
    icon: DashboardIcon,
    component: () => <DashboardPage />
  },
  {
    path: "/accounts",
    name: "Accounts",
    icon: DashboardIcon,
    component: () => <AccountsPage />
  },
  {
    path: "/proxies",
    name: "Proxies Review",
    icon: DashboardIcon,
    component: () => <ProxyPage />
  },
  {
    path: "/profitableCards",
    name: "Profitable Cards",
    icon: DashboardIcon,
    component: () => <ProfitablePlayersPage />
  }
];

export default routes;
