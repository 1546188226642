import React, { useState, useEffect } from "react";
import {
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import {
  PricedPlayerViewModel,
  FuzzySearchViewModel
} from "@futbot/stats-api";
import { PlayerCard } from "../../../../../../components/playerCard";
import { PlayerCardAutoComplete } from "../../../../../../components/playerCardAutoComplete";
import { InputCoins } from "../../../../../../components/inputCoins";
import {
  LeaguePicker,
  NationPicker,
  ClubPicker
} from "../../../../../../components/assetPicker";
import classNames from "classnames";
import { LevelPicker } from "../../../../../../components/assetPicker/LevelPicker";

const useStyles = makeStyles(() => ({
  root: {},
  dialogContent: {
    minHeight: 330,
    padding: "0 24px"
  },
  margin: {
    margin: 10
  }
}));

export function FuzzySearchModal(props: {
  open: boolean;
  action: "add" | "update";
  fuzzySearch: FuzzySearchViewModel;
  onClose: () => void;
  onSubmit: (fuzzySearch: FuzzySearchViewModel) => void;
}) {
  const classes = useStyles({});
  const { open, onClose, onSubmit, fuzzySearch, action } = props;
  const [editedSearch, setEditedSearch] = useState(
    fuzzySearch ? fuzzySearch : {}
  );
  const isAdd = action === "add";
  const operation = isAdd ? "Add" : "Update";
  const title = operation + " a fuzzy search";
  useEffect(() => {
    setEditedSearch(fuzzySearch ? fuzzySearch : {});
  }, [props]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    const newCard = { ...editedSearch, [name]: value };
    setEditedSearch(newCard);
  };

  const handleValueChange = (name, value) => {
    const newCard = { ...editedSearch, [name]: value };
    setEditedSearch(newCard);
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"md"}
      className={classes.root}
      open={open}
      onClose={onClose}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
    >
      <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container>
          <Grid item md={12}>
            <TextField
              className={classes.margin}
              label="Name"
              value={editedSearch.name}
              fullWidth
              name="name"
              variant="outlined"
              onChange={e => {
                handleInputChange(e);
              }}
            />
          </Grid>
          <Grid item md={6}>
            <Grid
              container
              justify="center"
              alignItems="center"
              direction="row"
            >
              <InputCoins
                className={classes.margin}
                label="Min Bid"
                placeholder="Any"
                initialValue={editedSearch.minBid}
                onChanged={value => {
                  handleValueChange("minBid", value);
                }}
              />
              <InputCoins
                className={classes.margin}
                label="Min Buy"
                placeholder="Any"
                initialValue={editedSearch.minBuy}
                onChanged={value => {
                  handleValueChange("minBuy", value);
                }}
              />
              <LeaguePicker
                className={classes.margin}
                initialValue={editedSearch.league}
                onChange={value => {
                  setEditedSearch({
                    ...editedSearch,
                    league: value,
                    team: 0
                  });
                }}
              />
              <NationPicker
                className={classes.margin}
                initialValue={editedSearch.nation}
                onChange={value => {
                  handleValueChange("nation", value);
                }}
              />
            </Grid>
          </Grid>
          <Grid item md={6}>
            <Grid container zeroMinWidth>
              <InputCoins
                className={classes.margin}
                label="Max Bid"
                placeholder="Any"
                initialValue={editedSearch.maxBid}
                onChanged={value => {
                  handleValueChange("maxBid", value);
                }}
              />
              <InputCoins
                className={classes.margin}
                label="Max Buy"
                placeholder="Any"
                initialValue={editedSearch.maxBuy}
                onChanged={value => {
                  handleValueChange("maxBuy", value);
                }}
              />
              <ClubPicker
                className={classes.margin}
                initialValue={editedSearch.team}
                onChange={value => handleValueChange("team", value)}
                league={editedSearch.league}
              />
              <LevelPicker
                className={classes.margin}
                initialValue={editedSearch.level}
                onChange={value => handleValueChange("level", value)}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            onSubmit(editedSearch);
          }}
          color="primary"
        >
          {operation}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
