import { useRef, useEffect, useState } from "react";

export function useDebounce(value: string, delay: number = 600) {
  const [currentValue, setCurrentValue] = useState(value);
  const interval = useRef<NodeJS.Timeout>(null);

  const clean = () => {
    if (interval.current !== null) {
      clearInterval(interval.current);
    }
  };

  useEffect(() => {
    interval.current = setTimeout(() => {
      setCurrentValue(value);
    }, delay);
    return clean;
  }, [value, delay]);

  useEffect(() => clean, []);

  return currentValue;
}
